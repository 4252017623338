import React from 'react';
import { Theme } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import {
  AvailableService,
  ServiceBehaviorProps,
  ServiceInfo,
} from '@bizapp-frontend/customer/organisms/AvailableService';

import {
  NotificationPanel,
  NotificationPanelProps,
} from '@bizapp-frontend/customer/organisms/NotificationPanel';

import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

import { PageTemplate } from '@bizapp-frontend/customer/templates/PageTemplate';
import { isTenantDeleted, ServiceType } from '../pages/util';
import { AvailableTrialService } from '@bizapp-frontend/customer/organisms/AvailableTrialService';

const MAX_DATE_NS = 9214646399000000000;

const INQUIRY_TYPE_CONTRACT = 'ご契約に関する問い合わせ';
const INQUIRY_DETAIL_PURCHASE_WITH_VOLUME =
  '有料プラン登録をご希望の方は、 以下フォーマットにご記入の上、送信してください。\n' +
  '--------\n' +
  '・お申し込みライセンス数：\n' +
  '・会社・団体名：\n' +
  '・会社・団体名（フリガナ）：';
const INQUIRY_DETAIL_PURCHASE =
  '有料プラン登録をご希望の方は、 以下フォーマットにご記入の上、送信してください。\n' +
  '--------\n' +
  '・会社・団体名：\n' +
  '・会社・団体名（フリガナ）：';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: 'center',
      backgroundColor: '#F2F3FB',
    },
    notificationPanel: {
      marginBottom: theme.spacing(4),
    },
    availableService: {
      marginBottom: '24px !important',
      '&:last-child': {
        marginBottom: '0px !important',
      },
    },
    availableTrialService: {
      marginBottom: theme.spacing(3),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    availableTrialServiceTitle: {
      marginTop: theme.spacing(6),
    },
  }),
);

export type ServiceStatus =
  | 'in-preparation'
  | 'in-use'
  | 'will-expire'
  | 'expired'
  | 'purchased'
  | 'freemium';

export interface AvailableServiceInfo {
  serviceInfo?: ServiceInfo;
  serviceStatus?: ServiceStatus;
  serviceId?: string;
  tenantId?: string;
  licenseNumber?: number;
  usedLicenseNumber?: number;
  volume?: number;
  usedVolume?: number;
  expiredDate?: number;
  daysLeft?: number;
  moreServicesRef?: any;
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  url?: string;
  notificationPanel?: NotificationPanelProps;
  startDate?: number; // only for sorting AvaliableService in customer home page
}

export interface HomeProps {
  availableServiceInfo?: AvailableServiceInfo[];
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
  trialServices?: ServiceType[];
}

export const Home: React.FC<HomeProps> = ({
  availableServiceInfo,
  dialogState,
  onClickDialogClose,
  trialServices,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const RootContainer = React.useMemo(
    () =>
      styled('main')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(6, 2, 7),
        width: '100%',
        maxWidth: '888px',
        boxSizing: 'border-box',
        [theme.breakpoints.up(768)]: {
          padding: theme.spacing(6, 4, 7),
        },
      })),
    [],
  );

  const LabelBox = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(2),
    '& span': {
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#333333',
    },
  }));

  const Label = styled('span')(({ theme }) => ({
    marginRight: theme.spacing(1),
  }));

  const toIncreaseLicense = (serviceId: string, tenantId: string) => {
    navigate(
      `/application/${serviceId}/increase-license?serviceId=${serviceId}&tenantId=${tenantId}`,
    );
  };

  const proceedToPurchase = (
    serviceId: string,
    tenantId: string,
    isTenantDeleted?: boolean,
  ) => {
    switch (serviceId) {
      case 'workflow':
        navigate(
          `/contact?tenantId=${tenantId}&serviceId=${serviceId}&type=${INQUIRY_TYPE_CONTRACT}&detail=${encodeURIComponent(
            INQUIRY_DETAIL_PURCHASE_WITH_VOLUME,
          )}`,
        );
        break;
      case 'chatbot':
        navigate(
          `/contact?tenantId=${tenantId}&serviceId=${serviceId}&type=${INQUIRY_TYPE_CONTRACT}&detail=${encodeURIComponent(
            INQUIRY_DETAIL_PURCHASE,
          )}`,
        );
        break;
      case 'pjb':
        if (isTenantDeleted) {
          navigate(
            `/contact?tenantId=${tenantId}&serviceId=${serviceId}&type=${INQUIRY_TYPE_CONTRACT}&detail=${encodeURIComponent(
              INQUIRY_DETAIL_PURCHASE_WITH_VOLUME,
            )}`,
          );
          break;
        }
        navigate(
          `/?serviceId=${serviceId}&tenantId=${tenantId}&purchaseDialog=true`,
        );
        break;
      default:
        break;
    }
  };

  const generateServiceBehaviorProps = (info: AvailableServiceInfo) => {
    const isInfiniteEndDate = (info.expiredDate ?? 0) >= MAX_DATE_NS;
    // UnixNano to JST
    const expiredDateTime = info.expiredDate
      ? new Date(
          info.expiredDate / 1000000 +
            (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000,
        )
      : undefined;

    const expiredDateTimeString = expiredDateTime
      ? `${expiredDateTime.getFullYear()}年${(
          '0' +
          (expiredDateTime.getMonth() + 1)
        ).slice(-2)}月${('0' + expiredDateTime.getDate()).slice(-2)}日`
      : undefined;

    const limitedDateTime = expiredDateTime;
    let limitedDateTimeString = '';
    if (limitedDateTime !== undefined) {
      limitedDateTime.setDate(limitedDateTime.getDate() + 30);
      limitedDateTimeString = `${limitedDateTime.getFullYear()}年${(
        '0' +
        (limitedDateTime.getMonth() + 1)
      ).slice(-2)}月${('0' + limitedDateTime.getDate()).slice(-2)}日`;
    }

    let availableService: ServiceBehaviorProps;
    switch (info.serviceStatus) {
      case 'freemium':
        availableService = {
          statusVariant: 'success',
          statusLabel: isInfiniteEndDate
            ? '無料キャンペーン'
            : `無料キャンペーン・あと${info.daysLeft}日`,
          buttonsProps: [
            {
              label: 'サービスを開く',
              variant: 'primary',
              onClick: () => window.open(info.url, '_blank'),
            },
            {
              label: '有料プラン登録',
              variant: 'secondary',
              onClick: () =>
                proceedToPurchase(
                  info.serviceId as string,
                  info.tenantId as string,
                ),
            },
          ],
        };
        break;
      case 'in-use':
        availableService = {
          statusVariant: 'success',
          statusLabel: isInfiniteEndDate
            ? '無料トライアル'
            : `無料トライアル・あと${info.daysLeft}日`,
          buttonsProps: [
            {
              label: 'サービスを開く',
              variant: 'primary',
              onClick: () => window.open(info.url, '_blank'),
            },
            {
              label: '有料プラン登録',
              variant: 'secondary',
              onClick: () =>
                proceedToPurchase(
                  info.serviceId as string,
                  info.tenantId as string,
                ),
            },
          ],
        };
        break;
      case 'will-expire':
        availableService = {
          statusVariant: 'warning',
          statusLabel: `無料トライアル・あと${info.daysLeft}日`,
          buttonsProps: [
            {
              label: 'サービスを開く',
              variant: 'primary',
              onClick: () => window.open(info.url, '_blank'),
            },
            {
              label: '有料プラン登録',
              variant: 'secondary',
              onClick: () =>
                proceedToPurchase(
                  info.serviceId as string,
                  info.tenantId as string,
                ),
            },
          ],
        };
        break;
      case 'expired':
        availableService = {
          statusVariant: 'normal',
          statusLabel: '無料トライアル終了',
          buttonsProps: [
            {
              label: '有料プラン登録',
              variant: 'primary',
              onClick: () =>
                proceedToPurchase(
                  info.serviceId as string,
                  info.tenantId as string,
                  isTenantDeleted((info.expiredDate as number) / 1000000),
                ),
            },
          ],
          warningDescription: (
            <>
              <span>
                トライアル利用中に作成されたデータは、
                <strong>30日後に完全削除</strong>
                されます。
              </span>
              <span>
                同一データを引き継いでご利用になる場合は、
                <strong>{limitedDateTimeString}まで</strong>
                に有料プラン登録のお手続きをお願いいたします。
              </span>
            </>
          ),
        };
        break;
      case 'purchased':
        availableService = {
          statusVariant: 'success',
          statusLabel: '有料プラン利用中',
          buttonsProps: [
            {
              label: 'サービスを開く',
              variant: 'primary',
              onClick: () => window.open(info.url, '_blank'),
            },
          ],
        };
        if (info.serviceId === 'pjb') {
          availableService.buttonsProps?.push({
            label: 'ライセンス追加購入',
            variant: 'secondary',
            onClick: () => {
              if (info.serviceId && info.tenantId) {
                toIncreaseLicense(info.serviceId, info.tenantId);
              }
            },
          });
        }
        break;
      case 'in-preparation': // fall through because of default value
      default:
        availableService = {
          statusVariant: 'normal',
          statusLabel: '無料トライアル・準備中',
          buttonsProps: [
            {
              label: 'サービスを開く',
              variant: 'primary',
              disabled: true,
            },
          ],
        };
        break;
    }
    return {
      availableService: availableService,
      expiredDateTimeString: expiredDateTimeString,
    };
  };

  return (
    <>
      <PageTemplate className={classes.root}>
        <RootContainer>
          <LabelBox>ご利用中のサービス</LabelBox>
          <div>
            {availableServiceInfo &&
              availableServiceInfo.map((info, i) => {
                const generatedInfo = generateServiceBehaviorProps(info);
                const isInfiniteEndDate =
                  (info.expiredDate ?? 0) >= MAX_DATE_NS;
                return (
                  <AvailableService
                    className={classes.availableService}
                    {...info.serviceInfo}
                    {...generatedInfo.availableService}
                    licenseInfo={
                      info.serviceId !== 'chatbot' && (
                        <>
                          <Label>ライセンス数：</Label>
                          <strong>
                            {info.serviceStatus === 'freemium'
                              ? '---'
                              : info.licenseNumber}
                          </strong>
                          {!!info.usedLicenseNumber && (
                            <>
                              &nbsp;(使用:&nbsp;
                              <strong>{info.usedLicenseNumber}</strong>)
                            </>
                          )}
                          {info.serviceStatus === 'expired' && <Warning />}
                        </>
                      )
                    }
                    endDateInfo={
                      <>
                        {info.serviceStatus !== 'purchased' &&
                          !isInfiniteEndDate && (
                            <>
                              <Label>終了日：</Label>
                              <strong>
                                {generatedInfo.expiredDateTimeString || '---'}
                              </strong>
                            </>
                          )}
                      </>
                    }
                    moreServicesRef={info.moreServicesRef}
                    expanded={info.expanded}
                    setExpanded={info.setExpanded}
                    key={i}
                    notificationPanel={
                      info.notificationPanel && (
                        <NotificationPanel
                          className={classes.notificationPanel}
                          {...info.notificationPanel}
                        />
                      )
                    }
                  />
                );
              })}
          </div>
          {trialServices && trialServices.length > 0 && (
            <>
              <LabelBox className={classes.availableTrialServiceTitle}>
                トライアル利用可能なサービス
              </LabelBox>
              {trialServices.map((serviceId, i) => (
                <AvailableTrialService
                  className={classes.availableTrialService}
                  key={i}
                  serviceId={serviceId}
                />
              ))}
            </>
          )}
        </RootContainer>
        <ProcessingDialog
          state={dialogState ?? 'close'}
          onClickError={onClickDialogClose}
        />
      </PageTemplate>
    </>
  );
};

export interface PJBServiceLinkInfo {
  openService?: { disabled?: boolean; hidden?: boolean };
  answerSurvey?: { disabled?: boolean; hidden?: boolean };
  openSpec?: { disabled?: boolean; hidden?: boolean };
  openTrialSpec?: { disabled?: boolean; hidden?: boolean };
  contract?: { disabled?: boolean; hidden?: boolean };
  purchase?: { disabled?: boolean; hidden?: boolean };
  contact?: { disabled?: boolean; hidden?: boolean };
}

export const PJBServiceInfo = (
  serviceStatus?: ServiceStatus,
  serviceId?: string,
  tenantId?: string,
  url?: string,
  isTenantDeleted?: boolean,
): ServiceInfo => {
  let serviceLinkInfo: PJBServiceLinkInfo;
  switch (serviceStatus) {
    case 'in-use':
      serviceLinkInfo = {
        openService: { disabled: false, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: false, hidden: false },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'will-expire':
      serviceLinkInfo = {
        openService: { disabled: false, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: false, hidden: false },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'expired':
      serviceLinkInfo = {
        openService: { disabled: true, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: false, hidden: false },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'purchased':
      serviceLinkInfo = {
        openService: { disabled: false, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: false },
        purchase: { disabled: false, hidden: true },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'in-preparation': // fall through because of default value
    default:
      serviceLinkInfo = {
        openService: { disabled: true, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: true, hidden: false },
        contact: { disabled: false, hidden: false },
      };
  }

  return {
    iconAlt: 'Project Board Icon',
    iconImgSrc: `${process.env.PUBLIC_URL}/images/icon-project-board@2x.png`,
    serviceName: 'Project Board',
    serviceNameComponent: 'h3',
    aboutServiceLabel: 'サービスについて',
    aboutContractLabel: 'ご契約内容について',
    moreServicesLabel: 'もっと使いこなすために…',
    aboutServiceLinks: [
      {
        id: 'to-service',
        label: 'サービスを開く',
        type: 'link',
        href: url,
        externalLink: true,
        target: '_blank',
        disabled: serviceLinkInfo?.openService?.disabled || false,
        hidden: serviceLinkInfo?.openService?.hidden || false,
      },
      {
        id: 'to-survey',
        label: 'アンケートへ回答',
        type: 'link',
        href: '#',
        disabled: serviceLinkInfo?.answerSurvey?.disabled || false,
        hidden: serviceLinkInfo?.answerSurvey?.hidden || false,
      },
      {
        id: 'to-service-spec',
        label: 'サービス仕様書',
        type: 'link',
        href: 'https://bizapp.worksap.co.jp/pjb/service-spec',
        externalLink: true,
        target: '_blank',
        disabled: serviceLinkInfo?.openSpec?.disabled || false,
        hidden: serviceLinkInfo?.openSpec?.hidden || false,
      },
      {
        id: 'to-trial-spec',
        label: 'トライアルサービス仕様書',
        type: 'link',
        href: 'https://bizapp.worksap.co.jp/pjb/trial-spec',
        target: '_blank',
        externalLink: true,
        disabled: serviceLinkInfo?.openTrialSpec?.disabled || false,
        hidden: serviceLinkInfo?.openTrialSpec?.hidden || false,
      },
    ],
    aboutContractLinks: [
      {
        id: 'to-plan-contract',
        label: 'ご契約内容の確認',
        type: 'link',
        href: '/plan-contract',
        disabled: serviceLinkInfo?.contract?.disabled || false,
        hidden: serviceLinkInfo?.contract?.hidden || false,
      },
      {
        id: 'to-purchase',
        label: '有料プラン登録',
        type: 'link',
        href: isTenantDeleted
          ? `/contact?tenantId=${tenantId}&serviceId=${serviceId}&type=${INQUIRY_TYPE_CONTRACT}&detail=${encodeURIComponent(
              INQUIRY_DETAIL_PURCHASE_WITH_VOLUME,
            )}`
          : `/?serviceId=${serviceId}&tenantId=${tenantId}&purchaseDialog=true`,
        disabled: serviceLinkInfo?.purchase?.disabled || false,
        hidden: serviceLinkInfo?.purchase?.hidden || false,
      },
      {
        id: 'to-contact',
        label: 'お問い合わせ',
        type: 'link',
        href: `/contact`,
        state: {
          serviceId: serviceId || '',
          tenantId: tenantId || '',
        },
        disabled: serviceLinkInfo?.contact?.disabled || false,
        hidden: serviceLinkInfo?.contact?.hidden || false,
      },
    ],
    moreServicesLeftLinks: [
      {
        id: 'first-guide',
        label: 'はじめてガイド',
        type: 'link',
        href: 'https://bizapp.worksap.co.jp/resource/pjb-manual0001',
        externalLink: true,
        disabled: false,
        target: '_blank',
      },
      {
        id: 'for-user',
        label: '一般ユーザー向け',
        type: 'title',
        subLinks: [
          {
            id: 'user-operation-guide',
            label: '操作ガイド',
            type: 'link',
            href: 'https://bizapp.worksap.co.jp/resource/pjb-r0003',
            externalLink: true,
            disabled: false,
            target: '_blank',
          },
          {
            id: 'user-faq',
            label: 'FAQ',
            type: 'link',
            href: 'https://bizapp.worksap.co.jp/resource/pjb-r0002',
            externalLink: true,
            disabled: false,
            target: '_blank',
          },
        ],
      },
    ],
    moreServicesRightLinks: [
      {
        id: 'for-admin',
        label: '管理者向け',
        type: 'title',
        subLinks: [
          {
            id: 'admin-operation-guide',
            label: '操作ガイド',
            type: 'link',
            href: 'https://bizapp.worksap.co.jp/resource/pjb-r0005',
            externalLink: true,
            disabled: false,
            target: '_blank',
          },
          {
            id: 'admin-faq',
            label: 'FAQ',
            type: 'link',
            href: 'https://bizapp.worksap.co.jp/resource/pjb-r0004',
            externalLink: true,
            disabled: false,
            target: '_blank',
          },
        ],
      },
      {
        id: 'for-guest',
        label: 'ゲストユーザー向け',
        type: 'title',
        subLinks: [
          {
            id: 'guest-operation-guide',
            label: '操作ガイド',
            type: 'link',
            href: 'https://bizapp.worksap.co.jp/resource/pjb-r0001',
            externalLink: true,
            disabled: false,
            target: '_blank',
          },
        ],
      },
    ],
  };
};

export interface WorkflowServiceLinkInfo {
  openService?: { disabled?: boolean; hidden?: boolean };
  answerSurvey?: { disabled?: boolean; hidden?: boolean };
  openSpec?: { disabled?: boolean; hidden?: boolean };
  openTrialSpec?: { disabled?: boolean; hidden?: boolean };
  contract?: { disabled?: boolean; hidden?: boolean };
  purchase?: { disabled?: boolean; hidden?: boolean };
  contact?: { disabled?: boolean; hidden?: boolean };
}

export const WorkflowServiceInfo = (
  serviceStatus?: ServiceStatus,
  serviceId?: string,
  tenantId?: string,
  url?: string,
): ServiceInfo => {
  let serviceLinkInfo: WorkflowServiceLinkInfo;
  switch (serviceStatus) {
    case 'freemium':
    case 'in-use':
      serviceLinkInfo = {
        openService: { disabled: false, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: false, hidden: false },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'will-expire':
      serviceLinkInfo = {
        openService: { disabled: false, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: false, hidden: false },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'expired':
      serviceLinkInfo = {
        openService: { disabled: true, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: false, hidden: false },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'purchased':
      serviceLinkInfo = {
        openService: { disabled: false, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: false },
        purchase: { disabled: false, hidden: true },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'in-preparation': // fall through because of default value
    default:
      serviceLinkInfo = {
        openService: { disabled: true, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: true, hidden: false },
        contact: { disabled: false, hidden: false },
      };
  }

  return {
    iconAlt: 'Workflow Icon',
    iconImgSrc: `${process.env.PUBLIC_URL}/images/logo/workflow.png`,
    serviceName: 'Workflow',
    serviceNameComponent: 'h3',
    aboutServiceLabel: 'サービスについて',
    aboutContractLabel: 'ご契約内容について',
    moreServicesLabel: 'もっと使いこなすために…',
    aboutServiceLinks: [
      {
        id: 'to-service',
        label: 'サービスを開く',
        type: 'link',
        href: url,
        externalLink: true,
        target: '_blank',
        disabled: serviceLinkInfo?.openService?.disabled || false,
        hidden: serviceLinkInfo?.openService?.hidden || false,
      },
      {
        id: 'to-survey',
        label: 'アンケートへ回答',
        type: 'link',
        href: '#',
        disabled: serviceLinkInfo?.answerSurvey?.disabled || false,
        hidden: serviceLinkInfo?.answerSurvey?.hidden || false,
      },
      {
        id: 'to-service-spec',
        label: 'サービス仕様書',
        type: 'link',
        href: 'https://bizapp.worksap.co.jp/workflow/service-spec',
        externalLink: true,
        target: '_blank',
        disabled: serviceLinkInfo?.openSpec?.disabled || false,
        hidden: serviceLinkInfo?.openSpec?.hidden || false,
      },
      {
        id: 'to-trial-spec',
        label: 'トライアルサービス仕様書',
        type: 'link',
        href: 'https://bizapp.worksap.co.jp/workflow/trial-spec',
        target: '_blank',
        externalLink: true,
        disabled: serviceLinkInfo?.openTrialSpec?.disabled || false,
        hidden: serviceLinkInfo?.openTrialSpec?.hidden || false,
      },
    ],
    aboutContractLinks: [
      {
        id: 'to-plan-contract',
        label: 'ご契約内容の確認',
        type: 'link',
        href: '/plan-contract',
        disabled: serviceLinkInfo?.contract?.disabled || false,
        hidden: serviceLinkInfo?.contract?.hidden || false,
      },
      {
        id: 'to-purchase',
        label: '有料プラン登録',
        type: 'link',
        href: `/contact?tenantId=${tenantId}&serviceId=${serviceId}&type=${INQUIRY_TYPE_CONTRACT}&detail=${encodeURIComponent(
          INQUIRY_DETAIL_PURCHASE_WITH_VOLUME,
        )}`,
        disabled: serviceLinkInfo?.purchase?.disabled || false,
        hidden: serviceLinkInfo?.purchase?.hidden || false,
      },
      {
        id: 'to-contact',
        label: 'お問い合わせ',
        type: 'link',
        href: `/contact?serviceId=${serviceId}&tenantId=${tenantId}`,
        state: {
          serviceId: serviceId || '',
          tenantId: tenantId || '',
        },
        disabled: serviceLinkInfo?.contact?.disabled || false,
        hidden: serviceLinkInfo?.contact?.hidden || false,
      },
    ],
    moreServicesLeftLinks: [
      {
        id: 'zendesk-helper-center',
        label: 'Workflowヘルプセンター',
        type: 'link',
        href: 'https://worksap.zendesk.com/',
        externalLink: true,
        disabled: false,
        target: '_blank',
        comment:
          '※Workflowヘルプセンターでは、マニュアルのダウンロードや、よくある質問を確認することができます。',
      },
      {
        id: 'video',
        label: '無料トライアルお試しガイド',
        type: 'link',
        href: 'https://www.youtube.com/watch?v=Or_nKZk4Tck',
        externalLink: true,
        disabled: false,
        target: '_blank',
      },
    ],
  };
};

export interface ChatbotServiceLinkInfo {
  openService?: { disabled?: boolean; hidden?: boolean };
  answerSurvey?: { disabled?: boolean; hidden?: boolean };
  openSpec?: { disabled?: boolean; hidden?: boolean };
  openTrialSpec?: { disabled?: boolean; hidden?: boolean };
  contract?: { disabled?: boolean; hidden?: boolean };
  purchase?: { disabled?: boolean; hidden?: boolean };
  contact?: { disabled?: boolean; hidden?: boolean };
}

export const ChatbotServiceInfo = (
  serviceStatus?: ServiceStatus,
  serviceId?: string,
  tenantId?: string,
  url?: string,
): ServiceInfo => {
  let serviceLinkInfo: ChatbotServiceLinkInfo;
  switch (serviceStatus) {
    case 'in-use':
      serviceLinkInfo = {
        openService: { disabled: false, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: false, hidden: false },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'will-expire':
      serviceLinkInfo = {
        openService: { disabled: false, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: false, hidden: false },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'expired':
      serviceLinkInfo = {
        openService: { disabled: true, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: false, hidden: false },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'purchased':
      serviceLinkInfo = {
        openService: { disabled: false, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: false },
        purchase: { disabled: false, hidden: true },
        contact: { disabled: false, hidden: false },
      };
      break;
    case 'in-preparation': // fall through because of default value
    default:
      serviceLinkInfo = {
        openService: { disabled: true, hidden: false },
        answerSurvey: { disabled: true, hidden: true },
        openSpec: { disabled: false, hidden: false },
        openTrialSpec: { disabled: false, hidden: false },
        contract: { disabled: false, hidden: true },
        purchase: { disabled: true, hidden: false },
        contact: { disabled: false, hidden: false },
      };
  }

  return {
    iconAlt: 'Chatbot Icon',
    iconImgSrc: `${process.env.PUBLIC_URL}/images/logo/chatbot.png`,
    serviceName: 'Chatbot',
    serviceNameComponent: 'h3',
    aboutServiceLabel: 'サービスについて',
    aboutContractLabel: 'ご契約内容について',
    moreServicesLabel: 'もっと使いこなすために…',
    aboutServiceLinks: [
      {
        id: 'to-service',
        label: 'サービスを開く',
        type: 'link',
        href: url,
        externalLink: true,
        target: '_blank',
        disabled: serviceLinkInfo?.openService?.disabled || false,
        hidden: serviceLinkInfo?.openService?.hidden || false,
      },
      {
        id: 'to-survey',
        label: 'アンケートへ回答',
        type: 'link',
        href: '#',
        disabled: serviceLinkInfo?.answerSurvey?.disabled || false,
        hidden: serviceLinkInfo?.answerSurvey?.hidden || false,
      },
      {
        id: 'to-service-spec',
        label: 'サービス仕様書',
        type: 'link',
        href: 'https://bizapp.worksap.co.jp/chatbot/service-spec',
        externalLink: true,
        target: '_blank',
        disabled: serviceLinkInfo?.openSpec?.disabled || false,
        hidden: serviceLinkInfo?.openSpec?.hidden || false,
      },
      {
        id: 'to-trial-spec',
        label: 'トライアルサービス仕様書',
        type: 'link',
        href: 'https://bizapp.worksap.co.jp/chatbot/trial-spec',
        target: '_blank',
        externalLink: true,
        disabled: serviceLinkInfo?.openTrialSpec?.disabled || false,
        hidden: serviceLinkInfo?.openTrialSpec?.hidden || false,
      },
    ],
    aboutContractLinks: [
      {
        id: 'to-plan-contract',
        label: 'ご契約内容の確認',
        type: 'link',
        href: '/plan-contract',
        disabled: serviceLinkInfo?.contract?.disabled || false,
        hidden: serviceLinkInfo?.contract?.hidden || false,
      },
      {
        id: 'to-purchase',
        label: '有料プラン登録',
        type: 'link',
        href: `/contact?tenantId=${tenantId}&serviceId=${serviceId}&type=${INQUIRY_TYPE_CONTRACT}&detail=${encodeURIComponent(
          INQUIRY_DETAIL_PURCHASE,
        )}`,
        disabled: serviceLinkInfo?.purchase?.disabled || false,
        hidden: serviceLinkInfo?.purchase?.hidden || false,
      },
      {
        id: 'to-contact',
        label: 'お問い合わせ',
        type: 'link',
        href: `/contact?serviceId=${serviceId}&tenantId=${tenantId}`,
        state: {
          serviceId: serviceId || '',
          tenantId: tenantId || '',
        },
        disabled: serviceLinkInfo?.contact?.disabled || false,
        hidden: serviceLinkInfo?.contact?.hidden || false,
      },
    ],
    moreServicesLeftLinks: [
      {
        id: 'for-admin',
        label: '管理者向け',
        type: 'title',
        subLinks: [
          {
            id: 'first-guide',
            label: 'はじめてガイド',
            type: 'link',
            href: 'https://bizapp.worksap.co.jp/resource/chatbot-r0007',
            externalLink: true,
            disabled: false,
            target: '_blank',
          },
          {
            id: 'admin-operation-guide',
            label: '管理機能操作マニュアル',
            type: 'link',
            href: 'https://bizapp.worksap.co.jp/resource/chatbot-r0001',
            externalLink: true,
            disabled: false,
            target: '_blank',
          },
          {
            id: 'admin-q&a',
            label: 'Q&A作成ガイド',
            type: 'link',
            href: 'https://bizapp.worksap.co.jp/resource/chatbot-r0004',
            externalLink: true,
            disabled: false,
            target: '_blank',
          },
          {
            id: 'admin-faq',
            label: '管理者向けFAQ',
            type: 'link',
            href: 'https://bizapp.worksap.co.jp/resource/chatbot-r0003',
            externalLink: true,
            disabled: false,
            target: '_blank',
          },
        ],
      },
    ],
  };
};
