import React from 'react';
import { Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import {
  IncreaseLicenseApplicationStep3,
  IncreaseLicenseApplicationStep3Props,
} from '@bizapp-frontend/customer/templates/form/IncreaseLicenseApplicationStep3';
import { IncreaseLicenseApplicationCommonProps } from '@bizapp-frontend/customer/pages/form/pjb/IncreaseLicenseApplication';

export interface IncreaseLicenseFormStep3Props
  extends IncreaseLicenseApplicationStep3Props,
    IncreaseLicenseApplicationCommonProps {}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      backgroundColor: '#FFFFFF',
    },
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(0, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
  }),
);

export const IncreaseLicenseFormStep3: React.FC<IncreaseLicenseFormStep3Props> = () => {
  const navigate = useNavigate();
  const classes = useStyle();

  return (
    <>
      <div className={classes.root}>
        <IncreaseLicenseApplicationStep3 />
        <Link
          onClick={() => {
            navigate('/');
          }}
          className={classes.backlink}
        >
          ユーザーページへ戻る
        </Link>
      </div>
    </>
  );
};
