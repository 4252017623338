import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Box, useMediaQuery } from '@material-ui/core';
import { FormButton } from '@bizapp-frontend/customer/molecules/form/FormButton';
import { getCookie, timeout } from '@bizapp-frontend/customer/pages/util';
import { useAuth0 } from '@auth0/auth0-react';
import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';
import { v4 as uuid } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { StyledButton } from '@bizapp-frontend/customer/molecules/StyledButton';
import maxBy from 'lodash/maxBy';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    borderRadius: '3px',
    margin: theme.spacing(1, 0),
  },
  test: {
    flexGrow: 1,
  },
  content: {
    padding: theme.spacing(3, 4),
  },
  title: {
    fontSize: '20px',
    lineHeight: '30px',
    marginBottom: theme.spacing(2),
    '& span': {
      display: 'block',
      [theme.breakpoints.up(425)]: {
        display: 'inline',
      },
      [theme.breakpoints.up(1024)]: {
        display: 'block',
      },
      [theme.breakpoints.up(1080)]: {
        display: 'inline',
      },
    },
  },
  contractInfo: {
    marginLeft: '28px',
    flexGrow: 1,
  },
  infoValue: {
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
    '& span': {
      display: 'block',
      [theme.breakpoints.up(768)]: {
        display: 'inline',
      },
    },
  },
  label: {
    minWidth: '100px',
  },
  actionBtn: {
    '& > button': {
      padding: '6px 37px',
      marginBottom: theme.spacing(1),
      height: '32px',
      width: '100%',
    },
  },
}));

// ref: https://stripe.com/docs/api/payment_methods/object#payment_method_object-card
export type PaymentMethodType = 'card' | 'invoice' | 'none';
export type PaymentMethod = {
  type: PaymentMethodType;
  card?: {
    brand: string;
    last4: string;
  };
};

interface ApplicationDraft {
  applicationDraftType: string;
  userKind: string;
  userId: string;
  customerId: string;
  tenantId: string;
  jsonData: string;
  submitted: boolean;
}

export interface ContractCardProps {
  className?: string;
  applicationControlAPIBaseUrl?: string;
  paymentGatewayAPIBaseUrl?: string;
  iconSrc: string;
  title: React.ReactNode;
  licenseNumber: number;
  paymentMethod: PaymentMethod;
  nextChargeTime: number;
  serviceId: string;
  tenantId: string;
  customerId?: string;
  userId?: string;
  setDialogState?: (
    value:
      | ProcessingDialogState
      | ((prevVar: ProcessingDialogState) => ProcessingDialogState),
  ) => void;
  usageKind: 'trial' | 'purchased' | 'freemium';
}

export const ContractCard: React.FC<ContractCardProps> = ({
  className,
  applicationControlAPIBaseUrl,
  paymentGatewayAPIBaseUrl,
  iconSrc,
  title,
  licenseNumber,
  paymentMethod,
  nextChargeTime,
  serviceId,
  tenantId,
  customerId,
  userId,
  setDialogState,
  usageKind,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.tablet),
  );
  const { getAccessTokenSilently } = useAuth0();
  const applicationDraftType = 'card-update';

  const iconSize = mobile ? 48 : 96;

  const [submitForm, setSubmitForm] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const handleClick = React.useCallback(async () => {
    // disable button to prevent multiple-submit
    setDisableSubmit(true);
    setSubmitForm(true);
  }, []);

  const [draftId, setDraftId] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    if (customerId && tenantId) {
      const getDraftById = async (draftId: string) => {
        // console.debug('get draft by cookie id');
        const accessToken = await getAccessTokenSilently();
        const method = 'GET';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/customers/${customerId}/drafts/${draftId}?draft-type=${applicationDraftType}`;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const resp = await fetch(url, {
          method: method,
          headers: headers,
        });

        if (resp.status === 200) {
          return Promise.resolve(resp.json());
        } else {
          return Promise.resolve(undefined);
        }
      };
      const getDraftByTenant = async (tenantId: string) => {
        // console.debug('get draft by current tenant id');
        const accessToken = await getAccessTokenSilently();
        const method = 'GET';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/tenants/${tenantId}?draft-type=${applicationDraftType}`;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const resp = await fetch(url, {
          method: method,
          headers: headers,
        });

        if (resp.status === 200) {
          const drafts: ApplicationDraft[] = await resp.json();
          // const submittedDraft = drafts.find((draft) => draft.submitted);
          // if (submittedDraft) {
          //   return Promise.resolve(submittedDraft);
          // } else {
          return Promise.resolve(maxBy(drafts, 'timestamp'));
          // }
        } else {
          return Promise.reject(
            new Error(
              `Failed in getting step 1 session data. status is ${resp.status}`,
            ),
          );
        }
      };

      const f = async () => {
        const cookieDraftId = getCookie(
          `Draft-${applicationDraftType}-${tenantId}`,
        );
        setDraftId(cookieDraftId);
        const getDraftFn = cookieDraftId
          ? () => getDraftById(cookieDraftId)
          : () => getDraftByTenant(tenantId);

        setDialogState && setDialogState('wait');
        try {
          const draft = await getDraftFn();
          setDialogState && setDialogState('close');

          if (draft) {
            if (draft.submitted) {
              setDraftId(undefined);
              document.cookie = `Draft-${applicationDraftType}-${tenantId}=; path=/; max-age=0`;
            } else {
              setDraftId(draft.applicationDraftId);
            }
          } else {
            setDraftId(undefined);
          }
        } catch {
          // todo: error handling
          setDraftId(undefined);
        }
      };
      f();
    }
  }, [
    applicationControlAPIBaseUrl,
    customerId,
    getAccessTokenSilently,
    setDialogState,
    tenantId,
  ]);

  React.useEffect(() => {
    if (submitForm) {
      setSubmitForm(false);
      const createDraft = async () => {
        const method = 'POST';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/tenants/${tenantId}`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const _body = {
          applicationDraftType: applicationDraftType,
          userKind: 'customer',
          userId: userId,
          customerId: customerId,
          tenantId: tenantId,
          serviceId: serviceId,
          notificationKind: 'email',
          jsonData: JSON.stringify({}),
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        return resp.status === 201
          ? Promise.resolve(resp.text())
          : Promise.reject(
              new Error(`Failed in submitting form. status is ${resp.status}`),
            );
      };
      const updateDraft = async (draftId: string) => {
        const method = 'PUT';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/customers/${customerId}/drafts/${draftId}`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const _body = {
          applicationDraftType: applicationDraftType,
          jsonData: JSON.stringify({}),
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        return resp.status === 200
          ? Promise.resolve(resp.text())
          : Promise.reject(
              new Error(`Failed in submitting form. status is ${resp.status}`),
            );
      };
      const startRegisterCard = async (applicationDraftId: string) => {
        const localSessionDataKey =
          'BIZAPP_CUSTOMER_UPDATE_CARD_SESSION_DATA_' + uuid();
        window.localStorage.setItem(
          localSessionDataKey,
          JSON.stringify({ tenantId, serviceId, from: 'contract' }),
        );
        const method = 'POST';
        const paymentGatewayId = 'stripe';
        const url = `${paymentGatewayAPIBaseUrl}/api/payment-gateway/${paymentGatewayId}/customers/${customerId}/card`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const baseUrl = `${window.location.protocol}//${window.location.host}/plan-contract`;
        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify({
            success_url: `${baseUrl}/success?sessionId={CHECKOUT_SESSION_ID}&sessionDataKey=${localSessionDataKey}`,
            cancel_url: `${baseUrl}/cancel?sessionDataKey=${localSessionDataKey}`,
            metadata: {
              userId: userId,
              customerId: customerId,
              applicationDraftId: applicationDraftId,
              applicationDraftType: applicationDraftType,
            },
          }),
        });

        return resp.ok
          ? Promise.resolve(resp.json())
          : Promise.reject(
              new Error(
                `Failed in startRegisterCard. status is ${resp.status}`,
              ),
            );
      };

      const f = async () => {
        try {
          setDialogState && setDialogState('wait');
          let _draftId;
          if (draftId) {
            _draftId = draftId;
            await timeout(updateDraft(_draftId));
            document.cookie = `Draft-${applicationDraftType}-${tenantId}=${_draftId}; path=/; max-age=5184000`;
          } else {
            _draftId = await timeout(createDraft());
            document.cookie = `Draft-${applicationDraftType}-${tenantId}=${_draftId}; path=/; max-age=5184000`;
          }

          const resp = await timeout(startRegisterCard(_draftId));
          window.location.href = resp.url;

          setDialogState && setDialogState('close');
        } catch (err) {
          setDisableSubmit(false);
          setDialogState && setDialogState('error');
        }
      };
      f();
    } else {
      setSubmitForm(false);
      setDisableSubmit(false);
    }
  }, [
    applicationControlAPIBaseUrl,
    customerId,
    draftId,
    getAccessTokenSilently,
    paymentGatewayAPIBaseUrl,
    serviceId,
    setDialogState,
    submitForm,
    tenantId,
    userId,
  ]);

  const nextChargeDateTime = new Date(
    nextChargeTime + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000,
  );
  const nextChargeDateTimeString = `${nextChargeDateTime.getFullYear()}年${(
    '0' +
    (nextChargeDateTime.getMonth() + 1)
  ).slice(-2)}月${('0' + nextChargeDateTime.getDate()).slice(-2)}日`;

  const contractInfoPart = (
    <>
      {serviceId !== 'chatbot' && (
        <>
          <Box display="flex" marginBottom={0.25}>
            <Typography variant="body1" className={classes.label}>
              ライセンス数：
            </Typography>
            <Typography variant="body1" className={classes.infoValue}>
              {usageKind === 'freemium' ? '---' : licenseNumber}
            </Typography>
          </Box>
        </>
      )}

      {paymentMethod.type !== 'none' && (
        <>
          <Box display="flex" marginBottom={0.25}>
            <Typography variant="body1" className={classes.label}>
              お支払い方法：
            </Typography>
            {paymentMethod.type === 'card' && (
              <Typography variant="body1" className={classes.infoValue}>
                <span>クレジットカード</span>
                <span>
                  （{paymentMethod.card?.brand} ****{paymentMethod.card?.last4}
                  ）
                </span>
              </Typography>
            )}
            {paymentMethod.type === 'invoice' && (
              <Typography variant="body1" className={classes.infoValue}>
                請求書払い
              </Typography>
            )}
          </Box>

          <Box display="flex" marginBottom={3}>
            <Typography variant="body1">次回お支払い予定日：</Typography>
            <Typography variant="body1" className={classes.infoValue}>
              {nextChargeDateTimeString}
            </Typography>
          </Box>
        </>
      )}
    </>
  );

  const toIncreaseLicense = () => {
    navigate(
      `/application/${serviceId}/increase-license?serviceId=${serviceId}&tenantId=${tenantId}`,
    );
  };

  const actionsPart = (
    <>
      {paymentMethod.type !== 'none' && (
        <Box>
          {serviceId === 'pjb' && (
            <div className={classes.actionBtn}>
              <StyledButton
                variant="secondary"
                label="ライセンス追加購入"
                onClick={toIncreaseLicense}
              />
            </div>
          )}
          {paymentMethod.type === 'card' && (
            <FormButton
              id="update-card-btn"
              variant="secondary"
              label="お支払い方法の変更"
              className={classes.actionBtn}
              disabled={disableSubmit}
              postClick={handleClick}
            />
          )}
        </Box>
      )}
    </>
  );

  return (
    <Card className={`${classes.root} ${className}`}>
      <CardContent className={classes.content}>
        <Box>
          <Box display="flex">
            <Box>
              <img src={iconSrc} alt="" width={iconSize} height={iconSize} />
            </Box>
            <Box className={classes.contractInfo}>
              <Box display="flex">
                <Box flexGrow={1}>
                  <Typography variant="h4" className={classes.title}>
                    {title}
                  </Typography>
                </Box>
                {!mobile && actionsPart}
              </Box>
              {!mobile && contractInfoPart}
            </Box>
          </Box>
          {mobile && contractInfoPart}
          {mobile && actionsPart}
        </Box>
      </CardContent>
    </Card>
  );
};
