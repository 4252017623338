import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { getCookie } from '@bizapp-frontend/customer/pages/util';
import {
  Container,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core/styles';
import { PurchaseApplicationSuccess as PurchaseApplicationSuccessTemplate } from '@bizapp-frontend/customer/templates/form/PurchaseApplicationSuccess';
import { PageTemplate } from '@bizapp-frontend/customer/templates/PageTemplate';

export interface PurchaseApplicationSuccessProps
  extends PurchaseApplicationSuccessComponentProps {}

interface LocalSessionData {
  tenantId?: string;
  serviceId?: string;
  customerKind?: string;
}

const useStepperStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F2f3fb',
      padding: theme.spacing(5, 2),
      [theme.breakpoints.up(768)]: {
        padding: theme.spacing(5, 4),
      },
    },
    header: {
      color: '#333333',
      '& .MuiTypography-h1': {
        fontSize: '32px',
        lineHeight: '48px',
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.down(768)]: {
          fontSize: '24px',
          lineHeight: '36px',
        },
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 'normal',
        textAlign: 'center',
        margin: '16px auto 32px',
      },
    },

    stepper: {
      margin: theme.spacing(3, 'auto'),
      width: '264px',
      backgroundColor: 'initial',
      padding: 0,
      '& .MuiStep-horizontal': { padding: 0 },
      '& .MuiStepLabel-iconContainer': { padding: 0 },
    },
    stepDescription: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      color: '#333333',
      textAlign: 'center',
      '& .MuiTypography-subtitle1': {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '21px',
      },
    },
  }),
);

const useStepIconStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      backgroundColor: '#DADDE0',
      color: '#7F7F7F',
    },
    span: {
      textAlign: 'center',
      fontSize: '12px',
    },
    active: {
      backgroundColor: '#4285F4',
      color: '#FFFFFF',
    },
    completed: {
      backgroundColor: '#FFFFFF',
      color: '#4285F4',
      width: '22px',
      height: '22px',
      border: `1px solid #4285F4`,
    },
  }),
);

const StyledConnector = styled(StepConnector)({
  line: { borderColor: '#DADDE0' },
});

const StyledStepIcon = (props: StepIconProps) => {
  const classes = useStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={`${classes.root} ${active ? classes.active : ''} ${
        completed ? classes.completed : ''
      }`}
    >
      <span className={classes.span}>{props.icon}</span>
    </div>
  );
};

export const PurchaseApplicationSuccess: React.FC<PurchaseApplicationSuccessProps> = () => {
  const classes = useStepperStyles();

  const query = new URLSearchParams(useLocation().search);
  const localSessionDataKey = query.get('sessionDataKey') ?? undefined;
  const activeStep = 1;
  return (
    <PageTemplate>
      <Container disableGutters maxWidth={false} className={`${classes.root}`}>
        <Container
          component="header"
          disableGutters
          maxWidth={false}
          className={classes.header}
        >
          <Typography variant="h1">有料プラン利用登録</Typography>
          <Stepper
            activeStep={activeStep}
            connector={<StyledConnector />}
            className={classes.stepper}
          >
            {Array(3)
              .fill(0)
              .map((_, i) => (
                <Step completed={activeStep > i} key={i}>
                  <StepLabel StepIconComponent={StyledStepIcon} />
                </Step>
              ))}
          </Stepper>
        </Container>

        <PurchaseApplicationSuccessComponent
          localSessionDataKey={localSessionDataKey}
        />
      </Container>
    </PageTemplate>
  );
};

export interface PurchaseApplicationSuccessComponentProps {
  localSessionDataKey?: string;
}

const PurchaseApplicationSuccessComponent: React.FC<PurchaseApplicationSuccessComponentProps> = ({
  localSessionDataKey,
}) => {
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [tenantId, setTenantId] = React.useState('');
  const [serviceId, setServiceId] = React.useState('');
  const [customerKind, setCustomerKind] = React.useState('');
  const getLocalSessionData = (localSessionDataKey: string) => {
    const sessionData: LocalSessionData = JSON.parse(
      window.localStorage.getItem(localSessionDataKey) ?? '{}',
    );
    if (
      sessionData.tenantId &&
      sessionData.serviceId &&
      sessionData.customerKind
    ) {
      setTenantId(sessionData.tenantId);
      setServiceId(sessionData.serviceId);
      setCustomerKind(sessionData.customerKind);
    }
  };

  React.useEffect(() => {
    if (localSessionDataKey) {
      getLocalSessionData(localSessionDataKey);
    }
  });

  React.useEffect(() => {
    if (localSessionDataKey && tenantId && serviceId && customerKind) {
      // const setDraftSubmitted = async (draftId: string) => {
      //   const method = 'POST';
      //   const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/customers/${customerId}/drafts/${draftId}/submitted`;
      //   const accessToken = await getAccessTokenSilently();
      //   const headers = {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${accessToken}`,
      //   };
      //   const _body = {
      //     applicationDraftType: 'purchase-step1',
      //     paymentTiming: 'immediate',
      //     paymentSessionId: stripeSessionId,
      //   };
      //
      //   const resp = await fetch(url, {
      //     method: method,
      //     headers: headers,
      //     body: JSON.stringify(_body),
      //   });
      //
      //   return resp.status === 201
      //     ? Promise.resolve(true)
      //     : Promise.reject(
      //         new Error(
      //           `Failed in set draft submitted. status is ${resp.status}`,
      //         ),
      //       );
      // };

      const f = async () => {
        try {
          const draftId = getCookie(`Draft-${tenantId}`);
          if (draftId) {
            // await timeout(setDraftSubmitted(draftId));
            if (localSessionDataKey) {
              window.localStorage.removeItem(localSessionDataKey);
            }
            document.cookie = `Draft-${tenantId}=${draftId}; path=/; max-age=0`;
          }
          navigate(
            `/application/pjb/purchase?step=2&serviceId=${serviceId}&tenantId=${tenantId}&customerKind=${customerKind}`,
          );
        } catch (e) {
          console.error(e);
        }
      };
      f();
    }
  }, [
    customerKind,
    getAccessTokenSilently,
    localSessionDataKey,
    navigate,
    serviceId,
    tenantId,
  ]);

  return (
    <>
      <PurchaseApplicationSuccessTemplate />
    </>
  );
};
