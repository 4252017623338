import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      padding: '24px 24px 8px',
      '& h2': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        padding: '4px',
        lineHeight: '12px',
        fontWeight: 'inherit',
      },
      '& svg': {
        color: 'primary',
        width: '32px',
        minWidth: '32px',
        marginRight: '8px',
      },
    },
    actions: {
      padding: '16px 24px',
    },
    titleIcon: {
      color: '#4285f4',
    },
    okButton: {
      height: '32px',
      color: '#4285f4',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: 'normal',
      transition: 'none !important',
      borderRadius: '2px',
      padding: '12px',
      '&:hover': {
        backgroundColor: '#ecf2fd',
      },
    },
    cancelButton: {
      height: '32px',
      color: '#7f7f7f',
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: 'normal',
      transition: 'none !important',
      borderRadius: '2px',
      padding: '12px',
      '&:hover': {
        backgroundColor: '#cdd0d3',
      },
    },
  }),
);

export interface ConfirmationDialogProps {
  show: boolean;
  description: string;
  okText?: string;
  cancelText?: string;
  onClickOk?: React.MouseEventHandler<HTMLButtonElement>;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  show,
  description,
  okText = 'OK',
  cancelText = 'CANCEL',
  onClickOk = () => {},
  onClickCancel = () => {},
}) => {
  const classes = useStyles();

  return (
    <>
      <Dialog open={show}>
        <DialogTitle className={classes.title}>
          <InfoIcon fontSize={'large'} className={classes.titleIcon} />
          {description}
        </DialogTitle>
        <DialogActions className={classes.actions}>
          <Button onClick={onClickOk} className={classes.okButton}>
            {okText}
          </Button>
          <Button onClick={onClickCancel} className={classes.cancelButton}>
            {cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
