import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  RepurchaseApplicationStep2,
  RepurchaseApplicationStep2Props,
} from '@bizapp-frontend/customer/templates/form/RepurchaseApplicationStep2';
import { RepurchaseApplicationCommonProps } from '@bizapp-frontend/customer/pages/form/pjb/RepurchaseApplication';
import { RepurchaseApplicationContext } from '@bizapp-frontend/customer/templates/form/RepurchaseApplication';

export interface RepurchaseFormStep2Props
  extends RepurchaseApplicationStep2Props,
    RepurchaseApplicationCommonProps {
  paymentGatewayAPIBaseUrl: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'block',
      width: '100%',
      maxWidth: '400px',
      height: '48px',
    },
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(3, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
    },
    body: {
      height: '100%',
      backgroundColor: '#FFFFFF',
    },
  }),
);

export const RepurchaseFormStep2: React.FC<RepurchaseFormStep2Props> = () => {
  const classes = useStyle();

  const { step, setStep } = React.useContext(RepurchaseApplicationContext);
  setStep(step + 1);

  return (
    <div className={classes.body}>
      <RepurchaseApplicationStep2 />
    </div>
  );
};
