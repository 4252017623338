import React from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import { Container, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';

import { StyledButton } from '@bizapp-frontend/customer/molecules/StyledButton';
import Meta from '@bizapp-frontend/customer/organisms/Meta';
import { Complete } from '@bizapp-frontend/customer/templates/Complete';

import { GlobalsContext } from '@bizapp-frontend/customer/globals';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiListItem-root': {
        display: 'block',
        textAlign: 'center',
      },
    },
  }),
);

export interface TestToolsProps {
  applicationControlAPIBaseUrl: string;
}

const TestTools: React.FC<TestToolsProps> = ({
  applicationControlAPIBaseUrl,
}) => {
  const classes = useStyle();
  const { state } = React.useContext(GlobalsContext);
  const { getAccessTokenSilently } = useAuth0();
  const [
    disablePaymentFailureButton,
    setDisablePaymentFailureButton,
  ] = React.useState<boolean>(false);

  const handleClickPaymentFailure = () => {
    if (!state || !state.customerId) {
      alert(
        '顧客IDを取得できません。一度ログアウトし、再ログインをお試しください。',
      );
    } else {
      setDisablePaymentFailureButton(true);
      const f = async () => {
        const accessToken = await getAccessTokenSilently();
        const resp = await fetch(
          `${applicationControlAPIBaseUrl}/test-tools/dummy-unpaid`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({
              customerId: state.customerId,
            }),
          },
        );

        if (resp.status === 201) {
          alert('送信成功 - 請求メールを送信いたしました。');
        } else {
          alert('送信失敗 - 請求メールの送信に失敗しました。');
        }
        setDisablePaymentFailureButton(false);
      };
      f();
    }
  };

  return (
    <>
      <Meta>
        <title>ユーザーサイト テストツール</title>
      </Meta>
      <Complete heading1="ユーザーサイト テストツール" heading2="">
        <Container className={classes.root}>
          <Typography variant="h3">情報</Typography>
          <List>
            <ListItem>顧客ID: {state.customerId}</ListItem>
            <ListItem>e-mail: {state.emailAddress}</ListItem>
          </List>
          <Typography variant="h3">請求</Typography>
          <StyledButton
            label="決済に失敗する"
            onClick={handleClickPaymentFailure}
            disabled={disablePaymentFailureButton}
          />
        </Container>
      </Complete>
    </>
  );
};

export default TestTools;
