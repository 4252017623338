import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCookie } from '@bizapp-frontend/customer/pages/util';
import { LocalSessionData } from '@bizapp-frontend/customer/pages/plancontract/ContractUpdateCardSuccess';

export interface ContractUpdateCardCancelProps {}

export const ContractUpdateCardCancel: React.FC<ContractUpdateCardCancelProps> = () => {
  const query = new URLSearchParams(useLocation().search);
  const localSessionDataKey = query.get('sessionDataKey') ?? undefined;
  const navigate = useNavigate();

  React.useEffect(() => {
    const getLocalSessionData = (localSessionDataKey: string) => {
      return JSON.parse(
        window.localStorage.getItem(localSessionDataKey) ?? '{}',
      );
    };

    if (localSessionDataKey) {
      const sessionData: LocalSessionData = getLocalSessionData(
        localSessionDataKey,
      );
      if (sessionData.tenantId && sessionData.serviceId) {
        const applicationDraftType = 'card-update';
        const draftCookieKey = `Draft-${applicationDraftType}-${sessionData.tenantId}`;
        const draftId = getCookie(draftCookieKey);
        if (draftId) {
          document.cookie = `${draftCookieKey}=${draftId}; path=/; max-age=0`;
        }
        window.localStorage.removeItem(localSessionDataKey);

        switch (sessionData.from) {
          case 'increase-license':
            navigate(
              `/application/pjb/increase-license?step=1&serviceId=${sessionData.serviceId}&tenantId=${sessionData.tenantId}`,
            );
            break;
          case 'contract':
          default:
            navigate('/plan-contract');
        }
      } else {
        navigate('/');
      }
    }
  }, [localSessionDataKey, navigate]);

  return <></>;
};
