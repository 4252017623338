import React, { ReactNode } from 'react';
import { PageTemplate } from '@bizapp-frontend/customer/templates/PageTemplate';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Container, Theme, Typography } from '@material-ui/core';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import {
  StyledPaper,
  StyledPaperProps,
} from '@bizapp-frontend/customer/molecules/StyledPaper';

import {
  FormGenerator,
  FormGeneratorProps,
} from '@bizapp-frontend/customer/organisms/form/FormGenerator';

import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

export interface TrialApplicationProps
  extends StyledPaperProps,
    FormGeneratorProps {
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
  grecaptchaKey: string;
  title: ReactNode;
  subTitle: ReactNode;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F2f3fb',
      padding: theme.spacing(5, 2, 10),
      [theme.breakpoints.up(768)]: {
        padding: theme.spacing(5, 4, 10),
      },
    },
    header: {
      color: '#333333',
      '& .MuiTypography-h1': {
        fontSize: '32px',
        lineHeight: '48px',
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.down(768)]: {
          fontSize: '24px',
          lineHeight: '36px',
        },
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 'normal',
        textAlign: 'center',
        margin: '16px auto 32px',
      },
    },
    headingBox: {
      margin: theme.spacing(3, 0),
      textAlign: 'center',
    },
    headingDescriptionText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
      color: '#333333',
    },
    contentsRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
);

export const TrialApplication: React.FC<TrialApplicationProps> = ({
  className,
  title,
  subTitle,
  iconImgSrc,
  iconImgAlt,
  colors,
  formComponents,
  dialogState,
  onClickDialogClose,
  grecaptchaKey,
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <GoogleReCaptchaProvider reCaptchaKey={grecaptchaKey}>
      <PageTemplate>
        <Container
          disableGutters
          maxWidth={false}
          className={`${classes.root}`}
        >
          <Container
            component="header"
            disableGutters
            maxWidth={false}
            className={classes.header}
          >
            <Typography variant="h1">{title}</Typography>
          </Container>
          <div className={classes.contentsRoot}>
            <div className={classes.headingBox}>
              <Typography
                component="div"
                className={classes.headingDescriptionText}
              >
                {subTitle}
              </Typography>
            </div>
            <StyledPaper
              iconImgSrc={iconImgSrc}
              iconImgAlt={iconImgAlt}
              colors={colors}
              className={className}
            >
              <div>
                <FormGenerator {...props} formComponents={formComponents} />
              </div>
              {children}
            </StyledPaper>
          </div>
        </Container>

        <ProcessingDialog
          state={dialogState ?? 'close'}
          onClickError={onClickDialogClose}
        />
      </PageTemplate>
    </GoogleReCaptchaProvider>
  );
};
