import React from 'react';

export type State = {
  development: boolean;
  userId: string;
  userName: string;
  userTel: string;
  emailAddress: string;
  customerId: string;
  customerName: string;
  saasBillingAddressId: string;
};

export type Action =
  | { type: 'SET_DEVELOPMENT'; development: boolean }
  | { type: 'SET_USER_ID'; userId: string }
  | { type: 'SET_USER_NAME'; userName: string }
  | { type: 'SET_EMAIL_ADDRESS'; emailAddress: string }
  | { type: 'SET_USER_TEL'; userTel: string }
  | { type: 'SET_CUSTOMER_ID'; customerId: string }
  | { type: 'SET_CUSTOMER_NAME'; customerName: string }
  | { type: 'SET_SAAS_BILLING_ADDRESS_ID'; saasBillingAddressId: string };

export const GlobalsContext = React.createContext(
  {} as {
    state: State;
    dispatch: React.Dispatch<Action>;
  },
);

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_DEVELOPMENT':
      return {
        ...state,
        development: action.development,
      };
    case 'SET_USER_ID':
      return {
        ...state,
        userId: action.userId,
      };
    case 'SET_USER_NAME':
      return {
        ...state,
        userName: action.userName,
      };
    case 'SET_USER_TEL':
      return {
        ...state,
        userTel: action.userTel,
      };
    case 'SET_EMAIL_ADDRESS':
      return {
        ...state,
        emailAddress: action.emailAddress,
      };
    case 'SET_CUSTOMER_ID':
      return {
        ...state,
        customerId: action.customerId,
      };
    case 'SET_CUSTOMER_NAME':
      return {
        ...state,
        customerName: action.customerName,
      };
    case 'SET_SAAS_BILLING_ADDRESS_ID':
      return {
        ...state,
        saasBillingAddressId: action.saasBillingAddressId,
      };
    default:
      return state;
  }
};

export const initialState: State = {
  development: false,
  userId: '',
  userName: '',
  userTel: '',
  emailAddress: '',
  customerId: '',
  customerName: '',
  saasBillingAddressId: '',
};
