import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { getCookie } from '@bizapp-frontend/customer/pages/util';
import { Container, Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ContractUpdateCardSuccess as ContractUpdateCardSuccessTemplate } from '@bizapp-frontend/customer/templates/ContractUpdateCardSuccess';
import { PageTemplate } from '@bizapp-frontend/customer/templates/PageTemplate';
import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';

export interface ContractUpdateCardSuccessProps
  extends ContractUpdateCardSuccessComponentProps {}

export interface LocalSessionData {
  tenantId?: string;
  serviceId?: string;
  from?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F2f3fb',
      padding: theme.spacing(5, 0, 5),
      [theme.breakpoints.up(768)]: {
        padding: theme.spacing(5, 0),
      },
    },
    header: {
      color: '#333333',
      padding: theme.spacing(0, 0, 5),
      '& .MuiTypography-h1': {
        fontSize: '32px',
        lineHeight: '48px',
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.down(768)]: {
          fontSize: '24px',
          lineHeight: '36px',
        },
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 'normal',
        textAlign: 'center',
        margin: '16px auto 32px',
      },
    },
  }),
);

export const ContractUpdateCardSuccess: React.FC<ContractUpdateCardSuccessProps> = () => {
  const classes = useStyles();

  const query = new URLSearchParams(useLocation().search);
  const localSessionDataKey = query.get('sessionDataKey') ?? undefined;
  return (
    <PageTemplate>
      <Container disableGutters maxWidth={false} className={`${classes.root}`}>
        <Container
          component="header"
          disableGutters
          maxWidth={false}
          className={classes.header}
        >
          <Typography variant="h1">お支払い方法の変更</Typography>
        </Container>

        <ContractUpdateCardSuccessComponent
          localSessionDataKey={localSessionDataKey}
        />
      </Container>
    </PageTemplate>
  );
};

const useBackLinkStyles = makeStyles((theme: Theme) =>
  createStyles({
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(2, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
    body: {
      height: '100%',
      backgroundColor: '#FFFFFF',
      padding: theme.spacing(2),
    },
  }),
);

export interface ContractUpdateCardSuccessComponentProps {
  localSessionDataKey?: string;
}

const ContractUpdateCardSuccessComponent: React.FC<ContractUpdateCardSuccessComponentProps> = ({
  localSessionDataKey,
}) => {
  const classes = useBackLinkStyles();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();
  const applicationDraftType = 'card-update';

  const [tenantId, setTenantId] = React.useState('');
  const [serviceId, setServiceId] = React.useState('');
  const [from, setFrom] = React.useState('');
  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );

  const getLocalSessionData = (localSessionDataKey: string) => {
    const sessionData: LocalSessionData = JSON.parse(
      window.localStorage.getItem(localSessionDataKey) ?? '{}',
    );
    if (sessionData.tenantId && sessionData.serviceId && sessionData.from) {
      setTenantId(sessionData.tenantId);
      setServiceId(sessionData.serviceId);
      setFrom(sessionData.from);
    }
  };

  React.useEffect(() => {
    if (localSessionDataKey) {
      getLocalSessionData(localSessionDataKey);
    }
  }, [localSessionDataKey]);

  React.useEffect(() => {
    if (localSessionDataKey && tenantId) {
      // const setDraftSubmitted = async (draftId: string) => {
      //   const method = 'POST';
      //   const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/customers/${customerId}/drafts/${draftId}/submitted`;
      //   const accessToken = await getAccessTokenSilently();
      //   const headers = {
      //     'Content-Type': 'application/json',
      //     Authorization: `Bearer ${accessToken}`,
      //   };
      //   const _body = {
      //     applicationDraftType: applicationDraftType,
      //   };
      //
      //   const resp = await fetch(url, {
      //     method: method,
      //     headers: headers,
      //     body: JSON.stringify(_body),
      //   });
      //
      //   return resp.status === 201
      //     ? Promise.resolve(true)
      //     : Promise.reject(
      //         new Error(
      //           `Failed in set draft submitted. status is ${resp.status}`,
      //         ),
      //       );
      // };

      const f = async () => {
        try {
          setDialogState('wait');
          const draftCookieKey = `Draft-${applicationDraftType}-${tenantId}`;
          const draftId = getCookie(draftCookieKey);
          if (draftId) {
            // await timeout(setDraftSubmitted(draftId));
            if (localSessionDataKey) {
              window.localStorage.removeItem(localSessionDataKey);
            }
            document.cookie = `${draftCookieKey}=${draftId}; path=/; max-age=0`;

            switch (from) {
              case 'increase-license':
                navigate(
                  `/application/pjb/increase-license?step=1&serviceId=${serviceId}&tenantId=${tenantId}`,
                );
                break;
              case 'contract':
              default:
              // do nothing
            }
            setDialogState('close');
          } else {
            setDialogState('error');
          }
        } catch (e) {
          setDialogState('error');
          console.error(e);
        }
      };
      f();
    } else {
      setDialogState('wait');
    }
  }, [
    from,
    getAccessTokenSilently,
    localSessionDataKey,
    navigate,
    serviceId,
    tenantId,
  ]);

  const handleDialogClick = () => {
    setDialogState('close');
  };

  return (
    <div className={classes.body}>
      <ContractUpdateCardSuccessTemplate
        dialogState={dialogState}
        onClickDialogClose={handleDialogClick}
      />
      {from === 'contract' && (
        <Link
          onClick={() => {
            navigate('/plan-contract');
          }}
          className={classes.backlink}
        >
          {'ユーザーページへ戻る'}
        </Link>
      )}
    </div>
  );
};
