import React from 'react';
import { AppBar, Box, Link, Theme, Toolbar } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 5px 10px #00000014',
      padding: theme.spacing(2.5, 2),
      [theme.breakpoints.up('tablet')]: {
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
      },
      height: theme.spacing(9),
    },
    bgToolbar: { height: theme.spacing(9) },
    toolbar: {
      padding: 0,
      minHeight: theme.spacing(4),
      alignItems: 'center',
    },
    logobox: {
      height: theme.spacing(4),
      flexGrow: 1,
    },
    imageLinks: {},
    logo: { height: theme.spacing(4) },
    dummyLogo: {
      display: 'none',
      [theme.breakpoints.up('tablet')]: {
        display: 'inline',
      },
    },
    verticalDivider: {
      display: 'none',
      [theme.breakpoints.up('tablet')]: {
        display: 'inline-block',
        margin: theme.spacing(0, 2),
      },
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 4),
      },
    },
    link: {
      color: '#154052',
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: 0,
      marginRight: theme.spacing(3),
      display: 'inline-block',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginLeft: theme.spacing(3),
      },
      '&:link': { color: '#154052' },
      '&:visited': { color: '#154052' },
      '&:action': {
        color: '#154052',
        textDecoration: 'underline',
      },
      '&:focus': {
        color: '#154052',
        textDecoration: 'underline',
      },
      '&:hover': {
        color: '#154052',
        textDecoration: 'underline',
      },
    },
  }),
);

export interface HeaderProps {
  from?: string;
  children?: React.ReactNode;
}

const HeaderComponent: React.FC<HeaderProps> = ({ from, children }) => {
  const classes = useStyle();

  /**
   * Dealed with a rendering problem that a top of content is covered with Header.
   *
   * See also: https://material-ui.com/components/app-bar/#fixed-placement
   */
  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar} disableGutters={true}>
          <Box className={classes.logobox}>
            <Link className={classes.imageLinks} href={`/${from || ''}`}>
              <img
                className={classes.logo}
                src={`${process.env.PUBLIC_URL}/images/WAP-logo_white.png`}
                alt={'WAP-logo'}
              />
            </Link>
          </Box>
          {children}
        </Toolbar>
      </AppBar>
      <Toolbar className={classes.bgToolbar} />
    </>
  );
};

export const Header = React.memo(HeaderComponent);
