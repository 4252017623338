import React from 'react';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import { ArrowsDownIcon } from '@bizapp-frontend/customer/atoms/Icon';
import { StyledButton } from '@bizapp-frontend/customer/molecules/StyledButton';
import { Complete } from '@bizapp-frontend/customer/templates/Complete';
import { serviceThemeColor } from '@bizapp-frontend/customer/pages/util';

type TrialApplicationCompleteState = {
  valid?: boolean;
};

export interface TrialApplicationCompleteProps {
  isAllowedDirectAccess?: boolean;
}

const TrialApplicationComplete: React.FC<TrialApplicationCompleteProps> = ({
  isAllowedDirectAccess,
}) => {
  const { state } = useLocation() as { state: TrialApplicationCompleteState };
  const navigate = useNavigate();
  const { firstThemeColor } = serviceThemeColor('workflow');

  const ThanksBox = styled('section')(({ theme }) => ({
    margin: theme.spacing(3, 2),
    [theme.breakpoints.up(768)]: {
      margin: theme.spacing(3, 4),
    },
  }));

  const ThanksComponent = styled('p')(({ theme }) => ({
    color: '#333333',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
    letterSpacing: 0,
    padding: 0,
    marginBottom: theme.spacing(1),
    textAling: 'center',
    '& span': {
      display: 'block',
    },
  }));

  const AnnounceBox = styled('section')(({ theme }) => ({
    margin: theme.spacing(3, 2),
    [theme.breakpoints.up(768)]: {
      margin: theme.spacing(3, 4),
    },
  }));

  const AnnounceHeadingComponent = styled('h3')(({ theme }) => ({
    color: '#333333',
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 'bold',
    letterSpacing: 0,
    padding: 0,
    marginBottom: theme.spacing(2),
    textAling: 'center',
  }));

  const AnnounceDescriptionComponent = styled('p')(({ theme }) => ({
    color: '#333333',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    padding: 0,
    '& span': {
      display: 'block',
    },
    '& strong': {
      fontWeight: 'bold',
    },
    '&:last-of-type': {
      marginBottom: theme.spacing(10),
    },
  }));

  const ButtonBox = styled('div')(({ theme }) => ({
    margin: theme.spacing(3, 0, 3),
    '& button': {
      width: '100%',
      maxWidth: '400px',
      height: '48px',
      fontSize: '16px',
      lineHeight: '21p',
      '& svg': {
        width: '14px',
        height: '14px',
        color: '#253559',
      },
    },
  }));

  return isAllowedDirectAccess || state?.valid ? (
    <>
      <Complete
        themeColor={firstThemeColor}
        heading1="無料トライアル利用登録"
        heading2="お申し込みが完了しました"
      >
        <ThanksBox>
          <Typography component={ThanksComponent}>
            お申し込みいただき、ありがとうございます。
          </Typography>
          <Typography component={ThanksComponent}>
            <span>ただいま環境のセットアップを行っております。</span>
            <span>
              完了次第メールにてご案内いたしますので、しばらくお待ちください。
            </span>
          </Typography>
        </ThanksBox>
        <ArrowsDownIcon
          firstThemeColor={firstThemeColor}
          secondThemeColor={firstThemeColor}
        />
        <AnnounceBox>
          <Typography component={AnnounceHeadingComponent}>
            Workflow のご検討のきっかけをお聞かせください
          </Typography>
          <Typography component={AnnounceDescriptionComponent}>
            <span>約5分で回答できる簡単なアンケートです。</span>
            <span>
              お伺いした内容は、サービスの品質・利便性向上の参考材料として活用させていただきます。
            </span>
          </Typography>
          <ButtonBox>
            <StyledButton
              label={
                <>
                  アンケートに回答する
                  <OpenInNewIcon />
                </>
              }
              onClick={() => {
                window.open(
                  'https://docs.google.com/forms/d/e/1FAIpQLSeXtWVjimRi0y5qN41dj0RRXm5JHF3ZnYFHLefGc8G4SOVB0A/viewform',
                  '_blank',
                );
              }}
            />
          </ButtonBox>
          <Typography component={AnnounceDescriptionComponent}>
            <span>
              以下のユーザーサイトの
              <strong>ログイン情報はメールにてご案内</strong>
              しております。
            </span>
            <span>ご確認くださいませ。</span>
          </Typography>
          <ButtonBox>
            <StyledButton
              label="ユーザーサイトを開く"
              variant="secondary"
              onClick={() => navigate('/')}
            />
          </ButtonBox>
          <Typography component={AnnounceDescriptionComponent}>
            登録情報の確認・更新や、お客様の要件に合わせた導入や運用のご相談も承っておりますので、お気軽にご相談ください。
          </Typography>
        </AnnounceBox>
      </Complete>
    </>
  ) : (
    <Navigate to="/invalid" replace />
  );
};

export default TrialApplicationComplete;
