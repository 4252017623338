import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalSessionData } from '@bizapp-frontend/customer/pages/plancontract/ContractUpdateCardSuccess';
import { getCookie } from '@bizapp-frontend/customer/pages/util';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    headingBox: {
      padding: theme.spacing(4, 0, 3),
      textAlign: 'center',
      '& .MuiTypography-h2': {
        marginBottom: theme.spacing(3),
        color: '#4285F4',
        fontSize: '24px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-h3': {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
        fontSize: '20px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        '& span': { display: 'block' },
      },
    },
    headingTitleText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'bold',
      color: '#333333',
    },
    headingDescriptionText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
      color: '#333333',
    },
    spinnerWrapper: {
      height: '168px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinner: {
      height: '40px',
      width: '40px',

      color: '#4285F4',
    },
  }),
);

export interface ContractUpdateCardSuccessProps {
  className?: string;
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
}

export const ContractUpdateCardSuccess: React.FC<ContractUpdateCardSuccessProps> = ({
  dialogState,
  onClickDialogClose,
  className,
  children,
}) => {
  const classes = useStyle();

  const query = new URLSearchParams(useLocation().search);
  const localSessionDataKey = query.get('sessionDataKey') ?? undefined;
  const navigate = useNavigate();

  React.useEffect(() => {
    const getLocalSessionData = (localSessionDataKey: string) => {
      return JSON.parse(
        window.localStorage.getItem(localSessionDataKey) ?? '{}',
      );
    };

    if (localSessionDataKey) {
      const sessionData: LocalSessionData = getLocalSessionData(
        localSessionDataKey,
      );
      if (sessionData.tenantId && sessionData.serviceId) {
        const applicationDraftType = 'card-update';
        const draftCookieKey = `Draft-${applicationDraftType}-${sessionData.tenantId}`;
        const draftId = getCookie(draftCookieKey);
        if (draftId) {
          document.cookie = `${draftCookieKey}=${draftId}; path=/; max-age=0`;
        }
        window.localStorage.removeItem(localSessionDataKey);

        switch (sessionData.from) {
          case 'increase-license':
            navigate(
              `/application/pjb/increase-license?step=1&serviceId=${sessionData.serviceId}&tenantId=${sessionData.tenantId}`,
            );
            break;
          case 'contract':
          default:
            navigate('/plan-contract');
        }
      } else {
        navigate('/');
      }
    }
  }, [localSessionDataKey, navigate]);

  return (
    <div className={`${classes.root} ${className || ''}`}>
      <div className={classes.headingBox}>
        <Typography variant="h2">お支払い方法の変更が完了しました</Typography>
        <Typography>
          <span>メールをお送りしておりますので、ご確認ください。</span>
        </Typography>
        {children}
      </div>
      <ProcessingDialog
        state={dialogState ?? 'close'}
        onClickError={onClickDialogClose}
      />
    </div>
  );
};
