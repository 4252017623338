import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export interface StyledAccordionProps {
  id: string;
  expanded?: boolean;
  title: React.ReactNode;
  onChange?: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
  className?: string;
  children?: React.ReactNode;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: '#ffffff',
      transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      boxShadow: 'none',

      '&.MuiAccordion-root': {
        position: 'static',
      },
      '& .MuiAccordionSummary-root': {
        padding: theme.spacing(0),
        minHeight: 0,
        '& .MuiIconButton-root': {
          padding: 0,
          alignSelf: 'flex-start',
        },
        '& .MuiIconButton-edgeEnd': {
          marginRight: 0,
        },
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
        height: '100%',
        alignSelf: 'flex-start',
        '& .MuiTypography-subtitle1': {
          color: '#333333',
          fontSize: '20px',
          fontWeight: 'bold',
          lineHeight: '30px',
        },
      },
      '&.MuiAccordionSummary-expandIcon': {
        marginRight: 0,
        paddingTop: 0,
      },
      '& .MuiAccordionDetails-root': {
        padding: 0,
      },
    },
    title: { fontWeight: 'bold', fontSize: 14, color: '#333333' },
  }),
);

export const StyledAccordion: React.FC<StyledAccordionProps> = ({
  id,
  expanded,
  title,
  onChange,
  className,
  children,
}: StyledAccordionProps) => {
  const classes = useStyles();

  return (
    <Accordion
      square
      id={id}
      expanded={expanded}
      onChange={onChange}
      className={`${classes.root} ${className ?? ''}`}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        IconButtonProps={{ disableRipple: true }}
        onBlur={(e) => {
          const root = document.getElementById(id);
          root?.removeAttribute('style');
        }}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};
