import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  FormGenerator,
  FormGeneratorProps,
} from '@bizapp-frontend/customer/organisms/form/FormGenerator';

import { PageTemplate } from '@bizapp-frontend/customer/templates/PageTemplate';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';
import { Toastr } from '@bizapp-frontend/customer/molecules/Toastr';
import {
  ServiceThemeColor,
  ServiceType,
  serviceName,
} from '@bizapp-frontend/customer/pages/util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      backgroundColor: '#F2F3FB',
      padding: theme.spacing(6, 2, 6, 2),
    },
    root: {
      boxSizing: 'border-box',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 6px 20px #0000004D',
      borderRadius: '0px 0px 5px 5px',
      padding: theme.spacing(4, 4, 5, 4),
      width: '100%',
      maxWidth: theme.spacing(53),
      [theme.breakpoints.down(376)]: {
        padding: theme.spacing(3),
      },

      '& button': {
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '9px',
      },

      '& div > div': {
        marginBottom: 0,
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3),

      '& img': {
        width: '40px',
        height: '40px',
      },

      '& span': {
        marginLeft: theme.spacing(1),
        fontSize: '20px',
        color: '#333333',
      },
    },
    subHeader: {
      textAlign: 'center',
      fontSize: '16px',
      color: '#333333',
      marginBottom: theme.spacing(3),
    },
    description: {
      marginBottom: theme.spacing(6),

      '& span': {
        textAlign: 'center',
        fontSize: '14px',
        color: '#333333',
        display: 'block',
      },
    },
    resend: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginTop: theme.spacing(5),

      '& > span': {
        display: 'block',
        marginBottom: theme.spacing(2),
        textAlign: 'center',
        fontSize: '14px',
        color: '#333333',
      },

      '& a': {
        textAlign: 'center',
        fontSize: '14px',
        color: '#1565C0',
      },
    },
    topbar: (props: ServiceThemeColor) => ({
      maxWidth: theme.spacing(53),
      height: theme.spacing(0.75),
      borderRadius: '5px 5px 0px 0px',
      background: `transparent linear-gradient(to right, ${props.firstThemeColor} 0%, ${props.secondThemeColor} 100%) 0% 0% no-repeat padding-box`,
    }),
  }),
);

export interface EmailVerificationTemplateProps extends FormGeneratorProps {
  className?: string;
  userEmail?: string;
  resend?: React.ReactNode;
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
  showToastr?: boolean;
  setShowToastr?: (show: boolean) => void;
  serviceId: ServiceType;
  serviceThemeColor?: ServiceThemeColor;
}

export const EmailVerificationTemplate: React.FC<EmailVerificationTemplateProps> = ({
  className,
  formComponents,
  userEmail,
  resend,
  dialogState,
  onClickDialogClose,
  showToastr,
  setShowToastr,
  serviceId,
  serviceThemeColor,
  ...props
}) => {
  const classes = useStyles(
    serviceThemeColor ?? {
      firstThemeColor: '#ffffff',
      secondThemeColor: '#ffffff',
    },
  );

  return (
    <>
      <PageTemplate>
        <div className={classes.background}>
          <div>
            <div className={classes.topbar} />
            <div className={classes.root}>
              <div className={classes.header}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/logo/${serviceId}.png`}
                  alt={serviceId as string}
                />
                <span>{serviceName(serviceId)}</span>
              </div>
              <p className={classes.subHeader}>確認コードを入力してください</p>
              <div className={classes.description}>
                <span>
                  <strong>{userEmail}</strong> 宛に
                </span>
                <span>確認メールを送信しました。</span>
                <span>メールに記載された確認コードを入力してください。</span>
              </div>
              <FormGenerator {...props} formComponents={formComponents} />
              <div className={classes.resend}>
                <span>
                  メールが届いていない場合や、誤って破棄してしまった場合、確認コードの有効期限が切れてしまった場合は、以下より再送信してください。
                </span>
                {resend}
              </div>
            </div>
          </div>
          <ProcessingDialog
            state={dialogState ?? 'close'}
            onClickError={onClickDialogClose}
          />
          <Toastr
            open={!!showToastr}
            setOpen={(open) => setShowToastr && setShowToastr(open)}
            message={'確認コードを再発行いたしました'}
            duration={3000}
          />
        </div>
      </PageTemplate>
    </>
  );
};
