import React from 'react';
import { Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';

import {
  FormComponentProps,
  initFormData,
} from '@bizapp-frontend/customer/organisms/form/FormGenerator';

import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';

import {
  TrialApplication as TrialApplicationTemplate,
  TrialApplicationProps as TrialApplicationTemplateProps,
} from '@bizapp-frontend/customer/templates/form/TrialApplication';

import { CustomerKind, timeout } from '@bizapp-frontend/customer/pages/util';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    agreement: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(5.5),
    },
    submitButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > button': {
        width: '100%',
        height: '48px',
        maxWidth: '400px',
        fontSize: '16px',
        lineHeight: '21p',
      },
      '& span': {
        display: 'block',
      },
    },
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(2, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
  }),
);

export type TrialApplicationState = {
  emailAddress?: string;
  accessToken?: string;
};

export interface TrialApplicationProps
  extends Omit<TrialApplicationTemplateProps, 'title' | 'subTitle'> {
  applicationControlAPIBaseUrl: string;
}

const TrialApplication: React.FC<TrialApplicationProps> = ({
  applicationControlAPIBaseUrl,
  grecaptchaKey,
}) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const [submitForm, setSubmitForm] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [grecaptcha, setGrecaptcha] = React.useState('');
  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );

  const handleDialogClick = () => {
    setDialogState('close');
  };

  const handleClick = async (grecaptchaToken: string) => {
    setDisableSubmit(true);
    setGrecaptcha(grecaptchaToken);
    setSubmitForm(true);
  };

  const formComponents: FormComponentProps[][] = [
    [
      {
        kind: 'text-section-title',
        id: 'title-service',
        label: 'お申込み対象',
      },
    ],
    [
      {
        kind: 'input-normal',
        id: 'service-product',
        label: '利用サービス',
        defaultValue: 'Workflow',
        size: 'medium',
        disabled: true,
      },
      {
        kind: 'input-positive-number',
        id: 'service-license-number',
        label: 'お申込みライセンス数',
        defaultValue: '20',
        size: 'medium',
        disabled: true,
        textAlign: 'right',
      },
    ],
    [
      {
        kind: 'text-section-title',
        id: 'title-contact-person',
        label: 'ご利用者情報',
      },
    ],
    [
      {
        kind: 'input-email',
        id: 'contact-person-mail',
        label: 'メールアドレス',
        name: 'email',
        required: true,
        size: 'medium',
        description: (
          <>
            <span>
              個人アドレスではなく法人アドレスでの登録をお願いいたします。
            </span>
            <span>
              競合他社様からのお申し込みはお断りさせていただいております。
            </span>
          </>
        ),
      },
    ],
    [
      {
        kind: 'input-normal',
        id: 'contact-person-last-name',
        label: '姓',
        name: 'last-name',
        required: true,
        size: 'medium',
      },
      {
        kind: 'input-normal',
        id: 'contact-person-first-name',
        label: '名',
        name: 'first-name',
        required: true,
        size: 'medium',
      },
    ],
    [
      {
        kind: 'input-normal',
        id: 'contact-person-last-name-kana',
        label: '姓（フリガナ）',
        name: 'last-name-kana',
        size: 'medium',
      },
      {
        kind: 'input-normal',
        id: 'contact-person-first-name-kana',
        label: '名（フリガナ）',
        name: 'first-name-kana',
        size: 'medium',
      },
    ],
    [
      {
        kind: 'input-normal',
        id: 'organization-name',
        label: '会社・団体名',
        name: 'organization',
        size: 'medium',
        required: true,
      },
      {
        kind: 'input-normal',
        id: 'organization-name-kana',
        label: '会社・団体名（フリガナ）',
        name: 'organization-kana',
        size: 'medium',
      },
    ],
    [
      {
        kind: 'input-normal',
        id: 'contact-person-title',
        label: '所属・役職',
        name: 'organization-title',
        required: false,
        size: 'medium',
      },
    ],
    [
      {
        kind: 'input-tel',
        id: 'contact-person-tel',
        label: '携帯番号 (テレワーク中の方など連絡が取れる番号)',
        name: 'tel',
        required: true,
        size: 'medium',
      },
    ],
    [
      {
        kind: 'checkbox-normal',
        id: 'agreement',
        label: (
          <>
            <Link href="https://bizapp.worksap.co.jp/terms" target={'_blank'}>
              利用規約
            </Link>
            および
            <Link href="https://bizapp.worksap.co.jp/privacy" target={'_blank'}>
              個人情報の取り扱い
            </Link>
            について同意する。
          </>
        ),
        required: true,
        helperText: 'ご同意をお願いします。',
        className: classes.agreement,
      },
    ],
    [
      {
        kind: 'button-grecaptcha',
        id: 'submit-button',
        label: '送信する',
        disabled: disableSubmit,
        className: classes.submitButton,
        postClick: handleClick,
      },
    ],
  ];

  const kinds = _.flatMapDeep(formComponents)
    .filter((v) => !(v.kind.startsWith('text') || v.kind.startsWith('button')))
    .map((v) => v);

  const [formData, setFormData] = React.useState<{
    [key: string]: boolean | string | undefined;
  }>(_.fromPairs(kinds.map((v) => [v.id, initFormData(v)])));

  const [isValids, setIsValids] = React.useState(
    _.fromPairs(kinds.map((v) => [v.id, false])),
  );
  const [forceValidation, setForceValidation] = React.useState(false);

  const combineNames = (last?: string, first?: string) => {
    return (last ?? '') + (last && first ? '　' : '') + (first ?? '');
  };

  React.useEffect(() => {
    if (submitForm && grecaptcha) {
      setSubmitForm(false);
      const submitData = async (grecaptchaToken: string) => {
        const method = 'POST';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/unauthenticated-applications`;
        const headers = {
          'Content-Type': 'application/json',
          'X-reCAPTCHA-Token': grecaptchaToken,
        };
        const _body = {
          applicationType: 'contract',
          applicationSubtype: 'new',
          userKind: 'customer',
          userId: formData['contact-person-mail'],
          serviceId: 'workflow-internal',
          notificationKind: 'email',
          jsonData: JSON.stringify({
            'plan-id': 'workflow-basic',
            'usage-kind': 'trial',
            'contract-kind': 'volume',
            customerKind: 'unknown' as CustomerKind,
            ...formData,
            'contact-person-name': combineNames(
              formData['contact-person-last-name'] as string,
              formData['contact-person-first-name'] as string,
            ),
            'contact-person-name-kana': combineNames(
              formData['contact-person-last-name-kana'] as string,
              formData['contact-person-first-name-kana'] as string,
            ),
          }),
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        return resp.status === 201
          ? Promise.resolve()
          : Promise.reject(
              new Error(`Failed in submitting form. status is ${resp.status}`),
            );
      };

      const f = async () => {
        try {
          setDialogState('wait');
          await timeout(submitData(grecaptcha));
          setDialogState('close');
          navigate('/internal/application/workflow/trial-complete', {
            replace: true,
          });
        } catch (err) {
          setGrecaptcha('');
          setDisableSubmit(false);
          setDialogState('error');
        }
      };
      f();
    } else {
      setSubmitForm(false);
      setGrecaptcha('');
      setDisableSubmit(false);
    }
  }, [
    applicationControlAPIBaseUrl,
    grecaptcha,
    formData,
    navigate,
    submitForm,
  ]);

  return (
    <>
      <TrialApplicationTemplate
        title={'Test from Internal: 無料トライアルのお申し込み'}
        subTitle={
          <>
            <div>下記フォームにご記入ください。</div>
            <div>フォーム送信後、弊社よりご案内をお送りいたします。</div>
          </>
        }
        formComponents={formComponents}
        formData={formData}
        setFormData={setFormData}
        isValids={isValids}
        setIsValids={setIsValids}
        forceValidation={forceValidation}
        setForceValidation={setForceValidation}
        iconImgSrc={`${process.env.PUBLIC_URL}/images/icon-workflow@2x.png`}
        iconImgAlt="Workflow Logo"
        colors={{
          firstThemeColor: '#0EC6BD',
          secondThemeColor: '#78DB99',
        }}
        dialogState={dialogState}
        onClickDialogClose={handleDialogClick}
        grecaptchaKey={grecaptchaKey}
      />
    </>
  );
};

export default TrialApplication;
