import React from 'react';
import { Link, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink, Navigate, useLocation } from 'react-router-dom';
import {
  serviceThemeColor,
  ServiceType,
} from '@bizapp-frontend/customer/pages/util';

import { Complete } from '@bizapp-frontend/customer/templates/Complete';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(2, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
  }),
);

type ContactCompleteState = {
  valid?: boolean;
  serviceId?: string;
};

export interface ContactCompleteProps {
  isAllowedDirectAccess?: boolean;
}

const ContactComplete: React.FC<ContactCompleteProps> = ({
  isAllowedDirectAccess,
}) => {
  const classes = useStyle();
  const { search, state } = useLocation() as {
    search: string;
    state: ContactCompleteState;
  };
  const query = new URLSearchParams(search);
  const serviceId = query.get('serviceId') ?? '';

  return isAllowedDirectAccess || state?.valid ? (
    <>
      <Complete
        themeColor={
          serviceId
            ? serviceThemeColor(serviceId as ServiceType).firstThemeColor
            : '#4285F4'
        }
        heading1="お問い合わせ"
        heading2="お問い合わせの送信が完了しました"
        description={
          <>
            <span>
              確認のため、お客様へ自動返信メールをお送りしております。
            </span>
            <span>
              弊社担当よりご連絡いたしますので、今しばらくお待ちください。
            </span>
          </>
        }
      >
        <Link component={RouterLink} to="/" className={classes.backlink}>
          ユーザーページへ戻る
        </Link>
      </Complete>
    </>
  ) : (
    <Navigate to="/invalid" replace />
  );
};

export default ContactComplete;
