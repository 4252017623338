import React, { useState } from 'react';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';
import {
  Container,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { PageTemplate } from '@bizapp-frontend/customer/templates/PageTemplate';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

export interface RepurchaseApplicationProps {
  stepPages?: React.ReactNode[];
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#F2f3fb',
      padding: theme.spacing(5, 2),
      [theme.breakpoints.up(768)]: {
        padding: theme.spacing(5, 4),
      },
      display: 'flex',
      flexDirection: 'column',
    },
    body: {
      flexGrow: 1,

      margin: theme.spacing(0, -2, -5, -2),
      [theme.breakpoints.up(768)]: {
        margin: theme.spacing(0, -4, -5, -4),
      },
    },
    wrapper: {
      height: '100%',
    },
    header: {
      color: '#333333',
      '& .MuiTypography-h1': {
        fontSize: '32px',
        lineHeight: '48px',
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.down(768)]: {
          fontSize: '24px',
          lineHeight: '36px',
        },
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 'normal',
        textAlign: 'center',
        margin: '16px auto 32px',
      },
    },

    stepper: {
      margin: theme.spacing(3, 'auto'),
      width: '264px',
      backgroundColor: 'initial',
      padding: 0,
      '& .MuiStep-horizontal': { padding: 0 },
      '& .MuiStepLabel-iconContainer': { padding: 0 },
    },
    stepDescription: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      color: '#333333',
      textAlign: 'center',
      '& .MuiTypography-subtitle1': {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '21px',
      },
    },

    contentsBg: {
      padding: theme.spacing(0, 2, 10),
      [theme.breakpoints.up('sm')]: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
      },
    },

    step3bg: {
      background:
        'transparent linear-gradient(180deg, #FFFFFF 0%, #F2F3FB 100%) 0% 0% no-repeat padding-box',
    },
    step3pre: {
      padding: theme.spacing(6, 0, 3),
      color: '#333333',
      textAlign: 'center',
      '& .MuiTypography-h2': {
        marginBottom: '27px',
        color: '#0EC6BD',
        fontSize: '24px',
        lineHeight: '36px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-h3': {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '21px',
        '& span': { display: 'block' },
      },
      '& svg': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
    },
    step3paper: {},

    submitButtonArea: {
      margin: theme.spacing(5.5, 0, 0),
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
    },
    submitButton: {
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'block',
      width: '100%',
      maxWidth: '400px',
      height: '48px',
    },
    links: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(2, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
    },

    linkTop: {
      marginTop: theme.spacing(4),
    },
  }),
);

const useStepIconStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      backgroundColor: '#DADDE0',
      color: '#7F7F7F',
    },
    span: {
      textAlign: 'center',
      fontSize: '12px',
    },
    active: {
      backgroundColor: '#4285F4',
      color: '#FFFFFF',
    },
    completed: {
      backgroundColor: '#FFFFFF',
      color: '#4285F4',
      width: '22px',
      height: '22px',
      border: `1px solid #4285F4`,
    },
  }),
);

const StyledConnector = styled(StepConnector)({
  line: { borderColor: '#DADDE0' },
});

const StyledStepIcon = (props: StepIconProps) => {
  const classes = useStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={`${classes.root} ${active ? classes.active : ''} ${
        completed ? classes.completed : ''
      }`}
    >
      <span className={classes.span}>{props.icon}</span>
    </div>
  );
};

export const RepurchaseApplicationContext = React.createContext(
  {} as {
    step: number;
    setStep: (step: number, silentLoadSessionData?: boolean) => void;
    silentLoadSessionData: boolean;
  },
);

export const RepurchaseApplication: React.FC<RepurchaseApplicationProps> = ({
  stepPages,
  dialogState,
  onClickDialogClose,
}) => {
  const [activeStep, setActiveStep] = useState<number>(-1);
  const [silentLoadSessionData, setSilentLoadSessionData] = useState<boolean>(
    false,
  );
  const classes = useStyles();

  // Todo: workaround for design review
  const query = new URLSearchParams(useLocation().search);
  const stepQuery = query.get('step') ?? '0';

  React.useEffect(() => {
    switch (stepQuery) {
      case '1':
        setActiveStep(1);
        break;
      case '2':
        setActiveStep(2);
        break;
      case '0': // fall througe because of default value
      default:
        setActiveStep(0);
        break;
    }
  }, [stepQuery]);

  const setStep = (step: number, loadData: boolean = false) => {
    setSilentLoadSessionData(loadData);
    setActiveStep(step);
  };

  return (
    <RepurchaseApplicationContext.Provider
      value={{
        step: activeStep,
        setStep: setStep,
        silentLoadSessionData: silentLoadSessionData,
      }}
    >
      <PageTemplate>
        <Container
          disableGutters
          maxWidth={false}
          className={`${classes.root}`}
        >
          <Container
            component="header"
            disableGutters
            maxWidth={false}
            className={classes.header}
          >
            <Typography variant="h1">未払い料金のお支払い</Typography>
            <Stepper
              activeStep={activeStep}
              connector={<StyledConnector />}
              className={classes.stepper}
            >
              {Array(3)
                .fill(0)
                .map((_, i) => (
                  <Step completed={activeStep > i} key={i}>
                    <StepLabel StepIconComponent={StyledStepIcon} />
                  </Step>
                ))}
            </Stepper>
          </Container>
          <div className={classes.body}>
            {stepPages &&
              stepPages.map(
                (page, i) =>
                  activeStep === i && (
                    <div className={classes.wrapper} key={i}>
                      {page}
                    </div>
                  ),
              )}
          </div>
        </Container>

        <ProcessingDialog
          state={dialogState ?? 'close'}
          onClickError={onClickDialogClose}
        />
      </PageTemplate>
    </RepurchaseApplicationContext.Provider>
  );
};
