import React from 'react';
import { Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { PurchaseApplicationContext } from '@bizapp-frontend/customer/templates/form/PurchaseApplication';
import {
  PurchaseApplicationStep2,
  PurchaseApplicationStep2Props,
} from '@bizapp-frontend/customer/templates/form/PurchaseApplicationStep2';
import { PurchaseApplicationCommonProps } from '@bizapp-frontend/customer/pages/form/pjb/PurchaseApplication';
import { v4 as uuid } from 'uuid';

import { getCookie, timeout } from '@bizapp-frontend/customer/pages/util';
import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';

export interface PurchaseFormStep2Props
  extends PurchaseApplicationStep2Props,
    PurchaseApplicationCommonProps {
  applicationControlAPIBaseUrl: string;
  paymentGatewayAPIBaseUrl: string;
}

interface Step1SessionData {
  priceInfo?: any;
  'input-address'?: string;
  'contact-person-mail'?: string;
  'contact-person-name'?: string;
  'contact-person-name-kana'?: string;
  'contact-person-tel1'?: string;
  'contact-person-title'?: string;
  'input-zipcode'?: {
    zipcode: string;
  };
  'organization-name'?: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'block',
      width: '100%',
      maxWidth: '400px',
      height: '48px',
    },
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(3, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
  }),
);

export const PurchaseFormStep2: React.FC<PurchaseFormStep2Props> = ({
  applicationControlAPIBaseUrl,
  paymentGatewayAPIBaseUrl,
  tenantId,
  serviceId,
  customerId,
  userId,
  customerKind,
  ...props
}) => {
  const navigate = useNavigate();
  const classes = useStyle();
  const applicationDraftType = 'purchase-step1';
  const [submitForm, setSubmitForm] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [sessionData, setSessionData] = React.useState<Step1SessionData>({});
  const { getAccessTokenSilently } = useAuth0();
  const { step, setStep } = React.useContext(PurchaseApplicationContext);
  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );
  const handleDialogClick = () => {
    setDialogState('close');
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [hasSubmittedDraft, setHasSubmittedDraft] = React.useState(false);
  const [draftId, setDraftId] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    if (customerId) {
      const getDraft = async (draftId: string) => {
        const method = 'GET';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/customers/${customerId}/drafts/${draftId}?draft-type=${applicationDraftType}`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        };
        const resp = await fetch(url, {
          method: method,
          headers: headers,
        });

        if (resp.status === 200) {
          return Promise.resolve(resp.json());
        } else {
          return Promise.reject(
            new Error(
              `Failed in getting step 1 session data. status is ${resp.status}`,
            ),
          );
        }
      };
      const f = async () => {
        try {
          setDialogState('wait');
          const cookieDraftId = getCookie(`Draft-${tenantId}`);
          if (cookieDraftId) {
            const step1Draft = await timeout(getDraft(cookieDraftId));
            setDraftId(cookieDraftId);
            if (step1Draft.submitted) {
              setSessionData({});
              setHasSubmittedDraft(true);
              alert('有料プランご登録済です。');
            } else {
              setSessionData(JSON.parse(step1Draft.jsonData));
            }
            setDialogState('close');
          } else {
            console.error('No DraftId in cookie');
            setDialogState('error');
          }
        } catch (err) {
          setDisableSubmit(false);
          console.error('Get session data failed');
          setDialogState('error');
        }
      };
      f();
    }
  }, [
    applicationControlAPIBaseUrl,
    customerId,
    getAccessTokenSilently,
    tenantId,
  ]);

  const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(async () => {
    // disable button to prevent multiple-submit
    setDisableSubmit(true);
    setSubmitForm(true);
  }, []);

  React.useEffect(() => {
    if (submitForm) {
      setSubmitForm(false);

      const genCustomerRegisterCardUrl = async () => {
        const method = 'POST';
        const paymentGatewayId = 'stripe';
        const url = `${paymentGatewayAPIBaseUrl}/api/payment-gateway/${paymentGatewayId}/customers`;
        const accessToken = await getAccessTokenSilently();
        const localSessionDataKey =
          'BIZAPP_CUSOMER_PURCHASE_SESSION_DATA_' + uuid();
        window.localStorage.setItem(
          localSessionDataKey,
          JSON.stringify({ tenantId, serviceId, customerKind }),
        );

        const headers = {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        };
        const baseUrl = `${window.location.protocol}//${window.location.host}/application/pjb/purchase`;
        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify({
            customer: {
              address: sessionData['input-address'],
              pic_email: sessionData['contact-person-mail'],
              pic_name: sessionData['contact-person-name'],
              pic_name_kana: sessionData['contact-person-name-kana'],
              tel: sessionData['contact-person-tel1'],
              pic_title: sessionData['contact-person-title'],
              zipcode: sessionData['input-zipcode']?.zipcode,
              company_name:
                sessionData['organization-name'] ||
                sessionData['contact-person-name'],
              customer_id: customerId,
            },
            success_url: `${baseUrl}/success?sessionId={CHECKOUT_SESSION_ID}&sessionDataKey=${localSessionDataKey}`,
            cancel_url: `${baseUrl}/cancel?sessionDataKey=${localSessionDataKey}`,
            price: +sessionData.priceInfo.discountedPrice.priceIncludingTax,
            paymentGatewayId: 'stripe',
            tenantId: tenantId,
            applicationDraftId: draftId,
            description: `Project Board（ライセンス数: ${sessionData.priceInfo.licenseNumber}）`,
            metadata: {
              userId: userId,
              customerId: customerId,
              applicationDraftId: draftId,
              applicationDraftType: applicationDraftType,
            },
          }),
        });
        return resp.status === 201
          ? Promise.resolve(resp.json())
          : Promise.reject(
              new Error(
                `Failed in getting stripe register url of customer. status is ${resp.status}`,
              ),
            );
      };

      const f = async () => {
        try {
          setDialogState('wait');
          const registerCardUrlInfo = await timeout(
            genCustomerRegisterCardUrl(),
          );
          window.location.href = registerCardUrlInfo.session_url;
          setDialogState('close');
        } catch (err) {
          setDisableSubmit(false);
          console.error('Get redirect url failed');
          setDialogState('error');
        }
      };
      f();
    } else {
      setSubmitForm(false);
      setDisableSubmit(false);
    }
  }, [
    customerId,
    customerKind,
    draftId,
    getAccessTokenSilently,
    paymentGatewayAPIBaseUrl,
    serviceId,
    sessionData,
    submitForm,
    tenantId,
    userId,
  ]);

  const toCompanyContact = () => {
    const inquiryType = 'ご契約に関する問い合わせ';
    const inquiryDetail =
      '請求書払いによる有料プラン登録をご希望の方は、 以下フォーマットにご記入の上、送信してください。\n' +
      '--------\n' +
      '・お申し込みライセンス数：\n' +
      '・会社・団体名：\n' +
      '・会社・団体名（フリガナ）：';
    navigate(
      `/contact?tenantId=${tenantId}&serviceId=${serviceId}&type=${inquiryType}&detail=${encodeURIComponent(
        inquiryDetail,
      )}`,
    );
  };

  return (
    <>
      <PurchaseApplicationStep2
        {...props}
        baseUrl={applicationControlAPIBaseUrl}
        iconImgSrc={`${process.env.PUBLIC_URL}/images/icon-project-board@2x.png`}
        iconImgAlt="Project Board Logo"
        colors={{
          firstThemeColor: '#6A8AFF',
          secondThemeColor: '#6AA4FF',
        }}
        submitTitle="決済画面を開く"
        disableSubmit={disableSubmit || hasSubmittedDraft}
        onSubmit={handleSubmit}
        dialogState={dialogState}
        onClickDialogClose={handleDialogClick}
      >
        <Link onClick={toCompanyContact} className={classes.backlink}>
          {'請求書払いを申込む'}
        </Link>
        <Link
          onClick={() => {
            setStep(step - 1, true);
          }}
          className={classes.backlink}
        >
          {'Step 1 へ戻る'}
        </Link>
      </PurchaseApplicationStep2>
    </>
  );
};
