import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  RepurchaseApplication as RepurchaseApplicationTemplate,
  RepurchaseApplicationProps as RepurchaseApplicationTemplateProps,
} from '@bizapp-frontend/customer/templates/form/RepurchaseApplication';

import {
  RepurchaseFormStep1,
  RepurchaseFormStep1Props,
  RepurchaseInfo,
} from '@bizapp-frontend/customer/pages/form/pjb/RepurchaseFormStep1';
import {
  RepurchaseFormStep2,
  RepurchaseFormStep2Props,
} from '@bizapp-frontend/customer/pages/form/pjb/RepurchaseFormStep2';
import { RepurchaseFormStep3 } from '@bizapp-frontend/customer/pages/form/pjb/RepurchaseFormStep3';

import { GlobalsContext } from '@bizapp-frontend/customer/globals';
import { useAuth0 } from '@auth0/auth0-react';
import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';

interface RepurchaseApplicationProps
  extends Omit<RepurchaseApplicationTemplateProps, 'stepPages'>,
    Omit<RepurchaseFormStep1Props, 'repurchaseInfo'>,
    RepurchaseFormStep2Props {}

export interface ApplicationDraft {
  applicationDraftType: string;
  userKind: string;
  userId: string;
  customerId: string;
  tenantId: string;
  jsonData: string;
  submitted: boolean;
}

export interface DraftJsonData {
  price: number;
  description: string;
}

export interface RepurchaseApplicationCommonProps {
  // login info
  customerId?: string;
  userId?: string;
  emailAddress?: string;

  usageYearMonth?: string;
  applicationDraftId?: string;
  // fixed to pjb-basic
  planId?: string;
}
const RepurchaseApplication: React.FC<RepurchaseApplicationProps> = ({
  applicationControlAPIBaseUrl,
  paymentGatewayAPIBaseUrl,
  customerAPIBaseUrl,
}) => {
  const {
    state: { customerId, userId, emailAddress },
  } = React.useContext(GlobalsContext);
  const { usageYearMonth, applicationDraftId } = useParams();
  const planId = 'pjb-basic';

  const query = new URLSearchParams(useLocation().search);
  const step = +(query.get('step') ?? '0');

  const navigate = useNavigate();
  if (!applicationDraftId || !usageYearMonth) {
    navigate('/');
  }
  const { getAccessTokenSilently } = useAuth0();
  const [repurchaseInfo, setRepurchaseInfo] = React.useState<RepurchaseInfo>();
  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );
  const [canRender, setCanRender] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (customerId && usageYearMonth && applicationDraftId) {
      const getDraftById = async (
        usageYearMonth: string,
        draftId: string,
      ): Promise<ApplicationDraft | undefined> => {
        // console.debug('get draft by cookie id');
        const draftType = `unpaid_payment$${usageYearMonth}`;
        const accessToken = await getAccessTokenSilently();
        const method = 'GET';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/customers/${customerId}/drafts/${draftId}?draft-type=${draftType}`;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
        });

        if (resp.status === 200) {
          return Promise.resolve(resp.json());
        } else {
          return Promise.resolve(undefined);
        }
      };

      const f = async () => {
        try {
          setDialogState('wait');
          const draft = await getDraftById(usageYearMonth, applicationDraftId);
          if (draft) {
            const draftType = `unpaid_payment$${usageYearMonth}`;
            const jsonData = JSON.parse(draft.jsonData) as DraftJsonData;
            setRepurchaseInfo({
              description: jsonData.description,
              price: jsonData.price,
              tenantId: draft.tenantId,
              customerId: draft.customerId,
              draftType: draftType,
            });
            setDialogState('close');
            const baseLocation = `/application/repayments/${usageYearMonth}/${applicationDraftId}`;
            if (draft.submitted) {
              navigate(`${baseLocation}?step=2`);
            } else {
              if (step !== 1) {
                navigate(`${baseLocation}?step=0`);
              }
            }
          } else {
            setDialogState('error');
          }
        } catch (e) {
          setDialogState('error');
        }
        setCanRender(true);
      };
      f();
    }
  }, [
    applicationControlAPIBaseUrl,
    applicationDraftId,
    customerId,
    getAccessTokenSilently,
    navigate,
    step,
    usageYearMonth,
  ]);
  const handleDialogClick = () => {
    setDialogState('close');
  };
  return canRender ? (
    <>
      <RepurchaseApplicationTemplate
        stepPages={[
          <RepurchaseFormStep1
            applicationControlAPIBaseUrl={applicationControlAPIBaseUrl}
            customerAPIBaseUrl={customerAPIBaseUrl}
            paymentGatewayAPIBaseUrl={paymentGatewayAPIBaseUrl}
            customerId={customerId}
            emailAddress={emailAddress}
            applicationDraftId={applicationDraftId}
            usageYearMonth={usageYearMonth}
            userId={userId}
            planId={planId}
            repurchaseInfo={repurchaseInfo}
          />,
          <RepurchaseFormStep2
            paymentGatewayAPIBaseUrl={paymentGatewayAPIBaseUrl}
            customerId={customerId}
            userId={userId}
          />,
          <RepurchaseFormStep3
            applicationControlAPIBaseUrl={applicationControlAPIBaseUrl}
            userId={userId}
            customerId={customerId}
            planId={planId}
          />,
        ]}
        dialogState={dialogState}
        onClickDialogClose={handleDialogClick}
      />
    </>
  ) : (
    <RepurchaseApplicationTemplate
      stepPages={[]}
      dialogState={dialogState}
      onClickDialogClose={handleDialogClick}
    />
  );
};

export default RepurchaseApplication;
