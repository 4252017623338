import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Navigate, useLocation } from 'react-router-dom';
import { Complete } from '@bizapp-frontend/customer/templates/Complete';

type TrialApplicationCompleteState = {
  valid?: boolean;
};

export interface TrialApplicationCompleteProps {
  isAllowedDirectAccess?: boolean;
}

const TrialApplicationComplete: React.FC<TrialApplicationCompleteProps> = ({
  isAllowedDirectAccess,
}) => {
  const { state } = useLocation() as { state: TrialApplicationCompleteState };

  const ThanksBox = styled('section')(({ theme }) => ({
    margin: theme.spacing(3, 2),
    [theme.breakpoints.up(768)]: {
      margin: theme.spacing(3, 4),
    },
  }));

  const BackLink = styled(Link)(({ theme }) => ({
    display: 'block',
    textAlign: 'center',
    margin: theme.spacing(2, 'auto', 0),
    color: '#1565C0',
    fontSize: '14px',
    lineHeight: '21px',
    cursor: 'pointer',
  }));

  const ThanksComponent = styled('p')(({ theme }) => ({
    color: '#333333',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
    letterSpacing: 0,
    padding: 0,
    marginBottom: theme.spacing(1),
    textAling: 'center',
    '& span': {
      display: 'block',
    },
  }));

  return isAllowedDirectAccess || state?.valid ? (
    <>
      <Complete
        themeColor="#0EC6BD"
        heading1="Test from Internal: 無料トライアルのお申し込み"
        heading2="お申し込みが完了しました"
      >
        <ThanksBox>
          <Typography component={ThanksComponent}>
            <span>お問い合わせいただき、ありがとうございました。</span>
            <span>確認のためお客様へ自動返信メールをお送りしております。</span>
            <span>
              弊社担当よりご連絡いたしますので、今しばらくお待ちください。
            </span>
          </Typography>
          <BackLink href={'https://www.worksap.co.jp/saas/workflow'}>
            {'TOPページへ戻る'}
          </BackLink>
        </ThanksBox>
      </Complete>
    </>
  ) : (
    <Navigate to="/invalid" replace />
  );
};

export default TrialApplicationComplete;
