import { FC } from 'react';
import { Link, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grecaptcha: {
      marginTop: '12px',
      color: '#7f7f7f',
      fontSize: '12px',
      lineHeight: '18px',
      textAlign: 'center',
      '& span': {
        display: 'block',
      },
      '& .MuiLink-root': {
        color: '#1565c0',
      },
      // [theme.breakpoints.up(768)]: {
      //   '& span': {
      //     display: 'inline',
      //   },
      // },
    },
  }),
);

export const GrecaptchaTypography: FC = () => {
  const classes = useStyles();

  return (
    <Typography component={'p'} className={classes.grecaptcha}>
      <span>このサイトはreCAPTCHAによって保護されており、</span>
      <span>
        Googleの
        <Link
          href="https://policies.google.com/privacy"
          target={'_blank'}
          rel={'noreferrer'}
        >
          プライバシーポリシー
        </Link>
        と
        <Link
          href="https://policies.google.com/terms"
          target={'_blank'}
          rel={'noreferrer'}
        >
          利用規約
        </Link>
        が適用されます。
      </span>
    </Typography>
  );
};
