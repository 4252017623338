import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  FormGenerator,
  FormGeneratorProps,
} from '@bizapp-frontend/customer/organisms/form/FormGenerator';

import { PageTemplate } from '@bizapp-frontend/customer/templates/PageTemplate';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

import { ServiceThemeColor } from '@bizapp-frontend/customer/pages/util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      width: '100%',
      backgroundColor: '#F2F3FB',
      padding: theme.spacing(6, 4, 6, 4),

      [theme.breakpoints.down(1025)]: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(6, 2, 6, 2),
      },
    },
    description: {
      width: '100%',
      maxWidth: '696px',
      marginRight: theme.spacing(6),
      [theme.breakpoints.down(1025)]: {
        marginBottom: theme.spacing(4),
        marginRight: '0px',
      },

      '&> img': {
        marginBottom: theme.spacing(3),
        width: '100%',
        maxWidth: '640px',
      },
    },
    descriptionHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(3),

      '& img': {
        width: '48px',
        height: '48px',
      },

      '& span': {
        marginLeft: theme.spacing(1),
        fontSize: '28px',
        color: '#4C4C4C',
        display: 'block',
        fontWeight: 'bold',
      },
    },
    descriptionSubHeader: {
      fontSize: '16px',
      color: '#4C4C4C',
      display: 'block',
      fontWeight: 'bold',
      marginBottom: theme.spacing(5),
    },
    descriptionText: {
      fontSize: '16px',
      color: '#333333',
      display: 'flex',
      alignItems: 'center',
      marginBottom: theme.spacing(2),

      '& img': {
        width: '48px',
        height: '48px',
        marginRight: theme.spacing(2),
      },
    },
    root: (props: ServiceThemeColor) => ({
      boxSizing: 'border-box',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 6px 20px #0000004D',
      borderRadius: '0px 0px 5px 5px',
      width: '100%',
      maxWidth: '480px',

      '& button': {
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '9px',
      },

      '&::before': {
        content: '""',
        display: 'block',
        height: theme.spacing(0.75),
        borderRadius: '5px 5px 0px 0px',
        background: `transparent linear-gradient(to right, ${props.firstThemeColor} 0%, ${props.secondThemeColor} 100%) 0% 0% no-repeat padding-box`,
      },
    }),
    content: {
      margin: theme.spacing(4, 4, 5, 4),

      [theme.breakpoints.down(376)]: {
        margin: theme.spacing(3),
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      marginBottom: theme.spacing(3),

      '& img': {
        width: '40px',
        height: '40px',
      },

      '& div': {
        fontSize: '20px',
        color: '#333333',
        display: 'block',
      },
    },
    subheader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3),
      color: '#333333',

      '& > p': {
        margin: 0,
        padding: theme.spacing(1.5),
        textAlign: 'center',

        '&:last-of-type': {
          paddingBottom: 0,
        },
      },
    },
    login: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(6),

      '& a': {
        textAlign: 'center',
        fontSize: '14px',
        color: '#1565C0',
      },
    },
    lineBreak: {
      '& > span': {
        display: 'block',
      },
      margin: 0,
    },
  }),
);

export interface SignUpProps extends FormGeneratorProps {
  children?: React.ReactNode;
  dialogState?: ProcessingDialogState;
  login?: React.ReactNode;
  onClickDialogClose?: () => void;
  serviceThemeColor?: ServiceThemeColor;
}

/**
 * This template is specifically for Workflow signup page.
 * Not designed for general use because whether this UI is used generally in future is undetermined at this point.
 */
export const SignUp: React.FC<SignUpProps> = ({
  children,
  dialogState,
  login,
  onClickDialogClose,
  serviceThemeColor,
  formComponents,
  ...props
}) => {
  const classes = useStyles(
    serviceThemeColor ?? {
      firstThemeColor: '#ffffff',
      secondThemeColor: '#ffffff',
    },
  );

  const descriptionTexts = [
    <>
      <span>
        入力いただいたメールアドレスへ登録フォームをお送りいたします。
      </span>
      <span>
        ご登録後すぐにトライアル環境へのログインが可能です。ご利用にあたって
        <strong>インストールは不要</strong>です。
      </span>
    </>,
    <>
      <span>
        お申込みをいただいた方全員に、
        <strong>無料トライアルお試しガイドや各種マニュアル、動画を提供</strong>
        しています。また、組織情報やユーザー情報などの
        <strong>データサンプルも送付</strong>
        いたしますので、すぐにお試しいただけます。
      </span>
    </>,
    <>
      <span>
        トライアルのご利用にあたり、<strong>無料のハンズオンも実施中</strong>
        です。
      </span>
      <span>
        また、ご契約時と同様に<strong>メールによるお問い合わせ</strong>や
        <strong>専用のヘルプセンター</strong>
        においてFAQやチャットボットで24時間いつでもご不明点を解消いただけます。
      </span>
    </>,
  ];

  return (
    <>
      <PageTemplate>
        <div className={classes.background}>
          <div className={classes.description}>
            <div className={classes.descriptionHeader}>
              <img
                src={`${process.env.PUBLIC_URL}/images/logo/workflow.png`}
                alt="workflow"
              />
              <span>HUEワークフロー 30日間無料トライアル</span>
            </div>
            <img
              src={`${process.env.PUBLIC_URL}/images/signup-workflow@2x.png`}
              alt="signup-workflow"
            />
            <div className={classes.descriptionSubHeader}>
              HUEワークフローはユーザー目線で「使いやすい」を追求した今までにないワークフローシステムです。申請・承認にとどまらない業務全体の効率化、DX推進の第一歩を支援します。
            </div>
            {descriptionTexts.map((text, i) => (
              <div
                className={classes.descriptionText}
                key={`description-text-${i}`}
              >
                <img
                  src={`${process.env.PUBLIC_URL}/images/checkmark-circle-workflow.png`}
                  alt="checkmark-circle-workflow"
                />
                <p className={classes.lineBreak}>{text}</p>
              </div>
            ))}
          </div>
          <div className={classes.root}>
            <div className={classes.content}>
              <div className={classes.header}>
                <div>無料で試してみる</div>
                <div>アカウント作成はこちら</div>
              </div>
              <div className={classes.subheader}>
                <p className={classes.lineBreak}>
                  <span>
                    <strong>30日間無料</strong>で20名様まで
                    <strong>すべての機能</strong>を
                  </span>
                  <span>お試しいただけます。</span>
                  <span>終了後に自動で課金されることはありません。</span>
                </p>
                <p>
                  トライアル期間中のデータは、有料プランのご契約後もそのままご利用いただけます。
                </p>
              </div>
              <div className={classes.login}>{login}</div>
              <FormGenerator {...props} formComponents={formComponents} />
            </div>
          </div>
          <ProcessingDialog
            state={dialogState ?? 'close'}
            onClickError={onClickDialogClose}
          />
        </div>
      </PageTemplate>
    </>
  );
};
