import React from 'react';
import { Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import {
  RepurchaseApplicationStep3,
  RepurchaseApplicationStep3Props,
} from '@bizapp-frontend/customer/templates/form/RepurchaseApplicationStep3';
import { PurchaseApplicationCommonProps } from '@bizapp-frontend/customer/pages/form/pjb/PurchaseApplication';

export interface RepurchaseFormStep3Props
  extends RepurchaseApplicationStep3Props,
    PurchaseApplicationCommonProps {
  applicationControlAPIBaseUrl: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(5, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
    body: {
      height: '100%',
      backgroundColor: '#FFFFFF',
      padding: theme.spacing(0, 2),
    },
  }),
);

export const RepurchaseFormStep3: React.FC<RepurchaseFormStep3Props> = () => {
  const navigate = useNavigate();
  const classes = useStyle();

  return (
    <div className={classes.body}>
      <RepurchaseApplicationStep3>
        <Link
          onClick={() => {
            navigate('/');
          }}
          className={classes.backlink}
        >
          {'ユーザーページへ戻る'}
        </Link>
      </RepurchaseApplicationStep3>
    </div>
  );
};
