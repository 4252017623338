import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  StyledPaper,
  StyledPaperProps,
} from '@bizapp-frontend/customer/molecules/StyledPaper';
import { FormGeneratorProps } from '@bizapp-frontend/customer/organisms/form/FormGenerator';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    headingBox: {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
    },
    headingTitleText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'bold',
      color: '#333333',
    },
    headingDescriptionText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
      color: '#333333',
    },
    payment: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(5),
    },
    paymentTitle: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'bold',
      color: '#333333',
    },
    paymentTable: {
      maxWidth: '400px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#DADDE0',
      borderRadius: '3px',
      borderCollapse: 'separate',
      padding: theme.spacing(3, 2),
      '& .MuiTableCell-root': {
        border: 'none',
        padding: 0,
      },
      margin: theme.spacing(2, 2),
    },
    paymentTableTitle: {
      fontSize: '14px',
      lineHeight: '32px',
      fontWeight: 'bold',
      color: '#777777',
    },
    paymentTableValue: {
      textAlign: 'right',
      fontSize: '14px',
      lineHeight: '32px',
      fontWeight: 'bold',
      color: '#333333',
    },
    content: {
      marginBottom: theme.spacing(10),
    },
  }),
);

export interface PaymentInfo {
  paymentGatewayId?: string;
  paymentMethodDescription: string;
  price: string;
  issueDate?: string;
}

export interface IncreaseLicenseApplicationStep2Props
  extends StyledPaperProps,
    Omit<FormGeneratorProps, 'formComponents'> {
  baseUrl?: string;
  className?: string;
  paymentInfo?: PaymentInfo;
  cardTitle?: string;
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
  showConfirmationDialog?: boolean;
  setShowConfirmationDialog?: () => void;
  onLoadSessionData?: (load: boolean) => void;
}

export const IncreaseLicenseApplicationStep2: React.FC<IncreaseLicenseApplicationStep2Props> = ({
  baseUrl,
  className,
  iconImgSrc,
  iconImgAlt,
  colors,
  paymentInfo,
  cardTitle,
  children,
  dialogState,
  onClickDialogClose,
}) => {
  const classes = useStyle();
  return (
    <div className={`${classes.root} ${className || ''}`}>
      <div className={classes.headingBox}>
        <Typography component="h2" className={classes.headingTitleText}>
          Step 2
        </Typography>
        <Typography component="h3" className={classes.headingDescriptionText}>
          お支払い情報を確認してください。
        </Typography>
      </div>
      <StyledPaper
        className={classes.content}
        iconImgSrc={iconImgSrc}
        iconImgAlt={iconImgAlt}
        colors={colors}
      >
        <div className={classes.payment}>
          <Typography component="h3" className={classes.paymentTitle}>
            {cardTitle}
          </Typography>
          <Table className={classes.paymentTable}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.paymentTableTitle}>
                  お支払い方法
                </TableCell>
                <TableCell className={classes.paymentTableValue}>
                  {paymentInfo?.paymentMethodDescription}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.paymentTableTitle}>
                  初回ご請求金額（合計）
                </TableCell>
                <TableCell className={classes.paymentTableValue}>
                  {paymentInfo?.price}
                </TableCell>
              </TableRow>
              {paymentInfo?.paymentGatewayId === 'mfkessai' && (
                <TableRow>
                  <TableCell className={classes.paymentTableTitle}>
                    請求日
                  </TableCell>
                  <TableCell className={classes.paymentTableValue}>
                    {paymentInfo?.issueDate}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {children}
        <ProcessingDialog
          state={dialogState ?? 'close'}
          onClickError={onClickDialogClose}
        />
      </StyledPaper>
    </div>
  );
};
