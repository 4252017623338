import React from 'react';
import { Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { addDays, endOfMonth } from 'date-fns';
import { GlobalsContext } from '@bizapp-frontend/customer/globals';

import {
  FormComponentProps,
  initFormDataPairs,
} from '@bizapp-frontend/customer/organisms/form/FormGenerator';
import { PriceInfo } from '@bizapp-frontend/customer/organisms/form/PriceTable';
import { ProcessingDialogConfig } from '@bizapp-frontend/customer/organisms/ProcessingDialog';

import {
  IncreaseLicenseApplicationContext,
  PaymentKind,
} from '@bizapp-frontend/customer/templates/form/IncreaseLicenseApplication';
import {
  IncreaseLicenseApplicationStep1,
  IncreaseLicenseApplicationStep1Props,
} from '@bizapp-frontend/customer/templates/form/IncreaseLicenseApplicationStep1';
import { IncreaseLicenseApplicationCommonProps } from '@bizapp-frontend/customer/pages/form/pjb/IncreaseLicenseApplication';

import { getCookie, timeout } from '@bizapp-frontend/customer/pages/util';

interface ContractInfo {
  applicationId: string;
  availableDate: number;
  calculationStartDate: number;
  contractId: string;
  contractKind: 'volume' | 'sepcified';
  customerId: string;
  endDate: number;
  note: string;
  planId: string;
  serviceId: string;
  startDate: number;
  usageKind: 'trial' | 'purchased';
  tenantId: string;
  tenantName: string;
  volume?: number | null;
  timestamp: number;
  discountInfo?: DiscountInfo;
}

interface DiscountInfo {
  kind: 'none' | 'fixed' | 'rate' | 'specified';
  unitPrice?: number | null;
  amount: number;
  rate: number;
  price: number;
}

export interface IncreaseLicenseFormStep1Props
  extends Omit<IncreaseLicenseApplicationStep1Props, 'priceInfo'>,
    IncreaseLicenseApplicationCommonProps {
  applicationControlAPIBaseUrl: string;
  zipcodeAPIBaseUrl: string;
  feeCalculatorAPIBaseUrl: string;
  contractAPIBaseUrl: string;
  customerAPIBaseUrl: string;
  tenantManagementAPIBaseUrl: string;
  pjbDomainUrlSuffix: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    submitButtonArea: {
      width: '100%',
      margin: theme.spacing(4, 0, 0),
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
    },
    agreement: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(5.5),
    },
    submitButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(5),
      '& > button': {
        width: '100%',
        height: '48px',
        maxWidth: '400px',
      },
      '& span': {
        display: 'block',
      },
      '& .MuiButton-label': {
        fontSize: '16px',
      },
    },
    pjbLink: {
      display: 'inline',
      margin: theme.spacing(0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(2, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
    description: {
      marginTop: theme.spacing(1),
      color: '#7F7F7F',
      fontSize: '12px',
      lineHeight: '18px',
      '& strong': {
        fontWeight: 'bold',
      },
    },
  }),
);

type FormData = {
  [key: string]: boolean | string | undefined;
};

interface ApplicationDraft {
  applicationDraftType: string;
  userKind: string;
  userId: string;
  customerId: string;
  tenantId: string;
  jsonData: string;
  submitted: boolean;
}

export const IncreaseLicenseFormStep1: React.FC<IncreaseLicenseFormStep1Props> = ({
  applicationControlAPIBaseUrl,
  zipcodeAPIBaseUrl,
  feeCalculatorAPIBaseUrl,
  contractAPIBaseUrl,
  customerAPIBaseUrl,
  tenantManagementAPIBaseUrl,
  pjbDomainUrlSuffix,
  tenantId,
  serviceId,
  planId,
  customerId,
  emailAddress,
  userId,
  ...props
}) => {
  const applicationDraftType = 'increase-license-step1';
  const classes = useStyle();
  const [licenseNumber, setLicenseNumber] = React.useState('20');
  const [discountedPrice, setDiscountedPrice] = React.useState(-1);
  const [baseContractId, setBaseContractId] = React.useState('');
  const [price, setPrice] = React.useState<PriceInfo>();
  const [submitForm, setSubmitForm] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);

  const [
    dialogConfig,
    setDialogConfig,
  ] = React.useState<ProcessingDialogConfig>({
    state: 'close',
  });

  const [initDataLoaded, setInitDataLoaded] = React.useState(false);

  const navigate = useNavigate();

  const { step, setStep, silentLoadSessionData } = React.useContext(
    IncreaseLicenseApplicationContext,
  );
  const { getAccessTokenSilently } = useAuth0();

  const { state: globalState } = React.useContext(GlobalsContext);

  const handleDialogClick = () => {
    setWaitingStates({});
    setDialogConfig({ state: 'close' });
  };

  const [loadSessionData, setLoadSessionData] = React.useState<
    boolean | undefined
  >(undefined);
  const [showConfirmationDialog, setShowConfirmationDialog] = React.useState(
    false,
  );
  const [sessionData, setSessionData] = React.useState<FormData | null>(null);

  React.useEffect(() => {
    if (sessionData) {
      if (_.isEmpty(sessionData)) {
        setLoadSessionData(false);
      } else if (silentLoadSessionData) {
        setLoadSessionData(true);
      } else {
        setShowConfirmationDialog(true);
      }
    }
  }, [sessionData, silentLoadSessionData]);

  const [draftId, setDraftId] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    if (customerId && tenantId) {
      const getDraftById = async (draftId: string) => {
        // console.debug('get draft by cookie id');
        const accessToken = await getAccessTokenSilently();
        const method = 'GET';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/customers/${customerId}/drafts/${draftId}?draft-type=${applicationDraftType}`;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const resp = await fetch(url, {
          method: method,
          headers: headers,
        });

        if (resp.status === 200) {
          return Promise.resolve(resp.json());
        } else {
          return Promise.resolve(undefined);
        }
      };
      const getDraftByTenant = async (tenantId: string) => {
        // console.debug('get draft by current tenant id');
        const accessToken = await getAccessTokenSilently();
        const method = 'GET';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/tenants/${tenantId}?draft-type=${applicationDraftType}`;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const resp = await fetch(url, {
          method: method,
          headers: headers,
        });

        if (resp.status === 200) {
          const drafts: ApplicationDraft[] = await resp.json();
          // const submittedDraft = drafts.find((draft) => draft.submitted);
          // if (submittedDraft) {
          //   return Promise.resolve(submittedDraft);
          // } else {
          return Promise.resolve(_.maxBy(drafts, 'timestamp'));
          // }
        } else {
          return Promise.reject(
            new Error(
              `Failed in getting step 1 session data. status is ${resp.status}`,
            ),
          );
        }
      };

      const f = async () => {
        const cookieDraftId = getCookie(
          `Draft-${applicationDraftType}-${tenantId}`,
        );
        setDraftId(cookieDraftId);
        const getDraftFn = cookieDraftId
          ? () => getDraftById(cookieDraftId)
          : () => getDraftByTenant(tenantId);

        setWaitingStates((prevState) => ({
          ...prevState,
          getInitData: true,
        }));
        try {
          const draft = await getDraftFn();
          if (draft) {
            // console.debug('get draft data, submitted:', draft.submitted);
            if (draft.submitted) {
              setDraftId(undefined);
              setSessionData({});
              // setHasSubmittedDraft(true);
              // alert('有料プランご登録済です。');
              document.cookie = `Draft-${applicationDraftType}-${tenantId}=; path=/; max-age=0`;
            } else {
              setDraftId(draft.applicationDraftId);
              setSessionData(JSON.parse((draft.jsonData as string) ?? '{}'));
            }
          } else {
            // console.debug('no draft');
            setDraftId(undefined);
            setSessionData({});
          }
        } catch {
          // todo: error handling
          setSessionData({});
        }
      };
      f();
    }
  }, [
    applicationControlAPIBaseUrl,
    customerId,
    getAccessTokenSilently,
    tenantId,
  ]);

  React.useEffect(() => {
    if (loadSessionData != null) {
      const f = async () => {
        try {
          if (loadSessionData && sessionData) {
            setFormData((prev) => ({ ...prev, ...sessionData }));
            setLicenseNumber(
              (sessionData['service-license-number-increased'] ??
                '20') as string,
            );
          }
          // start getting price info
          setInitDataLoaded(true);

          setWaitingStates((prevState) => ({
            ...prevState,
            getInitData: false,
          }));
        } catch {
          setWaitingStates((prevState) => ({
            ...prevState,
            getInitData: false,
          }));
          setInitDataLoaded(true);
        }
      };
      f();
    }
  }, [loadSessionData, sessionData]);
  const [tenantName, setTenantName] = React.useState('');

  React.useEffect(() => {
    if (tenantId) {
      const f = async () => {
        const accessToken = await getAccessTokenSilently();
        const userResp = await fetch(
          `${tenantManagementAPIBaseUrl}/api/tenant-management/tenants/${tenantId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        const tenantJson = await userResp.json();
        setTenantName(tenantJson?.[0]?.tenantName);
      };
      f();
    }
  }, [
    getAccessTokenSilently,
    pjbDomainUrlSuffix,
    tenantId,
    tenantManagementAPIBaseUrl,
    tenantName,
  ]);

  const [nextTimeoutId, setNextTimeoutId] = React.useState<
    number | undefined
  >();

  const getContract = React.useCallback(
    async (currentContractTenantId): Promise<ContractInfo> => {
      const accessToken = await getAccessTokenSilently();
      const method = 'GET';
      const url = `${contractAPIBaseUrl}/api/contract/customers/${customerId}/tenants/${currentContractTenantId}/current`;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      };
      const resp = await fetch(url, {
        method: method,
        headers: headers,
      });

      if (resp.status === 200) {
        return Promise.resolve(resp.json());
      } else {
        return Promise.reject(
          new Error(`Failed in getting usage data. status is ${resp.status}`),
        );
      }
    },
    [contractAPIBaseUrl, customerId, getAccessTokenSilently],
  );

  const [currentLicenseNumber, setCurrentLicenseNumber] = React.useState(0);
  const setLicenseInfo = React.useCallback((contract: ContractInfo) => {
    if (contract) {
      const volume = contract.volume ?? 0;
      setCurrentLicenseNumber(volume);
      setBaseContractId(contract.contractId);
      if (
        contract.discountInfo &&
        (contract.discountInfo.kind === 'fixed' ||
          contract.discountInfo.kind === 'rate')
      ) {
        setDiscountedPrice(contract.discountInfo.unitPrice ?? 0);
      } else {
        setDiscountedPrice(-1);
      }
      setFormData((prev) => {
        const crr = _.cloneDeep(prev);
        crr['service-license-number-current'] = volume.toString();
        return crr;
      });
    }
  }, []);

  const updateLicenseInfo = React.useCallback(async () => {
    if (tenantId) {
      const res = await getContract(tenantId);
      setLicenseInfo(res);
    }
  }, [getContract, setLicenseInfo, tenantId]);
  const [updateUsageRequested, setUpdateUsageRequested] = React.useState(false);
  React.useEffect(() => {
    if (tenantId && tenantName && !updateUsageRequested) {
      setUpdateUsageRequested(true);
      const requestUpdateUsage = async (
        tenantId: string,
        tenantName: string,
      ) => {
        const accessToken = await getAccessTokenSilently();
        const method = 'POST';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/messages`;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const _body = {
          targetService: 'pjb',
          tenantId: tenantId,
          tenantName: tenantName,
          eventType: 'read',
          resource: 'usage',
        };
        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        if (resp.status === 201) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            new Error(`Failed in getting usage data. status is ${resp.status}`),
          );
        }
      };

      setWaitingStates((prevState) => ({
        ...prevState,
        updateLicenseInfo: true,
      }));
      updateLicenseInfo().finally(() =>
        setWaitingStates((prevState) => ({
          ...prevState,
          updateLicenseInfo: false,
        })),
      );

      requestUpdateUsage(tenantId, tenantName);
      window.setTimeout(updateLicenseInfo, 5000);

      const requestUpdateUsageWithTimeout = (timeout: number) => {
        const timeoutId = window.setTimeout(() => {
          requestUpdateUsage(tenantId, tenantName);
          window.setTimeout(updateLicenseInfo, 5000);

          requestUpdateUsageWithTimeout(timeout * 2);
        }, timeout);
        setNextTimeoutId(timeoutId);
      };
      requestUpdateUsageWithTimeout(3 * 60000);
    }
    return () => {
      if (nextTimeoutId != null) {
        window.clearTimeout(nextTimeoutId);
      }
    };
  }, [
    applicationControlAPIBaseUrl,
    getAccessTokenSilently,
    nextTimeoutId,
    setLicenseInfo,
    tenantId,
    tenantName,
    updateLicenseInfo,
    updateUsageRequested,
  ]);

  const handleClick = React.useCallback(async () => {
    // disable button to prevent multiple-submit
    setDisableSubmit(true);
    setSubmitForm(true);
  }, []);
  const formComponentsPart1Init: FormComponentProps[][] = React.useMemo(
    () => [
      [
        {
          kind: 'text-section-title',
          id: 'title-service',
          label: 'お申込み対象',
        },
      ],
      [
        {
          kind: 'input-normal',
          id: 'service-product',
          label: '利用サービス',
          defaultValue: 'Project Board',
          size: 'medium',
          disabled: true,
        },
      ],
      [
        {
          kind: 'input-normal',
          id: 'service-license-number-current',
          label: '現在契約中のライセンス数',
          defaultValue: '0',
          size: 'medium',
          disabled: true,
        },
      ],
      [
        {
          kind: 'input-positive-number',
          id: 'service-license-number-increased',
          label: '追加ライセンス数',
          required: true,
          defaultValue: '20',
          size: 'medium',
          postChange: (value) => setLicenseNumber(value),
        },
      ],
    ],
    [],
  );

  props.formComponentsPart2 = [
    [
      {
        kind: 'button-normal',
        id: 'submit-button',
        label: '次へ',
        className: classes.submitButton,
        disabled: disableSubmit,
        preClick: updateLicenseInfo,
        postClick: handleClick,
      },
    ],
  ];

  const kinds = _.flatMapDeep(
    _.concat(formComponentsPart1Init, props.formComponentsPart2),
  )
    .filter((v) => !(v.kind.startsWith('text') || v.kind.startsWith('button')))
    .map((v) => v);

  const [formData, setFormData] = React.useState<FormData>(
    initFormDataPairs(kinds),
  );

  const [isValids, setIsValids] = React.useState(
    _.fromPairs(kinds.map((v) => [v.id, false])),
  );
  const [forceValidation, setForceValidation] = React.useState(false);

  const [waitingStates, setWaitingStates] = React.useState<{
    [key: string]: boolean;
  }>({});

  // check multi async webapi call to set loading spinner
  React.useEffect(() => {
    if (dialogConfig.state !== 'error') {
      if (_.some(_.values(waitingStates))) {
        setDialogConfig({ state: 'wait' });
      } else {
        setDialogConfig({ state: 'close' });
      }
    }
  }, [dialogConfig.state, waitingStates]);

  React.useEffect(() => {
    if (initDataLoaded) {
      let _licenseNumber = parseInt(licenseNumber, 10);
      if (_.isNaN(_licenseNumber)) {
        _licenseNumber = 0;
      }
      const getPrice = async () => {
        const method = 'POST';
        const url = `${feeCalculatorAPIBaseUrl}/fee-calculator`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };

        const currentDate = new Date();
        const usageStartDate = addDays(currentDate, 1);
        const usageEndDate = endOfMonth(usageStartDate);
        //formats to 'yyyy-mm-dd'
        const formatter = Intl.DateTimeFormat('fr-CA', {
          timeZone: 'Asia/Tokyo',
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        });

        const _body = {
          unitPrice:
            discountedPrice !== -1 ? discountedPrice.toString() : '960',
          quantity: _licenseNumber.toString(),
          usageStartDate: formatter.format(usageStartDate),
          usageEndDate: formatter.format(usageEndDate),
          closingDate: formatter.format(usageEndDate),
          billType: 'monthly',
          productType: ['by-the-day'],
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        if (resp.status !== 200) {
          return Promise.reject(
            new Error(`Failed in getting price data. status is ${resp.status}`),
          );
        }

        const jsonData = await resp.json();
        //When user opens form on the last day of month
        if (usageStartDate.getMonth() !== endOfMonth(currentDate).getMonth()) {
          return Promise.resolve({
            discountedPrice: {
              ...jsonData.discountedPrice,
              unitPriceIncludingTax: '0',
              priceIncludingTax: '0',
              discountAmountForUnitPriceIncludingTax:
                jsonData.discountedPrice.unitPriceIncludingTax,
            },
            normalPrice: {
              ...jsonData.normalPrice,
            },
          });
        } else {
          return Promise.resolve(jsonData);
        }
      };
      const f = async () => {
        try {
          setWaitingStates((prevState) => ({ ...prevState, getPrice: true }));
          const priceInfo = await getPrice();
          setPrice({ ...priceInfo, licenseNumber: _licenseNumber.toString() });
          setWaitingStates((prevState) => ({ ...prevState, getPrice: false }));
        } catch {
          setDialogConfig({ state: 'error' });
        }
      };
      f();
    }
  }, [
    discountedPrice,
    feeCalculatorAPIBaseUrl,
    getAccessTokenSilently,
    initDataLoaded,
    licenseNumber,
    planId,
    serviceId,
  ]);

  React.useEffect(() => {
    if (submitForm) {
      setSubmitForm(false);
      const createDraft = async () => {
        const method = 'POST';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/tenants/${tenantId}`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const _body = {
          applicationDraftType: applicationDraftType,
          userKind: 'customer',
          userId: userId,
          customerId: customerId,
          saasBillingAddressId: globalState.saasBillingAddressId,
          tenantId: tenantId,
          serviceId: serviceId,
          notificationKind: 'email',
          jsonData: JSON.stringify({
            ...formData,
            priceInfo: price,
            'plan-id': planId,
            'usage-kind': 'purchased',
            'contract-kind': 'volume',
            'contact-person-mail': emailAddress,
            baseContractId: baseContractId,
          }),
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        return resp.status === 201
          ? Promise.resolve(resp.text())
          : Promise.reject(
              new Error(`Failed in submitting form. status is ${resp.status}`),
            );
      };
      const updateDraft = async (draftId: string) => {
        const method = 'PUT';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/customers/${customerId}/drafts/${draftId}`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const _body = {
          applicationDraftType: applicationDraftType,
          saasBillingAddressId: globalState.saasBillingAddressId,
          jsonData: JSON.stringify({
            ...formData,
            priceInfo: price,
            'plan-id': planId,
            'usage-kind': 'purchased',
            'contract-kind': 'volume',
          }),
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        return resp.status === 200
          ? Promise.resolve(resp.text())
          : Promise.reject(
              new Error(`Failed in submitting form. status is ${resp.status}`),
            );
      };

      const getPaymentKind = async (): Promise<PaymentKind> => {
        const accessToken = await getAccessTokenSilently();
        const paymentKindResp = await fetch(
          `${contractAPIBaseUrl}/api/contract/payment-kind/customers/${customerId}/latest?thresholdDayUnixMS=${Date.now()}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (paymentKindResp.ok) {
          return await paymentKindResp.json();
        } else {
          return Promise.reject(
            new Error(
              `Failed in get last payment kind. status is ${paymentKindResp.status}`,
            ),
          );
        }
      };

      const f = async () => {
        try {
          if (discountedPrice === 0 || currentLicenseNumber === 0) {
            throw new Error(
              `Not allowed to increase license when price or current license number is 0`,
            );
          }

          setWaitingStates((prevState) => ({
            ...prevState,
            submitSessionData: true,
          }));

          if (draftId) {
            await timeout(updateDraft(draftId));
            document.cookie = `Draft-${applicationDraftType}-${tenantId}=${draftId}; path=/; max-age=5184000`;
          } else {
            const newDraftId = await timeout(createDraft());
            document.cookie = `Draft-${applicationDraftType}-${tenantId}=${newDraftId}; path=/; max-age=5184000`;
          }
          const paymentInfo = await getPaymentKind();

          setWaitingStates((prevState) => ({
            ...prevState,
            submitSessionData: false,
          }));

          setStep(step + 1, false, paymentInfo);
        } catch (err) {
          setDisableSubmit(false);
          setDialogConfig({ state: 'error' });
        }
      };
      f();
    } else {
      setSubmitForm(false);
      setDisableSubmit(false);
    }
  }, [
    applicationControlAPIBaseUrl,
    contractAPIBaseUrl,
    baseContractId,
    customerId,
    draftId,
    emailAddress,
    formData,
    getAccessTokenSilently,
    navigate,
    planId,
    price,
    serviceId,
    setStep,
    step,
    submitForm,
    tenantId,
    userId,
    globalState.saasBillingAddressId,
    discountedPrice,
    currentLicenseNumber,
  ]);

  const handleLoadSessionData = async (load: boolean) => {
    setLoadSessionData(load);
    setShowConfirmationDialog(false);
    if (!load && draftId) {
      // delete draft
      const deleteDraft = async (draftId: string) => {
        const method = 'DELETE';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/customers/${customerId}/drafts/${draftId}`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const _body = {
          applicationDraftType: applicationDraftType,
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        return resp.status === 200
          ? Promise.resolve(resp.text())
          : Promise.reject(
              new Error(`Failed in deleting form. status is ${resp.status}`),
            );
      };

      const f = async () => {
        try {
          setWaitingStates((prevState) => ({
            ...prevState,
            deleteDraft: true,
          }));
          await timeout(deleteDraft(draftId));
          document.cookie = `Draft-${applicationDraftType}-${tenantId}=; path=/; max-age=0`;
          setDraftId(undefined);
        } catch (err) {
          setDialogConfig({ state: 'error' });
        } finally {
          setWaitingStates((prevState) => ({
            ...prevState,
            deleteDraft: false,
          }));
        }
      };
      f();
    }
  };

  const [formComponentsPart1, setFormComponentsPart1] = React.useState<
    FormComponentProps[][]
  >(formComponentsPart1Init);
  React.useEffect(() => {
    const licenseNumberIndex = formComponentsPart1Init.findIndex(
      (config) => config[0].id === 'service-license-number-increased',
    );
    if (licenseNumberIndex < 0) return;
    const licenseNumberStr = (formData['service-license-number-increased'] ??
      '') as string;

    const notValidInput = (value: string) =>
      value.match(new RegExp(/^[1-9][\d]*$/)) === null;

    const shouldValidInputHelperText = '1以上の数字を入力してください。';

    const validate = (value: string): string => {
      if (notValidInput(value)) {
        return shouldValidInputHelperText;
      } else {
        return '';
      }
    };

    setFormComponentsPart1((prev) => {
      const helperText = validate(licenseNumberStr);
      const curr = _.cloneDeep(prev);
      if (curr[licenseNumberIndex][0]) {
        prev[licenseNumberIndex][0] = {
          ...prev[licenseNumberIndex][0],
          error: helperText !== '',
          validator: {
            helperText: helperText,
            validate: () => validate(licenseNumberStr) === '',
          },
        } as FormComponentProps;
      }
      return curr;
    });
  }, [formComponentsPart1Init, formData, currentLicenseNumber]);

  return (
    <IncreaseLicenseApplicationStep1
      {...props}
      formComponentsPart1={formComponentsPart1}
      formData={formData}
      priceInfo={price}
      setFormData={setFormData}
      isValids={isValids}
      setIsValids={setIsValids}
      forceValidation={forceValidation}
      setForceValidation={setForceValidation}
      iconImgSrc={`${process.env.PUBLIC_URL}/images/icon-project-board@2x.png`}
      iconImgAlt="Project Board Logo"
      colors={{
        firstThemeColor: '#6A8AFF',
        secondThemeColor: '#6AA4FF',
      }}
      dialogConfig={dialogConfig}
      onClickDialogClose={handleDialogClick}
      showConfirmationDialog={showConfirmationDialog}
      onLoadSessionData={handleLoadSessionData}
    >
      <Link
        onClick={() => {
          navigate(-1);
        }}
        className={classes.backlink}
      >
        {'前のページへ戻る'}
      </Link>
    </IncreaseLicenseApplicationStep1>
  );
};
