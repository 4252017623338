import React from 'react';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export interface FormSectionTitleProps {
  className?: string;
  id: string;
  label?: React.ReactNode;
}

export const FormSectionTitle: React.FC<FormSectionTitleProps> = ({
  className,
  label,
}) => {
  const Component = styled('p')(({ theme }) => ({
    fontSize: '17px',
    lineHeight: '32px',
    fontWeight: 'bold',
    letterSpacing: 0,
    color: '#333333',
  }));

  return (
    <Typography component={Component} className={className}>
      {label}
    </Typography>
  );
};
