import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import _ from 'lodash';

export interface MetaProps {
  children?: JSX.Element | JSX.Element[];
}

const Meta: React.FC<MetaProps> = ({ ...props }) => {
  React.useEffect(() => {
    const metaKeyCandidates = ['name', 'property', 'charset'] as const;

    const createMetaKey = (meta: HTMLElement) => {
      const candidates = metaKeyCandidates
        .map((k) => {
          const value = meta.getAttribute(k);
          return value !== null ? `${k}-${value}` : '';
        })
        .filter((v) => v);
      return candidates.length > 0 ? candidates[0] : meta.outerHTML;
    };

    const titleCollection = document.head.getElementsByTagName('title');
    const titles: HTMLElement[] = _.slice(titleCollection);
    for (let i = 0; i !== titles.length; i++) {
      document.head.removeChild(titles[i]);
    }
    const metaCollection = document.head.getElementsByTagName('meta');
    const metas = _.slice(metaCollection);
    const metaKeyToIndex = _.fromPairs(
      metas.map((meta, i) => {
        return [createMetaKey(meta), i];
      }),
    );
    for (let i = metas.length; i !== 0; i--) {
      document.head.removeChild(metas[i - 1]);
    }

    const newTitles: HTMLElement[] = [];
    const newMetas: HTMLElement[] = _.cloneDeep(metas);
    const newOthers: HTMLElement[] = [];
    const pushNewHTMLElement = (element: JSX.Element) => {
      switch (element.type) {
        case 'title':
          const title = document.createElement('title');
          title.innerHTML = renderToStaticMarkup(element.props.children);
          newTitles.push(title);
          break;
        case 'meta':
          const meta = document.createElement('meta');
          _.entries(element.props).forEach(([key, value]) => {
            if (key !== 'children') {
              meta.setAttribute(key, (value as any) as string);
            }
          });
          const key = createMetaKey(meta);
          const index = metaKeyToIndex[key] || -1;
          if (index >= 0) {
            newMetas[index] = meta;
          } else {
            newMetas.push(meta);
          }
          break;
        default:
          const other = document.createElement(element.type);
          _.entries(element.props).forEach(([key, value]) => {
            if (key !== 'children') {
              other.setAttribute(key, (value as any) as string);
            }
          });
          other.innerHTML = renderToStaticMarkup(element.props.children);
          newOthers.push(other);
          break;
      }
    };

    if (_.isArray(props.children)) {
      props.children?.forEach((element) => {
        pushNewHTMLElement(element as JSX.Element);
      });
    } else {
      pushNewHTMLElement(props.children as JSX.Element);
    }
    newTitles.push(...titles);

    for (let i = newOthers.length; i !== 0; i--) {
      document.head.insertBefore(newOthers[i - 1], document.head.firstChild);
    }
    if (newTitles.length > 0) {
      document.head.insertBefore(newTitles[0], document.head.firstChild);
    }

    for (let i = newMetas.length; i !== 0; i--) {
      document.head.insertBefore(newMetas[i - 1], document.head.firstChild);
    }

    const cleanup = () => {
      for (let i = 0; i !== newMetas.length; i++) {
        document.head.removeChild(newMetas[i]);
      }
      if (newTitles.length > 0) {
        document.head.removeChild(newTitles[0]);
      }
      for (let i = 0; i !== newOthers.length; i++) {
        document.head.removeChild(newOthers[i]);
      }

      for (let i = titles.length; i !== 0; i--) {
        document.head.insertBefore(titles[i - 1], document.head.firstChild);
      }
      for (let i = metas.length; i !== 0; i--) {
        document.head.insertBefore(metas[i - 1], document.head.firstChild);
      }
    };

    return cleanup;
  }, [props.children]);
  return <></>;
};

export default Meta;
