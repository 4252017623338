import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export interface StyledPaperColorProps {
  firstThemeColor: string;
  secondThemeColor: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: StyledPaperColorProps) => ({
      margin: 0,
      maxWidth: '888px',
      width: '100%',
      background: '#ffffff',
      boxShadow: '0px 16px 32px #00000014',
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up(768)]: {
        paddingBottom: theme.spacing(5),
      },
      '&:before': {
        content: '""',
        display: 'block',
        height: '6px',
        background: `transparent linear-gradient(to right, ${props.firstThemeColor} 0%, ${props.secondThemeColor} 100%) 0% 0% no-repeat padding-box`,
      },
    }),
    contents: {
      margin: theme.spacing(3, 2, 0),
      [theme.breakpoints.up(768)]: {
        margin: theme.spacing(4.5, 4, 0),
      },
    },
    description: {
      marginTop: 0,
      marginBottom: '32px',
      fontSize: '14px',
      lineHeight: '21px',
      color: '#333333',
      textAlign: 'center',
      '& > *': {
        display: 'block',
      },
    },
    iconBox: {
      margin: theme.spacing(0, 'auto', 4),
      height: '64px',
      width: '64px',
      '& > *': {
        height: '100%',
        width: '100%',
      },
    },
  }),
);

export type StyledPaperProps = {
  className?: string;
  description?: React.ReactNode;
  iconImgSrc?: string;
  iconImgAlt?: string;
  colors?: StyledPaperColorProps;
  children?: React.ReactNode;
};

export const StyledPaper: React.FC<StyledPaperProps> = ({
  className,
  description,
  iconImgSrc,
  iconImgAlt,
  colors,
  children,
}) => {
  const classes = useStyle(
    colors ?? { firstThemeColor: '#ffffff', secondThemeColor: '#ffffff' },
  );
  return (
    <div className={`${classes.root} ${className || ''}`}>
      <div className={classes.contents}>
        {description && (
          <div className={classes.description}>{description}</div>
        )}
        {iconImgSrc && (
          <div className={classes.iconBox}>
            <img src={iconImgSrc} alt={iconImgAlt} />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
