import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { StyledButton } from '@bizapp-frontend/customer/molecules/StyledButton';
import {
  StyledPaper,
  StyledPaperProps,
} from '@bizapp-frontend/customer/molecules/StyledPaper';
import { FormGeneratorProps } from '@bizapp-frontend/customer/organisms/form/FormGenerator';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

export interface PurchaseApplicationStep2Props
  extends StyledPaperProps,
    Omit<FormGeneratorProps, 'formComponents'> {
  baseUrl?: string;
  className?: string;
  submitTitle?: string;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  disableSubmit?: boolean;
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    content: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(3),
    },
    contentTitle: {
      marginBottom: theme.spacing(2),
    },
    stepDescription: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      textAlign: 'center',
      '& .MuiTypography-subtitle1': {
        fontSize: '14px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
      },
    },
    submitButtonArea: {
      width: '100%',
      margin: theme.spacing(3, 0, 0),
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
    },
    agreements: {
      margin: theme.spacing(0, 'auto', 1.5),
      '& .MuiTypography-body1': {
        marginLeft: theme.spacing(1),
        fontSize: '14px',
        lineHeight: '21px',
        letterSpacing: 0,
      },
      '& .MuiLink-root': {
        color: '#1565C0',
      },
      '& .MuiCheckbox-root': {
        padding: theme.spacing(0),
        color: '#787F86',
        '& svg': {
          width: '21px',
          height: '21px',
        },
      },
    },
    submitButton: {
      marginRight: 'auto',
      marginLeft: 'auto',
      display: 'block',
      width: '100%',
      maxWidth: '400px',
      height: '48px',
    },
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(2, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
  }),
);

export const PurchaseApplicationStep2: React.FC<PurchaseApplicationStep2Props> = ({
  className,
  iconImgSrc,
  iconImgAlt,
  colors,
  submitTitle,
  onSubmit,
  disableSubmit,
  children,
  dialogState,
  onClickDialogClose,
}) => {
  const classes = useStyle();

  return (
    <div className={`${classes.root} ${className || ''}`}>
      <Box className={classes.stepDescription}>
        <Typography variant="subtitle1" component="h2">
          {'Step 2'}
        </Typography>
        <Typography>{'お支払い情報を入力してください。'}</Typography>
      </Box>
      <StyledPaper
        iconImgSrc={iconImgSrc}
        iconImgAlt={iconImgAlt}
        colors={colors}
      >
        <div>
          <Box className={classes.content}>
            <Typography variant="h3" className={classes.contentTitle}>
              決済ページへ移動します。
            </Typography>
            <Typography variant="body1">
              以下のボタンをクリックすると、外部サイト（Stripe）の決済ページが開きます。
            </Typography>
            <Typography variant="body1">
              完了後、ふたたびHUE Works
              Suiteの画面へ戻ってきますので、画面上の表示に従ってください。
            </Typography>
          </Box>

          <Box className={classes.submitButtonArea}>
            <StyledButton
              variant="primary"
              label={submitTitle ?? ''}
              onClick={onSubmit}
              className={classes.submitButton}
              disabled={disableSubmit}
            />
            {children}
            <ProcessingDialog
              state={dialogState ?? 'close'}
              onClickError={onClickDialogClose}
            />
          </Box>
        </div>
      </StyledPaper>
    </div>
  );
};
