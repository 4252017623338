import React from 'react';
import { Box, Link, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    padding: theme.spacing(3.25, 2, 3),
    color: '#7f7f7f',
    [theme.breakpoints.up('sm')]: { padding: theme.spacing(3.25, 4, 3) },
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      height: '72px',
      padding: theme.spacing(0, 4),
    },
  },

  nav: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(-1),
    [theme.breakpoints.up('sm')]: { flexFlow: 'row wrap' },
    [theme.breakpoints.up('lg')]: { margin: 'auto 0' },
  },
  links: {
    color: '#636363',
    fontSize: '14px',
    lineHeight: '21px',
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 'normal',
    letterSpacing: 0,
    padding: theme.spacing(1),
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: { marginRight: theme.spacing(1) },
    [theme.breakpoints.up('lg')]: { padding: 0, marginRight: theme.spacing(3) },
    '&:last-child': { marginRight: 0 },
    '&:link': { color: '#7f7f7f' },
    '&:visited': { color: '#7f7f7f' },
    '&:action': { color: '#333333', textDecoration: 'underline' },
    '&:focus': { color: '#333333', textDecoration: 'underline' },
    '&:hover': { color: '#333333', textDecoration: 'underline' },
  },
  copyright: {
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'center',
    margin: theme.spacing(4, 'auto', 0),
    [theme.breakpoints.up('lg')]: {
      display: 'inline-block',
      textAlign: 'right',
      margin: theme.spacing('auto', 0, 'auto', 'auto'),
    },
  },
}));

export interface FooterProps {
  className?: string;
  links?: {
    href: string;
    label: string;
    target?: string;
  }[];
}

const Component: React.FC<FooterProps> = ({ className, links }) => {
  const classes = useStyle();

  return (
    <Box component="footer" className={`${classes.root} ${className ?? ''}`}>
      <Box component="nav" className={classes.nav}>
        {links &&
          links.map((v, i) => {
            return (
              <Link
                href={v.href}
                className={classes.links}
                target={v.target ?? ''}
                key={`footer-links-${i}`}
              >
                {v.label}
              </Link>
            );
          })}
      </Box>
      <Box component="p" className={classes.copyright}>
        &copy;2023 Works Applications Co., Ltd.
      </Box>
    </Box>
  );
};

export const Footer = React.memo(Component);
