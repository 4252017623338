import React from 'react';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import TagManager from 'react-gtm-module';
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';

import { theme } from '@bizapp-frontend/customer/styles/theme';
import Contact from '@bizapp-frontend/customer/pages/form/Contact';
import ContactComplete from '@bizapp-frontend/customer/pages/form/ContactComplete';
import EmailVerification from '@bizapp-frontend/customer/pages/form/EmailVerification';
import Home from '@bizapp-frontend/customer/pages/Home';
import PurchaseApplication from '@bizapp-frontend/customer/pages/form/pjb/PurchaseApplication';
import PlanContract from '@bizapp-frontend/customer/pages/plancontract/PlanContract';
import SignUp from '@bizapp-frontend/customer/pages/form/SignUp';
import TrialApplication from '@bizapp-frontend/customer/pages/form/pjb/TrialApplication';
import TrialApplicationComplete from '@bizapp-frontend/customer/pages/form/pjb/TrialApplicationComplete';
import TrialApplicationWorkflow from '@bizapp-frontend/customer/pages/form/workflow/TrialApplication';
import TrialApplicationCompleteWorkflow from '@bizapp-frontend/customer/pages/form/workflow/TrialApplicationComplete';
import TrialApplicationChatbot from '@bizapp-frontend/customer/pages/form/chatbot/TrialApplication';
import TrialApplicationCompleteChatbot from '@bizapp-frontend/customer/pages/form/chatbot/TrialApplicationComplete';
import TrialApplicationWorkflowForInternal from '@bizapp-frontend/customer/pages/internal/form/workflow/TrialApplication';
import TrialApplicationCompleteWorkflowForInternal from '@bizapp-frontend/customer/pages/internal/form/workflow/TrialApplicationComplete';
import PageList from '@bizapp-frontend/customer/pages/internal/PageList';
import TestTools from '@bizapp-frontend/customer/pages/internal/TestTools';
import { PurchaseApplicationSuccess } from '@bizapp-frontend/customer/pages/form/pjb/PurchaseApplicationSuccess';
import { PurchaseApplicationCancel } from '@bizapp-frontend/customer/pages/form/pjb/PurchaseApplicationCancel';
import PurchaseApplicationComplete from '@bizapp-frontend/customer/pages/form/pjb/PurchaseApplicationComplete';
import RepurchaseApplication from '@bizapp-frontend/customer/pages/form/pjb/RepurchaseApplication';

import {
  GlobalsContext,
  initialState,
  reducer,
} from '@bizapp-frontend/customer/globals';
import { ContractUpdateCardSuccess } from '@bizapp-frontend/customer/pages/plancontract/ContractUpdateCardSuccess';
import IncreaseLicenseApplication from '@bizapp-frontend/customer/pages/form/pjb/IncreaseLicenseApplication';
import { IncreaseLicenseApplicationSuccess } from '@bizapp-frontend/customer/pages/form/pjb/IncreaseLicenseApplicationSuccess';
import { IncreaseLicenseApplicationCancel } from '@bizapp-frontend/customer/pages/form/pjb/IncreaseLicenseApplicationCancel';
import { ContractUpdateCardCancel } from '@bizapp-frontend/customer/pages/plancontract/ContractUpdateCardCancel';

const App: React.FC = () => {
  const onRedirectCallback = (appState: AppState) => {
    if (appState && appState.returnTo) {
      window.location.href = appState.returnTo;
    }
  };

  return (
    <Auth0Provider
      domain={`${process.env.REACT_APP_AUTH0_DOMAIN ?? ''}`}
      clientId={`${process.env.REACT_APP_AUTH0_CLIENT_ID ?? ''}`}
      redirectUri={window.location.origin}
      audience={`${process.env.REACT_APP_AUTH0_AUDIENCE ?? ''}`}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      <AppContent />
    </Auth0Provider>
  );
};

const AppContent: React.FC = () => {
  const { hash, pathname } = useLocation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();

  const grecaptchaKey = process.env.REACT_APP_GRECAPTCHA_KEY ?? '';
  const AUTH_BASE_URL = process.env.REACT_APP_API_AUTH_BASE_URL ?? '';
  const APPLICATION_CONTROLLER_BASE_URL =
    process.env.REACT_APP_API_APPLICATION_CONTROLLER_BASE_URL ?? '';
  const CONTRUCT_BASE_URL = process.env.REACT_APP_API_CONTRUCT_BASE_URL ?? '';
  const CUSTOMER_BASE_URL = process.env.REACT_APP_API_CUSTOMER_BASE_URL ?? '';
  const FEE_CALCUlATOR_BASE_URL =
    process.env.REACT_APP_API_FEE_CALCULATOR_BASE_URL ?? '';
  const PAYMENT_GATEWAY_BASE_URL =
    process.env.REACT_APP_API_PAYMENT_GATEWAY_BASE_URL ?? '';
  const TENANT_MANAGEMENT_BASE_URL =
    process.env.REACT_APP_API_TENANT_MANAGEMENT_BASE_URL ?? '';
  const ZIP_CODE_BASE_URL = process.env.REACT_APP_ZIPCODE_API_BASEURL ?? '';
  const PJB_DOMAIN_URL_SUFFIX =
    process.env.REACT_APP_API_PJB_DOMAIN_SUFFIX ?? '';
  const WORKFLOW_DOMAIN_URL =
    process.env.REACT_APP_API_WORKFLOW_DOMAIN_URL ?? '';
  const CHATBOT_DOMAIN_URL_SUFFIX =
    process.env.REACT_APP_API_CHATBOT_DOMAIN_SUFFIX ?? '';

  React.useEffect(() => {
    if (!hash) window.scrollTo(0, 0);

    if (
      pathname.startsWith('/signup') ||
      pathname.startsWith('/application/pjb/trial') ||
      pathname.startsWith('/application/workflow/trial') ||
      pathname.startsWith('/application/chatbot/trial')
    ) {
      const externalGtmId = process.env.REACT_APP_EXTERNAL_GTM_ID || '';
      if (externalGtmId !== '') {
        TagManager.initialize({
          gtmId: externalGtmId,
        });
      }

      const internalGtmId = process.env.REACT_APP_INTERNAL_GTM_ID || '';
      if (internalGtmId !== '') {
        TagManager.initialize({
          gtmId: internalGtmId,
        });
      }
    }
  }, [hash, pathname]);

  React.useEffect(() => {
    if (!isAuthenticated || state.emailAddress || !user) {
      return;
    }
    dispatch({ type: 'SET_USER_ID', userId: user.email ?? '' });
    dispatch({ type: 'SET_EMAIL_ADDRESS', emailAddress: user.email ?? '' });

    const appMetadata = user['https://bizpp.worksap.co.jp/app_metadata'] ?? {};
    const saasBillingAddressIds = appMetadata['saas_billing_address_ids'];
    if (saasBillingAddressIds && saasBillingAddressIds.length > 0) {
      dispatch({
        type: 'SET_SAAS_BILLING_ADDRESS_ID',
        saasBillingAddressId: saasBillingAddressIds[0],
      });
    }

    const f = async () => {
      const accessToken = await getAccessTokenSilently();
      const userResp = fetch(
        `${CUSTOMER_BASE_URL}/api/customer/users/${user.email}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const customerResp = fetch(
        `${CUSTOMER_BASE_URL}/api/customer/users/${user.email}/customers`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );

      const [userJsonBody, customerJsonBody] = await Promise.all([
        userResp,
        customerResp,
      ]);
      const userJson = await userJsonBody.json();
      const customerJson = await customerJsonBody.json();
      const customerIds = appMetadata['customer_ids'];
      if (
        customerIds &&
        customerIds.length > 0 &&
        customerJson.length > 0 &&
        customerJson.indexOf(customerIds[0]) !== -1
      ) {
        dispatch({ type: 'SET_CUSTOMER_ID', customerId: customerIds[0] });
      }
      dispatch({ type: 'SET_USER_NAME', userName: userJson.userName });
      dispatch({ type: 'SET_USER_TEL', userTel: userJson.tel ?? '' });
    };
    if (user.email) {
      f();
    }
  }, [
    getAccessTokenSilently,
    user,
    state.emailAddress,
    isAuthenticated,
    CUSTOMER_BASE_URL,
  ]);

  return (
    <GlobalsContext.Provider value={{ state, dispatch }}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedElement
                element={
                  <Home
                    applicationControlAPIBaseUrl={
                      APPLICATION_CONTROLLER_BASE_URL
                    }
                    contractAPIBaseUrl={CONTRUCT_BASE_URL}
                    tenantManegementAPIBaseUrl={TENANT_MANAGEMENT_BASE_URL}
                    pjbDomainUrlSuffix={PJB_DOMAIN_URL_SUFFIX}
                    workflowDomainUrl={WORKFLOW_DOMAIN_URL}
                    chatbotDomainUrlSuffix={CHATBOT_DOMAIN_URL_SUFFIX}
                  />
                }
                development={state.development}
              />
            }
          />
          <Route
            path="/contact"
            element={
              <ProtectedElement
                element={
                  <Contact
                    applicationControlAPIBaseUrl={
                      APPLICATION_CONTROLLER_BASE_URL
                    }
                    customerAPIBaseUrl={CUSTOMER_BASE_URL}
                    grecaptchaKey={grecaptchaKey}
                  />
                }
                development={state.development}
              />
            }
          />
          <Route
            path="/contact-complete"
            element={
              <ProtectedElement
                element={<ContactComplete isAllowedDirectAccess={true} />}
                development={state.development}
              />
            }
          />
          <Route
            path="/signup"
            element={
              <>
                <Outlet />
              </>
            }
          >
            <Route
              path=":service"
              element={
                <ValidateService
                  page={
                    <SignUp
                      authBaseUrl={AUTH_BASE_URL}
                      grecaptchaKey={grecaptchaKey}
                    />
                  }
                />
              }
            />
          </Route>
          <Route
            path="/signup-email-verification"
            element={
              <>
                <Outlet />
              </>
            }
          >
            <Route
              path=":service"
              element={
                <ValidateService
                  page={
                    <EmailVerification
                      authBaseUrl={AUTH_BASE_URL}
                      grecaptchaKey={grecaptchaKey}
                    />
                  }
                />
              }
            />
          </Route>
          <Route
            path="/application"
            element={
              <>
                <Outlet />
              </>
            }
          >
            <Route
              path="pjb/purchase"
              element={
                <ProtectedElement
                  element={
                    <PurchaseApplication
                      applicationControlAPIBaseUrl={
                        APPLICATION_CONTROLLER_BASE_URL
                      }
                      zipcodeAPIBaseUrl={ZIP_CODE_BASE_URL}
                      feeCalculatorAPIBaseUrl={FEE_CALCUlATOR_BASE_URL}
                      paymentGatewayAPIBaseUrl={PAYMENT_GATEWAY_BASE_URL}
                      customerAPIBaseUrl={CUSTOMER_BASE_URL}
                      tenantManagementAPIBaseUrl={TENANT_MANAGEMENT_BASE_URL}
                      pjbDomainUrlSuffix={PJB_DOMAIN_URL_SUFFIX}
                    />
                  }
                  development={state.development}
                />
              }
            />
            <Route
              path="pjb/purchase/success"
              element={
                <ProtectedElement
                  element={<PurchaseApplicationSuccess />}
                  development={state.development}
                />
              }
            />
            <Route
              path="pjb/purchase/cancel"
              element={
                <ProtectedElement
                  element={<PurchaseApplicationCancel />}
                  development={state.development}
                />
              }
            />
            <Route
              path="pjb/purchase-complete"
              element={
                <ProtectedElement
                  element={
                    <PurchaseApplicationComplete isAllowedDirectAccess />
                  }
                  development={state.development}
                />
              }
            />
            <Route
              path="repayments/:usageYearMonth/:applicationDraftId"
              element={
                <ProtectedElement
                  element={
                    <RepurchaseApplication
                      applicationControlAPIBaseUrl={
                        APPLICATION_CONTROLLER_BASE_URL
                      }
                      paymentGatewayAPIBaseUrl={PAYMENT_GATEWAY_BASE_URL}
                      customerAPIBaseUrl={CUSTOMER_BASE_URL}
                    />
                  }
                  development={state.development}
                />
              }
            />
            <Route
              path="pjb/increase-license"
              element={
                <ProtectedElement
                  element={
                    <IncreaseLicenseApplication
                      applicationControlAPIBaseUrl={
                        APPLICATION_CONTROLLER_BASE_URL
                      }
                      zipcodeAPIBaseUrl={ZIP_CODE_BASE_URL}
                      feeCalculatorAPIBaseUrl={FEE_CALCUlATOR_BASE_URL}
                      paymentGatewayAPIBaseUrl={PAYMENT_GATEWAY_BASE_URL}
                      customerAPIBaseUrl={CUSTOMER_BASE_URL}
                      tenantManagementAPIBaseUrl={TENANT_MANAGEMENT_BASE_URL}
                      pjbDomainUrlSuffix={PJB_DOMAIN_URL_SUFFIX}
                      contractAPIBaseUrl={CONTRUCT_BASE_URL}
                    />
                  }
                  development={state.development}
                />
              }
            />
            <Route
              path="pjb/increase-license/success"
              element={
                <ProtectedElement
                  element={<IncreaseLicenseApplicationSuccess />}
                  development={state.development}
                />
              }
            />
            <Route
              path="pjb/increase-license/cancel"
              element={
                <ProtectedElement
                  element={<IncreaseLicenseApplicationCancel />}
                  development={state.development}
                />
              }
            />
            <Route
              path="pjb/trial"
              element={
                <TrialApplication
                  applicationControlAPIBaseUrl={APPLICATION_CONTROLLER_BASE_URL}
                  grecaptchaKey={grecaptchaKey}
                  customerAPIBaseUrl={CUSTOMER_BASE_URL}
                />
              }
            />
            <Route
              path="pjb/trial-complete"
              element={<TrialApplicationComplete isAllowedDirectAccess />}
            />
            <Route
              path="workflow/trial"
              element={
                <TrialApplicationWorkflow
                  applicationControlAPIBaseUrl={APPLICATION_CONTROLLER_BASE_URL}
                  grecaptchaKey={grecaptchaKey}
                  customerAPIBaseUrl={CUSTOMER_BASE_URL}
                />
              }
            />
            <Route
              path="workflow/trial-complete"
              element={
                <TrialApplicationCompleteWorkflow isAllowedDirectAccess />
              }
            />
            <Route
              path="chatbot/trial"
              element={
                <TrialApplicationChatbot
                  applicationControlAPIBaseUrl={APPLICATION_CONTROLLER_BASE_URL}
                  grecaptchaKey={grecaptchaKey}
                  customerAPIBaseUrl={CUSTOMER_BASE_URL}
                />
              }
            />
            <Route
              path="chatbot/trial-complete"
              element={
                <TrialApplicationCompleteChatbot isAllowedDirectAccess />
              }
            />
          </Route>
          <Route
            path="/plan-contract"
            element={
              <ProtectedElement
                element={
                  <PlanContract
                    contractAPIBaseUrl={CONTRUCT_BASE_URL}
                    paymentGatewayAPIBaseUrl={PAYMENT_GATEWAY_BASE_URL}
                    tenantManagementAPIBaseUrl={TENANT_MANAGEMENT_BASE_URL}
                    applicationControlAPIBaseUrl={
                      APPLICATION_CONTROLLER_BASE_URL
                    }
                  />
                }
              />
            }
          />
          <Route
            path="/plan-contract/success"
            element={
              <ProtectedElement
                element={<ContractUpdateCardSuccess />}
                development={state.development}
              />
            }
          />
          <Route
            path="/plan-contract/cancel"
            element={
              <ProtectedElement
                element={<ContractUpdateCardCancel />}
                development={state.development}
              />
            }
          />
          <Route path="/internal/page-list" element={<PageList />} />
          <Route
            path="/internal/test-tools"
            element={
              <ProtectedElement
                element={
                  <TestTools
                    applicationControlAPIBaseUrl={
                      APPLICATION_CONTROLLER_BASE_URL
                    }
                  />
                }
              />
            }
          />
          <Route
            path="/internal/application/workflow/trial"
            element={
              <TrialApplicationWorkflowForInternal
                applicationControlAPIBaseUrl={APPLICATION_CONTROLLER_BASE_URL}
                grecaptchaKey={grecaptchaKey}
              />
            }
          />
          <Route
            path="/internal/application/workflow/trial-complete"
            element={
              <TrialApplicationCompleteWorkflowForInternal
                isAllowedDirectAccess
              />
            }
          />
          <Route
            path="/internal/*"
            element={<Navigate to="/internal/page-list" replace />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    </GlobalsContext.Provider>
  );
};

/**
 * make sure the serviceId is valid
 * @see https://reactrouter.com/docs/en/v6/getting-started/faq#what-happened-to-regexp-routes-paths
 */
const ValidateService: React.FC<{
  page: React.ReactElement;
}> = ({ page }) => {
  const { service } = useParams();
  const navigate = useNavigate();
  const [valid, setValid] = React.useState(false);

  React.useEffect(() => {
    if (service !== 'pjb' && service !== 'workflow' && service !== 'chatbot') {
      navigate('/', { replace: true });
    } else {
      setValid(true);
    }
  }, [service, navigate]);

  return valid ? page : <></>;
};

export const ProtectedElement: React.PropsWithChildren<any> = ({
  element,
  development,
}: {
  element?: React.ReactElement | null;
  development?: boolean;
}) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    if (development || isLoading || isAuthenticated) {
      return;
    }
    (async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: window.location.href,
        },
      });
    })();
  }, [development, isAuthenticated, isLoading, loginWithRedirect]);

  return development || isAuthenticated ? element : <></>;
};

export default App;
