import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StyledButton } from '@bizapp-frontend/customer/molecules/StyledButton';
import { ServiceType, serviceName } from '@bizapp-frontend/customer/pages/util';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    trial: {
      marginTop: theme.spacing(3),
    },
    trialServiceContainer: {
      [theme.breakpoints.up(768)]: {
        padding: theme.spacing(3, 4),
      },
      display: 'flex',
      flexDirection: 'column',
      boxSizing: 'border-box',
      [theme.breakpoints.up(640)]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: 'initial',
      },
      backgroundColor: '#FFFFFF',
      maxWdith: theme.spacing(111),
      minHeight: theme.spacing(18),
      borderRadius: '3px',
      boxShadow: '0px 1px 3px #0000004D',
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3),

      '& :last-child': {
        marginBottom: 0,
      },

      '& img': {
        width: '48px',
        height: '48px',
        [theme.breakpoints.up(768)]: {
          width: '96px',
          height: '96px',
        },
      },
    },
    button: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(640)]: {
        marginTop: 0,
      },
      '& button': {
        display: 'block',
        width: '100%',
        [theme.breakpoints.up(640)]: {
          width: '200px',
        },
        height: '32px',
        marginBottom: theme.spacing(1),
        '&:last-child': {
          marginBottom: theme.spacing(0),
        },
      },
    },
    serviceName: {
      marginTop: 0,
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up(768)]: {
        marginLeft: theme.spacing(4),
      },
      color: '#333333',
      textAlign: 'left',
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 'bold',
      letterSpacing: 0,
    },
    title: {
      display: 'flex',
      maxHeight: '63px',
      width: '100%',
      [theme.breakpoints.up(848)]: {
        maxHeight: '30px',
      },
    },
    discription: {
      marginTop: theme.spacing(2),
      [theme.breakpoints.up(640)]: {
        marginLeft: theme.spacing(8),
      },
      [theme.breakpoints.up(768)]: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(16),
      },
      display: 'block',
      color: '#333333',
      fontSize: '14px',
    },
    heading: {
      display: 'flex',
      maxHeight: '63px',
      width: '100%',
      [theme.breakpoints.up(848)]: {
        maxHeight: '30px',
      },
    },
    content: {
      flexGrow: 1,
      [theme.breakpoints.down(376)]: {
        flexDirection: 'column',
      },
    },
  }),
);

export interface AvailableTrialServiceProps {
  serviceId: ServiceType;
  className?: string;
}

export const AvailableTrialService: React.FC<AvailableTrialServiceProps> = ({
  className,
  serviceId,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={`${classes.trialServiceContainer} ${className}`}>
      <div className={classes.content}>
        <div className={classes.heading}>
          <div className={classes.title}>
            <img
              src={`${process.env.PUBLIC_URL}/images/logo/${serviceId}.png`}
              alt={serviceId as string}
            />
            <h3 className={classes.serviceName}>{serviceName(serviceId)}</h3>
          </div>
        </div>
        <span className={classes.discription}>
          「無料トライアル登録」より、30日間のトライアルをお楽しみいただけます。
        </span>
      </div>
      <div className={classes.button}>
        <StyledButton
          label="無料トライアル登録"
          variant="primary"
          onClick={async () => {
            navigate(`/application/${serviceId}/trial`, {
              state: {
                isAuthenticated: true,
              },
            });
          }}
        />
      </div>
    </div>
  );
};
