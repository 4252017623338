import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';

import {
  FormButton,
  FormButtonGrecaptcha,
  FormButtonGrecaptchaProps,
  FormButtonProps,
} from '@bizapp-frontend/customer/molecules/form/FormButton';
import {
  emailFormInputValidator,
  FormInput,
  FormInputProps,
  fullWidthToHalfWidthConverter,
  passwordFormInputValidator,
  positiveIntegerFormInputValidator,
  telFormInputValidator,
} from '@bizapp-frontend/customer/molecules/form/FormInput';
import {
  FormSectionTitle,
  FormSectionTitleProps,
} from '@bizapp-frontend/customer/molecules/form/FormSectionTitle';
import {
  FormSelect,
  FormSelectProps,
} from '@bizapp-frontend/customer/molecules/form/FormSelect';
import {
  AddressType,
  FormZipcode,
  FormZipcodeProps,
  prefectureMap,
} from '@bizapp-frontend/customer/molecules/form/FormZipcode';
import {
  FormCheckbox,
  FormCheckboxProps,
} from '@bizapp-frontend/customer/molecules/form/FormCheckbox';
import {
  FormCheckboxGroup,
  FormCheckboxGroupProps,
  FormCheckboxGroupValueType,
} from '@bizapp-frontend/customer/molecules/form/FormCheckboxGroup';

export type ComponentSize = 'full' | 'short' | 'medium' | 'long';
export type TextAlign = 'right' | 'left' | 'center';

type StyledInputProps = {
  size?: ComponentSize;
  textAlign?: TextAlign;
};

export type ButtonNormalProps = FormButtonProps & {
  kind: 'button-normal';
};

export type ButtonGrecaptchaProps = FormButtonGrecaptchaProps & {
  kind: 'button-grecaptcha';
};

export type TextSectionTitleProps = FormSectionTitleProps &
  StyledInputProps & {
    kind: 'text-section-title';
  };

export type InputNormalProps = FormInputProps &
  StyledInputProps & {
    kind: 'input-normal';
  };

export type InputEmailProps = FormInputProps &
  StyledInputProps & {
    kind: 'input-email';
  };

export type InputTelProps = FormInputProps &
  StyledInputProps & {
    kind: 'input-tel';
  };

export type InputPositiveNumberProps = FormInputProps &
  StyledInputProps & {
    kind: 'input-positive-number';
  };

export type InputPasswordProps = FormInputProps &
  StyledInputProps & {
    kind: 'input-password';
  };

export type InputPasswordConfirmProps = FormInputProps &
  StyledInputProps & {
    kind: 'input-password-confirmation';
  };

export type SelectNormalProps = FormSelectProps &
  StyledInputProps & {
    kind: 'select-normal';
  };

export type SelectPrefectureProps = FormSelectProps &
  StyledInputProps & {
    kind: 'select-prefecture';
  };

export type InputZipcodeProps = FormZipcodeProps &
  StyledInputProps & {
    kind: 'input-zipcode';
  };

export type CheckboxNormalProps = FormCheckboxProps &
  StyledInputProps & {
    kind: 'checkbox-normal';
  };

export type CheckboxGroupNormalProps = FormCheckboxGroupProps &
  StyledInputProps & {
    kind: 'checkbox-group-normal';
  };

export type FormComponentProps = (
  | ButtonNormalProps
  | ButtonGrecaptchaProps
  | TextSectionTitleProps
  | InputNormalProps
  | InputEmailProps
  | InputTelProps
  | InputPositiveNumberProps
  | InputPasswordProps
  | InputPasswordConfirmProps
  | SelectNormalProps
  | SelectPrefectureProps
  | InputZipcodeProps
  | CheckboxNormalProps
  | CheckboxGroupNormalProps
) & {
  associatedId?: string;
  associatedProperty?: string;
};

export interface FormGeneratorProps {
  className?: string;
  key?: string;
  formComponents?: FormComponentProps[][];
  formData?: { [key: string]: any };
  setFormData?: (
    formData:
      | { [key: string]: any }
      | ((prevVar: { [key: string]: any }) => { [key: string]: any }),
  ) => void;
  isValids?: { [key: string]: any };
  setIsValids?: (
    isValids:
      | { [key: string]: any }
      | ((prevVar: { [key: string]: boolean }) => { [key: string]: boolean }),
  ) => void;
  forceValidation?: boolean;
  setForceValidation?: (
    forceValidation: boolean | ((prevVar: boolean) => boolean),
  ) => void;
}

export const initFormDataPairs = (
  kinds: FormComponentProps[],
): { [key: string]: any } => {
  return _.fromPairs(
    kinds.filter((v) => !v.associatedId).map((v) => [v.id, initFormData(v)]),
  );
};

export const initFormData = (
  c: FormComponentProps,
): boolean | AddressType | string | FormCheckboxGroupValueType => {
  if (c.kind.startsWith('checkbox-group')) {
    return (
      ('items' in c &&
        c.items &&
        _.fromPairs(
          c.items.map((v) => [[v.id], { checked: false, value: v.label }]),
        )) ??
      {}
    );
  } else if (c.kind.startsWith('checkbox')) {
    return false;
  } else if (c.kind === 'input-zipcode') {
    return {
      zipcode: '',
      prefecture: '',
      address: '',
    };
  } else {
    return '';
  }
};

export const FormGenerator: React.FC<FormGeneratorProps> = ({
  className,
  formComponents,
  formData,
  setFormData,
  isValids,
  setIsValids,
  forceValidation,
  setForceValidation,
}) => {
  const classes = makeStyles((theme: Theme) =>
    createStyles({
      row: {
        marginBottom: theme.spacing(3),
        '&:last-child': {
          marginBottom: 0,
        },
      },
      inputCommon: {
        width: '100%',
      },
      inputShort: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(3),
        '&:first-child': {
          marginLeft: 0,
          marginTop: 0,
        },
        [theme.breakpoints.up(600)]: {
          marginLeft: theme.spacing(3),
          marginTop: theme.spacing(0),
          width: 'calc(50% - 12px)',
          maxWidth: '400px',
        },
        [theme.breakpoints.up(888)]: {
          maxWidth: '188px',
        },
      },
      inputMedium: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(3),
        '&:first-child': {
          marginTop: 0,
          marginLeft: 0,
        },
        [theme.breakpoints.up(888)]: {
          marginTop: theme.spacing(0),
          marginLeft: theme.spacing(3),
          maxWidth: '400px',
        },
      },
      inputLong: {
        maxWidth: '824px',
      },
      inputFull: {},
      inputTextAlignRight: {
        '& .MuiInputBase-input ': {
          textAlign: 'right',
        },
      },
      inputTextAlignLeft: {
        '& .MuiInputBase-input ': {
          textAlign: 'left',
        },
      },
      inputTextAlignCenter: {
        '& .MuiInputBase-input ': {
          textAlign: 'center',
        },
      },
    }),
  )();

  const classOfSize = React.useCallback(
    (size: ComponentSize) => {
      switch (size) {
        case 'full':
          return `${classes.inputCommon} ${classes.inputFull}`;
        case 'long':
          return `${classes.inputCommon} ${classes.inputLong}`;
        case 'medium':
          return `${classes.inputCommon} ${classes.inputMedium}`;
        case 'short':
          return `${classes.inputCommon} ${classes.inputShort}`;
      }
    },
    [
      classes.inputCommon,
      classes.inputFull,
      classes.inputLong,
      classes.inputMedium,
      classes.inputShort,
    ],
  );

  const classOfTextAlign = React.useCallback(
    (textAlign: TextAlign | undefined) => {
      switch (textAlign) {
        case 'right':
          return classes.inputTextAlignRight;
        case 'left':
          return classes.inputTextAlignLeft;
        case 'center':
          return classes.inputTextAlignCenter;
        default:
          return '';
      }
    },
    [
      classes.inputTextAlignCenter,
      classes.inputTextAlignLeft,
      classes.inputTextAlignRight,
    ],
  );

  return (
    <div className={className}>
      {formComponents &&
        formComponents.map((row, i) => {
          return (
            <div className={classes.row} key={`form-row-${i}`}>
              {row &&
                row.map(({ associatedId, associatedProperty, ...props }) => {
                  const id = props.id ?? '';

                  if (id && props.kind !== 'text-section-title') {
                    props.setForceValidation = setForceValidation;
                    props.forceValidation = forceValidation;

                    if (
                      props.kind !== 'button-normal' &&
                      props.kind !== 'button-grecaptcha'
                    ) {
                      const v =
                        (formData && formData[associatedId || id]) ??
                        props.value ??
                        '';

                      if (
                        associatedId &&
                        associatedProperty &&
                        associatedProperty
                      ) {
                        props.value = v[associatedProperty];
                        props.setValue = (value: any) => {
                          setFormData &&
                            setFormData((prev) => ({
                              ...prev,
                              [associatedId]: {
                                ...prev[associatedId],
                                [associatedProperty as string]: value,
                              },
                            }));
                        };
                      } else {
                        props.value = v;
                        props.setValue = (value: any) => {
                          setFormData &&
                            setFormData((prev) => ({
                              ...prev,
                              [associatedId || id]: value,
                            }));
                        };
                      }
                      props.setIsValid = (value: any) => {
                        setIsValids &&
                          setIsValids((prev) => ({
                            ...prev,
                            [id]: value,
                          }));
                      };
                      // props.forceValidation = forceValidation;
                    } else {
                      props.isValids = isValids;
                    }
                  }
                  const _styledInputProps = props as StyledInputProps;
                  const _textAlign = _styledInputProps.textAlign;
                  if (_textAlign) {
                    delete _styledInputProps.textAlign;
                  }
                  switch (props.kind) {
                    case 'button-normal':
                      return <FormButton {...props} key={id} />;
                    case 'button-grecaptcha':
                      return <FormButtonGrecaptcha {...props} key={id} />;
                    case 'text-section-title':
                      return <FormSectionTitle {...props} key={id} />;
                    case 'select-normal':
                      return (
                        <FormSelect
                          {...props}
                          className={`${classOfSize(props.size ?? 'long')} ${
                            props.className ?? ''
                          }`}
                          key={id}
                        />
                      );
                    case 'select-prefecture':
                      return (
                        <FormSelect
                          {...props}
                          className={`${classOfSize(props.size ?? 'long')} ${
                            props.className ?? ''
                          }`}
                          key={id}
                          options={prefectureMap}
                        />
                      );
                    case 'input-email':
                      return (
                        <FormInput
                          {...props}
                          className={`${classOfSize(
                            props.size ?? 'long',
                          )} ${classOfTextAlign(_textAlign)}`}
                          key={id}
                          validator={emailFormInputValidator}
                        />
                      );
                    case 'input-tel':
                      return (
                        <FormInput
                          {...props}
                          className={`${classOfSize(
                            props.size ?? 'long',
                          )} ${classOfTextAlign(_textAlign)} ${
                            props.className ?? ''
                          }`}
                          key={props.id}
                          validator={telFormInputValidator}
                          converter={fullWidthToHalfWidthConverter}
                        />
                      );
                    case 'input-positive-number':
                      return (
                        <FormInput
                          {...props}
                          className={`${classOfSize(
                            props.size ?? 'long',
                          )} ${classOfTextAlign(props.textAlign)} ${
                            props.className ?? ''
                          }`}
                          key={props.id}
                          validator={positiveIntegerFormInputValidator}
                          converter={fullWidthToHalfWidthConverter}
                        />
                      );
                    case 'input-password':
                      return (
                        <FormInput
                          {...props}
                          className={`${classOfSize(
                            props.size ?? 'long',
                          )} ${classOfTextAlign(_textAlign)}`}
                          type="password"
                          key={id}
                          validator={passwordFormInputValidator}
                        />
                      );
                    case 'input-password-confirmation':
                      return (
                        <FormInput
                          {...props}
                          className={`${classOfSize(
                            props.size ?? 'long',
                          )} ${classOfTextAlign(_textAlign)}`}
                          type="password"
                          key={id}
                        />
                      );
                    case 'input-zipcode':
                      return (
                        <FormZipcode
                          {...props}
                          className={`${classOfSize(props.size ?? 'long')} ${
                            props.className ?? ''
                          }`}
                          key={id}
                        />
                      );
                    case 'checkbox-normal':
                      return <FormCheckbox {...props} key={id} />;
                    case 'checkbox-group-normal':
                      return <FormCheckboxGroup {...props} key={id} />;
                    case 'input-normal': // fall through becase of default value
                    default:
                      return (
                        <FormInput
                          {...props}
                          className={`${classOfSize(
                            props.size ?? 'long',
                          )} ${classOfTextAlign(_textAlign)} ${
                            props.className ?? ''
                          }`}
                          key={id}
                        />
                      );
                  }
                })}
            </div>
          );
        })}
    </div>
  );
};
