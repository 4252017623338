import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import * as _ from 'lodash';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Link as ReactRouter, useNavigate, useParams } from 'react-router-dom';

import { emailFormInputValidator } from '@bizapp-frontend/customer/molecules/form/FormInput';
import { initFormData } from '@bizapp-frontend/customer/organisms/form/FormGenerator';

import {
  SignUp as SignUpTemplate,
  SignUpProps as SignUpTemplateProps,
} from '@bizapp-frontend/customer/templates/form/SignUp';

import { SignUp as WorkflowSignUpTemplate } from '@bizapp-frontend/customer/templates/form/WorkflowSignUp';

import { serviceNameJa, timeout } from '@bizapp-frontend/customer/pages/util';
import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';
import {
  ServiceType,
  serviceThemeColor,
} from '@bizapp-frontend/customer/pages/util';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    resetFormStyle: {
      '& > div': {
        marginBottom: 0,
      },
    },
    description: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3),
      color: '#333333',

      '& > p': {
        margin: 0,
        padding: theme.spacing(1.5),
        textAlign: 'center',

        '&:last-of-type': {
          paddingBottom: 0,
        },
      },
    },
    agreement: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '12px !important',
      marginTop: '24px !important',

      '& .MuiFormControlLabel-root': {
        alignItems: 'flex-start',

        '& .MuiIconButton-root': {
          fontSize: '14px',
        },
      },
    },
    previousPage: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),

      '& a': {
        textAlign: 'center',
        fontSize: '14px',
        color: '#1565C0',
      },
    },
    lineBreak: {
      '& > span': {
        display: 'block',
      },
    },
  }),
);

export interface SignUpProps extends Omit<SignUpTemplateProps, 'serviceId'> {
  authBaseUrl: string;
  grecaptchaKey: string;
}

const SignUp: React.FC<SignUpProps> = ({
  authBaseUrl,
  grecaptchaKey,
  ...props
}) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const { service } = useParams();
  const [conflicted, setConflicted] = React.useState(false);
  const [submitForm, setSubmitForm] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [grecaptcha, setGrecaptcha] = React.useState('');
  const [emailAddress, setEmailAddress] = React.useState('');
  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );
  const handleDialogClick = () => {
    setDialogState('close');
  };

  const handleClick = async (grecaptchaToken: string) => {
    setDisableSubmit(true);
    setGrecaptcha(grecaptchaToken);
    setSubmitForm(true);
  };

  props.className = classes.resetFormStyle;
  props.formComponents = [
    [
      {
        kind: 'input-email',
        id: 'signup-email',
        label: 'メールアドレス',
        name: 'email',
        size: 'full',
        required: true,
        error: conflicted,
        description:
          service === 'pjb' ? (
            ''
          ) : (
            <>
              <div className={classes.lineBreak}>
                <span>
                  ※HUE{serviceNameJa(service as ServiceType)}は法人向けの
                  {serviceNameJa(service as ServiceType)}サービスです。
                </span>
                <span>フリーメールアドレスは入力をご遠慮頂いております。</span>
              </div>
            </>
          ),
        helperText: conflicted
          ? 'このメールアドレスは既に使われています。'
          : emailFormInputValidator.helperText,
        postChange: (value) => {
          setEmailAddress(value);
          setConflicted(false);
        },
      },
    ],
    [
      {
        kind: 'checkbox-normal',
        id: 'agreement',
        label: (
          <>
            <Link href="https://bizapp.worksap.co.jp/terms" target={'_blank'}>
              利用規約
            </Link>
            および
            <Link href="https://bizapp.worksap.co.jp/privacy" target={'_blank'}>
              個人情報の取り扱い
            </Link>
            について同意する。
          </>
        ),
        required: true,
        helperText: 'ご同意をお願いします。',
        className: classes.agreement,
      },
    ],
    [
      {
        kind: 'button-grecaptcha',
        id: 'submit-button',
        label: '確認メールを送信する',
        disabled: disableSubmit,
        postClick: handleClick,
      },
    ],
  ];

  const kinds = _.flatMapDeep(props.formComponents)
    .filter((v) => !(v.kind.startsWith('text') || v.kind.startsWith('button')))
    .map((v) => v);

  const [formData, setFormData] = React.useState<{
    [key: string]: boolean | string | undefined;
  }>(_.fromPairs(kinds.map((v) => [v.id, initFormData(v)])));
  const [isValids, setIsValids] = React.useState(
    _.fromPairs(kinds.map((v) => [v.id, false])),
  );
  const [forceValidation, setForceValidation] = React.useState(false);

  const sendEmail = React.useCallback(
    async (grecaptchaToken) => {
      const method = 'POST';
      const url = `${authBaseUrl}/api/auth/passwordless`;
      const resp = await fetch(url, {
        method: method,
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'X-reCAPTCHA-Token': grecaptchaToken,
        },
        body: JSON.stringify({
          email: emailAddress,
        }),
      });

      if (resp.status === 201) {
        return Promise.resolve();
      } else if (resp.status === 409) {
        setConflicted(true);
        return Promise.reject(
          new Error(`Conflicted. status is ${resp.status}`),
        );
      } else {
        return Promise.reject(
          new Error(`Failed in submitting form. status is ${resp.status}`),
        );
      }
    },
    [authBaseUrl, emailAddress],
  );

  React.useEffect(() => {
    if (submitForm && grecaptcha) {
      setSubmitForm(false);
      const f = async () => {
        try {
          setDialogState('wait');
          await timeout(sendEmail(grecaptcha));
          navigate(`/signup-email-verification/${service}`, {
            state: { emailAddress: emailAddress },
          });
          setDialogState('close');
        } catch (error) {
          setGrecaptcha('');
          setDisableSubmit(false);
          console.error(error);
          setDialogState('close');
        }
      };
      f();
    } else {
      setSubmitForm(false);
      setGrecaptcha('');
      setDisableSubmit(false);
    }
  }, [emailAddress, grecaptcha, navigate, sendEmail, service, submitForm]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={grecaptchaKey}>
      {service === 'workflow' ? (
        <WorkflowSignUpTemplate
          {...props}
          formData={formData}
          setFormData={setFormData}
          isValids={isValids}
          setIsValids={setIsValids}
          forceValidation={forceValidation}
          setForceValidation={setForceValidation}
          login={
            <Link component={ReactRouter} to="/">
              既にアカウントをお持ちの方はこちら
            </Link>
          }
          dialogState={dialogState}
          onClickDialogClose={handleDialogClick}
          serviceThemeColor={serviceThemeColor(service as ServiceType)}
        />
      ) : (
        <SignUpTemplate
          {...props}
          formData={formData}
          setFormData={setFormData}
          isValids={isValids}
          setIsValids={setIsValids}
          forceValidation={forceValidation}
          setForceValidation={setForceValidation}
          login={
            <Link component={ReactRouter} to="/">
              既にアカウントをお持ちの方はこちら
            </Link>
          }
          dialogState={dialogState}
          onClickDialogClose={handleDialogClick}
          serviceThemeColor={serviceThemeColor(service as ServiceType)}
          headingContent={
            <>
              <img
                src={`${process.env.PUBLIC_URL}/images/logo/${service}.png`}
                alt={service as string}
              />
              <span>
                HUE{serviceNameJa(service as ServiceType)} 無料トライアル
              </span>
            </>
          }
        >
          <div className={classes.description}>
            <p className={classes.lineBreak}>
              <span>
                <strong>30日間無料</strong>で<strong>すべての機能</strong>
                をお試しいただけます。
              </span>
              <span>終了後に自動で課金されることはありません。</span>
            </p>
            <p>まずは、アカウントを作成してください。</p>
          </div>
        </SignUpTemplate>
      )}
    </GoogleReCaptchaProvider>
  );
};

export default SignUp;
