import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';
import {
  RepurchaseApplicationStep1,
  RepurchaseApplicationStep1Props,
} from '@bizapp-frontend/customer/templates/form/RepurchaseApplicationStep1';
import { RepurchaseApplicationCommonProps } from '@bizapp-frontend/customer/pages/form/pjb/RepurchaseApplication';
import { FormButton } from '@bizapp-frontend/customer/molecules/form/FormButton';
import { timeout } from '@bizapp-frontend/customer/pages/util';

export interface RepurchaseFormStep1Props
  extends Omit<RepurchaseApplicationStep1Props, 'priceInfo'>,
    RepurchaseApplicationCommonProps {
  applicationControlAPIBaseUrl: string;
  customerAPIBaseUrl: string;
  paymentGatewayAPIBaseUrl: string;
  repurchaseInfo?: RepurchaseInfo;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    submitButtonArea: {
      width: '100%',
      margin: theme.spacing(4, 0, 0),
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
    },
    agreement: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(5.5),
    },
    submitButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(3),
      marginButtom: theme.spacing(5),
      '& > button': {
        width: '100%',
        height: '48px',
        maxWidth: '400px',
      },
      '& span': {
        display: 'block',
      },
    },
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(2, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
  }),
);

export interface RepurchaseInfo {
  description: string;
  price: number;

  customerId: string;
  tenantId: string;
  draftType: string;
}

export const RepurchaseFormStep1: React.FC<RepurchaseFormStep1Props> = ({
  applicationControlAPIBaseUrl,
  paymentGatewayAPIBaseUrl,
  customerAPIBaseUrl,
  usageYearMonth,
  applicationDraftId,
  planId,
  customerId,
  emailAddress,
  userId,
  repurchaseInfo,
  ...props
}) => {
  const classes = useStyle();

  const [submitForm, setSubmitForm] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );

  const { getAccessTokenSilently } = useAuth0();

  const handleDialogClick = () => {
    setDialogState('close');
  };

  const handleClick = React.useCallback(async () => {
    // disable button to prevent multiple-submit
    setDisableSubmit(true);
    setSubmitForm(true);
  }, []);

  React.useEffect(() => {
    if (submitForm && repurchaseInfo) {
      setSubmitForm(false);

      const genCustomerRegisterCardUrl = async (
        repurchaseInfo: RepurchaseInfo,
      ) => {
        const method = 'POST';
        const paymentGatewayId = 'stripe';
        const url = `${paymentGatewayAPIBaseUrl}/api/payment-gateway/${paymentGatewayId}/customers`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        };
        const baseUrl = `${window.location.protocol}//${window.location.host}/application/repayments`;
        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify({
            customer: {
              customer_id: customerId,
              pic_email: emailAddress,
            },
            success_url: `${baseUrl}/${usageYearMonth}/${applicationDraftId}?step=2&sessionId={CHECKOUT_SESSION_ID}`,
            cancel_url: `${baseUrl}/${usageYearMonth}/${applicationDraftId}`,
            paymentGatewayId: 'stripe',
            tenantId: repurchaseInfo.tenantId,
            applicationDraftId: applicationDraftId,
            price: repurchaseInfo.price,
            description: repurchaseInfo.description,
            metadata: {
              userId: userId,
              customerId: customerId,
              applicationDraftId: applicationDraftId,
              applicationDraftType: repurchaseInfo.draftType,
            },
          }),
        });
        return resp.status === 201
          ? Promise.resolve(resp.json())
          : Promise.reject(
              new Error(
                `Failed in getting stripe register url of customer. status is ${resp.status}`,
              ),
            );
      };

      const f = async () => {
        try {
          setDialogState('wait');
          const registerCardUrlInfo = await timeout(
            genCustomerRegisterCardUrl(repurchaseInfo),
          );
          window.location.href = registerCardUrlInfo.session_url;
          setDialogState('close');
        } catch (err) {
          setDisableSubmit(false);
          setDialogState('error');
        }
      };
      f();
    } else {
      setSubmitForm(false);
      setDisableSubmit(false);
    }
  }, [
    applicationDraftId,
    customerId,
    emailAddress,
    getAccessTokenSilently,
    paymentGatewayAPIBaseUrl,
    repurchaseInfo,
    submitForm,
    usageYearMonth,
    userId,
  ]);

  return (
    <RepurchaseApplicationStep1
      {...props}
      paymentInfo={{
        price: Number(repurchaseInfo?.price).toLocaleString() + ' 円' ?? '',
        description: repurchaseInfo?.description ?? '',
      }}
      iconImgSrc={`${process.env.PUBLIC_URL}/images/icon-project-board@2x.png`}
      iconImgAlt="Project Board Logo"
      colors={{
        firstThemeColor: '#6A8AFF',
        secondThemeColor: '#6AA4FF',
      }}
      dialogState={dialogState}
      onClickDialogClose={handleDialogClick}
    >
      <FormButton
        id="update-card-btn"
        label="お支払い方法の変更"
        className={classes.submitButton}
        disabled={disableSubmit}
        postClick={handleClick}
      />
    </RepurchaseApplicationStep1>
  );
};
