import React from 'react';
import { Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

import { FormInputValidator } from '@bizapp-frontend/customer/molecules/form/FormInput';
import {
  FormComponentProps,
  initFormData,
} from '@bizapp-frontend/customer/organisms/form/FormGenerator';

import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';

import {
  TrialApplication as TrialApplicationTemplate,
  TrialApplicationProps as TrialApplicationTemplateProps,
} from '@bizapp-frontend/customer/templates/form/TrialApplication';

import {
  CustomerKind,
  serviceThemeColor,
  timeout,
} from '@bizapp-frontend/customer/pages/util';
import { GlobalsContext } from '@bizapp-frontend/customer/globals';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    agreement: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(5.5),
    },
    submitButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > button': {
        width: '100%',
        height: '48px',
        maxWidth: '400px',
        fontSize: '16px',
        lineHeight: '21p',
      },
      '& span': {
        display: 'block',
      },
    },
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(2, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
  }),
);

export type TrialApplicationState = {
  emailAddress?: string;
  accessToken?: string;
  isAuthenticated?: boolean;
};

export interface TrialApplicationProps
  extends Omit<TrialApplicationTemplateProps, 'title' | 'subTitle'> {
  applicationControlAPIBaseUrl: string;
  customerAPIBaseUrl: string;
}

const TrialApplication: React.FC<TrialApplicationProps> = ({
  applicationControlAPIBaseUrl,
  grecaptchaKey,
  customerAPIBaseUrl,
}) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const { state } = useLocation() as { state: TrialApplicationState };
  const [emailAddress, setEmailAddress] = React.useState(
    state?.emailAddress || '',
  );
  const [accessToken, setAccessToken] = React.useState(
    state?.accessToken || '',
  );
  const [submitForm, setSubmitForm] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [grecaptcha, setGrecaptcha] = React.useState('');
  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [isExistingCustomer, setIsExistingCustomer] = React.useState(false);
  const { state: globalState } = React.useContext(GlobalsContext);

  const { firstThemeColor, secondThemeColor } = serviceThemeColor('chatbot');

  const handleDialogClick = () => {
    setDialogState('close');
  };

  const handleClick = async (grecaptchaToken: string) => {
    setDisableSubmit(true);
    setGrecaptcha(grecaptchaToken);
    setSubmitForm(true);
  };

  const confirmPasswordValidator: FormInputValidator = {
    helperText: 'パスワードが一致しません。',
    validate: (value) => {
      return confirmPassword(value);
    },
  };

  const confirmPassword = (passwordConfirm: string) => {
    const password = formData['password'];
    return !!passwordConfirm && _.isEqual(password, passwordConfirm);
  };

  const formComponents: FormComponentProps[][] = _.map(
    [
      [
        {
          kind: 'text-section-title',
          id: 'title-service',
          label: 'お申込み対象',
        },
      ],
      [
        {
          kind: 'input-normal',
          id: 'service-product',
          label: '利用サービス',
          defaultValue: 'Chatbot',
          size: 'medium',
          disabled: true,
        },
      ],
      [
        {
          kind: 'text-section-title',
          id: 'title-contact-person',
          label: 'ご利用者情報',
        },
      ],
      [
        {
          kind: 'input-email',
          id: 'contact-person-mail',
          label: 'メールアドレス',
          defaultValue: emailAddress,
          name: 'email',
          required: true,
          size: 'medium',
          disabled: true,
        },
      ],
      [
        {
          kind: 'input-password',
          id: 'password',
          label: 'パスワード',
          name: 'name',
          required: true,
          size: 'medium',
          description: (
            <>
              <span>
                利用できる文字列：<strong>半角英数字</strong>および
                <strong>半角記号（!@#$%^&*）</strong>
              </span>
              <span>パスワードは次の条件を満たす必要があります</span>
              <span>
                <strong>8文字以上</strong>および
                <strong>小文字・大文字・数字それぞれを最低1文字含む</strong>
              </span>
            </>
          ),
        },
      ],
      [
        {
          kind: 'input-password-confirmation',
          id: 'password-confirmation',
          label: 'パスワード（確認用）',
          name: 'name',
          required: true,
          size: 'medium',
          validator: confirmPasswordValidator,
        },
      ],
      [
        {
          kind: 'input-normal',
          id: 'contact-person-last-name',
          label: '姓',
          name: 'last-name',
          required: true,
          size: 'medium',
        },
        {
          kind: 'input-normal',
          id: 'contact-person-first-name',
          label: '名',
          name: 'first-name',
          required: true,
          size: 'medium',
        },
      ],
      [
        {
          kind: 'input-normal',
          id: 'contact-person-last-name-kana',
          label: '姓（フリガナ）',
          name: 'last-name-kana',
          size: 'medium',
        },
        {
          kind: 'input-normal',
          id: 'contact-person-first-name-kana',
          label: '名（フリガナ）',
          name: 'first-name-kana',
          size: 'medium',
        },
      ],
      [
        {
          kind: 'input-normal',
          id: 'organization-name',
          label: '会社・団体名',
          name: 'organization',
          size: 'medium',
          required: true,
        },
      ],
      [
        {
          kind: 'input-normal',
          id: 'contact-person-department',
          label: '所属',
          name: 'contact-person-department',
          required: false,
          size: 'medium',
        },
        {
          kind: 'input-normal',
          id: 'contact-person-title',
          label: '役職',
          name: 'contact-person-title',
          required: false,
          size: 'medium',
        },
      ],
      [
        {
          kind: 'input-tel',
          id: 'contact-person-tel',
          label: '携帯番号 (テレワーク中の方など連絡が取れる番号)',
          name: 'tel',
          required: true,
          size: 'medium',
        },
      ],
      [
        {
          kind: 'checkbox-normal',
          id: 'agreement',
          label: (
            <>
              <Link href="https://bizapp.worksap.co.jp/terms" target={'_blank'}>
                利用規約
              </Link>
              および
              <Link
                href="https://bizapp.worksap.co.jp/privacy"
                target={'_blank'}
              >
                個人情報の取り扱い
              </Link>
              について同意する。
            </>
          ),
          required: true,
          helperText: 'ご同意をお願いします。',
          className: classes.agreement,
        },
      ],
      [
        {
          kind: 'button-grecaptcha',
          id: 'submit-button',
          label: '送信する',
          disabled: disableSubmit,
          className: classes.submitButton,
          postClick: handleClick,
        },
      ],
    ],
    (v) =>
      _.filter(v, (o: FormComponentProps) =>
        isExistingCustomer
          ? o.id !== 'password' && o.id !== 'password-confirmation'
          : true,
      ),
  ) as FormComponentProps[][];

  const kinds = _.flatMapDeep(formComponents)
    .filter((v) => !(v.kind.startsWith('text') || v.kind.startsWith('button')))
    .map((v) => v);

  const [formData, setFormData] = React.useState<{
    [key: string]: boolean | string | undefined;
  }>(_.fromPairs(kinds.map((v) => [v.id, initFormData(v)])));

  const [isValids, setIsValids] = React.useState(
    _.fromPairs(kinds.map((v) => [v.id, false])),
  );
  const [forceValidation, setForceValidation] = React.useState(false);

  React.useEffect(() => {
    // The customer has logged in
    if (state && state.isAuthenticated && globalState) {
      // Already procced this flow
      if (isExistingCustomer) {
        return;
      }

      setDialogState('wait');

      // Waiting for retrieval of status from Auth0 or update of global status
      if (
        !isAuthenticated ||
        _.isEmpty(globalState.customerId) ||
        _.isEmpty(globalState.emailAddress)
      ) {
        return;
      }

      setIsExistingCustomer(true);

      setEmailAddress(globalState.emailAddress);

      const f = async () => {
        const accessToken = await getAccessTokenSilently();
        const url = `${customerAPIBaseUrl}/api/customer/customers/${globalState.customerId}`;
        const res = await fetch(url, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (res.ok) {
          const customerInfo = await res.json();
          if (!customerInfo?.users || customerInfo.users.length === 0) {
            return Promise.reject(
              new Error(`Failed in getting customer info. no users`),
            );
          }
          const userInfo = customerInfo.users[0];
          const initData = {
            'contact-person-mail': globalState.emailAddress,
            'contact-person-last-name': userInfo?.userLastName,
            'contact-person-first-name': userInfo?.userFirstName,
            'contact-person-last-name-kana': userInfo?.userLastNameKana,
            'contact-person-first-name-kana': userInfo?.userFirstNameKana,
            'organization-name': customerInfo.company.companyName,
            'contact-person-department': userInfo?.department,
            'contact-person-title': userInfo?.jobTitle,
            'contact-person-tel': userInfo?.tel,
          };
          setFormData((prev) => ({ ...prev, ...initData }));
          setDialogState('close');
          return Promise.resolve();
        } else {
          setDialogState('error');
          return Promise.reject(
            new Error(
              `Failed in getting customer info. status is ${res.status}`,
            ),
          );
        }
      };
      // TODO: error handling
      f();
    } else if (
      !state ||
      _.isEmpty(state.emailAddress) ||
      _.isEmpty(state.accessToken)
    ) {
      const arr: { [key: string]: string } = {};
      if (document.cookie !== '') {
        const tmp = document.cookie.split('; ');
        for (let i = 0; i < tmp.length; i++) {
          const data = tmp[i].split('=');
          arr[data[0]] = decodeURIComponent(data[1]);
        }
      }

      if (_.isEmpty(arr['email']) || _.isEmpty(arr['accessToken'])) {
        navigate('/signup/chatbot');
      } else {
        setEmailAddress(arr['email']);
        setAccessToken(arr['accessToken']);
        setFormData((prev) => ({
          ...prev,
          'contact-person-mail': arr['email'],
        }));
      }
    } else {
      document.cookie = `email=${state.emailAddress}; path=/; max-age=3600`;
      document.cookie = `accessToken=${state.accessToken}; path=/; max-age=3600`;
    }
  }, [
    state,
    state?.emailAddress,
    navigate,
    customerAPIBaseUrl,
    getAccessTokenSilently,
    globalState,
    isAuthenticated,
    isExistingCustomer,
  ]);

  const combineNames = (last?: string, first?: string) => {
    return (last ?? '') + (last && first ? '　' : '') + (first ?? '');
  };

  React.useEffect(() => {
    if (submitForm && grecaptcha) {
      setSubmitForm(false);
      const submitData = async (grecaptchaToken: string) => {
        const method = 'POST';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/applications`;
        const headers = {
          Authorization: `Bearer ${
            isExistingCustomer ? await getAccessTokenSilently() : accessToken
          }`,
          'Content-Type': 'application/json',
          'X-reCAPTCHA-Token': grecaptchaToken,
        };
        const _body = {
          applicationType: 'contract',
          applicationSubtype: isExistingCustomer ? 'trial' : 'new',
          userKind: 'customer',
          userId: emailAddress,
          customerId: isExistingCustomer ? globalState.customerId : '',
          saasBillingAddressId: isExistingCustomer
            ? globalState.saasBillingAddressId
            : '',
          serviceId: 'chatbot',
          notificationKind: 'email',
          jsonData: JSON.stringify({
            'plan-id': 'chatbot-basic',
            'usage-kind': 'trial',
            'contract-kind': 'constant',
            'service-license-number': '1',
            customerKind: 'unknown' as CustomerKind,
            ...formData,
            'contact-person-name': combineNames(
              formData['contact-person-last-name'] as string,
              formData['contact-person-first-name'] as string,
            ),
            'contact-person-name-kana': combineNames(
              formData['contact-person-last-name-kana'] as string,
              formData['contact-person-first-name-kana'] as string,
            ),
          }),
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        return resp.status === 201
          ? Promise.resolve()
          : Promise.reject(
              new Error(`Failed in submitting form. status is ${resp.status}`),
            );
      };

      const f = async () => {
        try {
          setDialogState('wait');
          await timeout(submitData(grecaptcha));
          setDialogState('close');
          document.cookie = `email=; path=/; max-age=0`;
          document.cookie = `accessToken=; path=/; max-age=0`;
          navigate('/application/chatbot/trial-complete', { replace: true });
        } catch (err) {
          setGrecaptcha('');
          setDisableSubmit(false);
          setDialogState('error');
        }
      };
      f();
    } else {
      setSubmitForm(false);
      setGrecaptcha('');
      setDisableSubmit(false);
    }
  }, [
    applicationControlAPIBaseUrl,
    getAccessTokenSilently,
    globalState.customerId,
    globalState.saasBillingAddressId,
    grecaptcha,
    formData,
    isExistingCustomer,
    navigate,
    submitForm,
    state?.emailAddress,
    state?.accessToken,
    emailAddress,
    accessToken,
  ]);

  return (
    <>
      <TrialApplicationTemplate
        title={'無料トライアル利用登録'}
        subTitle={
          '無料トライアルを利用される方の情報を入力し、登録を完了してください。'
        }
        formComponents={formComponents}
        formData={formData}
        setFormData={setFormData}
        isValids={isValids}
        setIsValids={setIsValids}
        forceValidation={forceValidation}
        setForceValidation={setForceValidation}
        iconImgSrc={`${process.env.PUBLIC_URL}/images/logo/chatbot.png`}
        iconImgAlt="Chatbot Logo"
        colors={{
          firstThemeColor: firstThemeColor,
          secondThemeColor: secondThemeColor,
        }}
        dialogState={dialogState}
        onClickDialogClose={handleDialogClick}
        grecaptchaKey={grecaptchaKey}
      >
        <Link
          onClick={() => {
            navigate(-1);
          }}
          className={classes.backlink}
        >
          {'前のページへ戻る'}
        </Link>
      </TrialApplicationTemplate>
    </>
  );
};

export default TrialApplication;
