import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';
import * as _ from 'lodash';

import {
  ContractCard,
  PaymentMethod,
} from '@bizapp-frontend/customer/organisms/ContractCard';
import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';

import {
  addDay,
  timeout,
  toCurrMonthDayMs,
  toNextMonthDayMs,
} from '@bizapp-frontend/customer/pages/util';

export interface ContractListProps {
  contractAPIBaseUrl?: string;
  paymentGatewayAPIBaseUrl?: string;
  tenantManagementAPIBaseUrl?: string;
  applicationControlAPIBaseUrl?: string;
  customerId?: string;
  userId?: string;
  setDialogState?: (
    value:
      | ProcessingDialogState
      | ((prevVar: ProcessingDialogState) => ProcessingDialogState),
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  card: {
    marginBottom: '24px !important',
    '&:last-child': {
      marginBottom: '0px !important',
    },
  },
}));

interface PaymentMethodResp {
  last4: string;
  exp_year: number;
  exp_month: number;
  funding: string;
  country: string;
  brand: string;
  payment_customer_id: string;
}

interface ContractCardInfo {
  contractTitle: React.ReactNode;
  licenseNumber: number;
  paymentMethod: PaymentMethod;
  nextChargeTime: number;
  serviceId: string;
  tenantId: string;
  iconSrc: string;
  usageKind: 'trial' | 'purchased' | 'freemium';
  startDate: number; // only for sorting service in plan contract page
}

interface PaymentKind {
  paymentGatewayId: string;
  issueDate?: string;
}

interface CustomerTenantInfo {
  customerId: string;
  serviceId: string;
  tenantId: string;
  tenantName: string;
  timestamp: number;
}

export const ContractList: React.FC<ContractListProps> = ({
  contractAPIBaseUrl,
  paymentGatewayAPIBaseUrl,
  tenantManagementAPIBaseUrl,
  applicationControlAPIBaseUrl,
  customerId,
  userId,
  setDialogState,
}) => {
  const classes = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [contractCards, setContractCards] = React.useState<ContractCardInfo[]>(
    [],
  );

  React.useEffect(() => {
    if (customerId) {
      const getPaymentMethod = async () => {
        setDialogState && setDialogState('wait');
        setContractCards([]);
        const accessToken = await getAccessTokenSilently();

        const tenantResp = await fetch(
          `${tenantManagementAPIBaseUrl}/api/tenant-management/customers/${customerId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        const tenantJson = (await tenantResp.json()).filter(
          // TODO support other services
          // filter to show pjb service only
          // since all views are designed for pjb only
          (it: CustomerTenantInfo) =>
            it.serviceId === 'pjb' ||
            it.serviceId === 'workflow' ||
            it.serviceId === 'chatbot',
        );

        let paymentKindJson: PaymentKind = { paymentGatewayId: 'none' };
        const paymentKindResp = await fetch(
          `${contractAPIBaseUrl}/api/contract/payment-kind/customers/${customerId}/latest`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        let paymentContractMethod: PaymentMethod = { type: 'none' };
        if (paymentKindResp.ok) {
          paymentKindJson = await paymentKindResp.json();

          if (paymentKindJson.paymentGatewayId === 'stripe') {
            const paymentResp = await fetch(
              `${paymentGatewayAPIBaseUrl}/api/payment-gateway/${paymentKindJson.paymentGatewayId}/customers/${customerId}/card`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${accessToken}`,
                },
              },
            );
            const paymentJson: PaymentMethodResp = await paymentResp.json();
            paymentContractMethod = {
              type: 'card',
              card: {
                brand: paymentJson.brand,
                last4: paymentJson.last4,
              },
            };
          } else {
            paymentContractMethod = {
              type: 'invoice',
              card: undefined,
            };
          }
        }

        const contractCardInfo: ContractCardInfo[] = [];
        for await (const t of tenantJson) {
          const contractResp = await fetch(
            `${contractAPIBaseUrl}/api/contract/customers/${customerId}/tenants/${t.tenantId}/current`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          const contractJson = await contractResp.json();

          let contractTitle = '';
          let iconSrc = '';
          let paymentMethod: PaymentMethod = { type: 'none' };
          let nextChargeTime = 0;

          if (contractJson.serviceId === 'pjb') {
            contractTitle = 'Project Board';
            iconSrc = `${process.env.PUBLIC_URL}/images/icon-project-board.png`;
          } else if (contractJson.serviceId === 'workflow') {
            contractTitle = 'Workflow';
            iconSrc = `${process.env.PUBLIC_URL}/images/icon-workflow@2x.png`;
          } else if (contractJson.serviceId === 'chatbot') {
            contractTitle = 'Chatbot';
            iconSrc = `${process.env.PUBLIC_URL}/images/logo/chatbot.png`;
          }

          if (contractJson.usageKind === 'freemium') {
            contractTitle += ' 無料キャンペーン';
          } else if (contractJson.usageKind === 'trial') {
            contractTitle += ' 無料トライアル';
          } else {
            contractTitle += ' 有料プラン';
            paymentMethod = {
              ...paymentContractMethod,
            };

            const now = Date.now();
            if (paymentMethod.type === 'card') {
              const currentDate = new Date(
                now + (new Date().getTimezoneOffset() + 9 * 60) * 60 * 1000,
              );
              if (currentDate.getDate() <= 8) {
                currentDate.setDate(8);
                nextChargeTime = currentDate.getTime();
              } else {
                currentDate.setMonth(currentDate.getMonth() + 1);
                currentDate.setDate(8);
                nextChargeTime = currentDate.getTime();
              }
            } else if (paymentMethod.type === 'invoice') {
              if (paymentKindJson?.issueDate) {
                const issueDateMs = toCurrMonthDayMs(
                  paymentKindJson?.issueDate,
                  now,
                );
                const threshold = addDay(issueDateMs, 1);
                if (now <= threshold) {
                  nextChargeTime = issueDateMs;
                } else {
                  nextChargeTime = toNextMonthDayMs(
                    paymentKindJson?.issueDate,
                    now,
                  );
                }
              }
            }
          }
          contractCardInfo.push({
            contractTitle: contractTitle,
            licenseNumber: contractJson.volume ?? 0,
            paymentMethod: paymentMethod,
            nextChargeTime: nextChargeTime,
            serviceId: contractJson.serviceId,
            tenantId: contractJson.tenantId,
            iconSrc: iconSrc,
            usageKind: contractJson.usageKind,
            startDate: contractJson.startDate,
          });
        }
        const purchased: ContractCardInfo[] = _.filter(contractCardInfo, [
          'usageKind',
          'purchased',
        ]).sort((a, b) => {
          return a.startDate && b.startDate ? a.startDate - b.startDate : 0;
        });
        const freemium: ContractCardInfo[] = _.filter(contractCardInfo, [
          'usageKind',
          'freemium',
        ]).sort((a, b) => {
          return a.startDate && b.startDate ? a.startDate - b.startDate : 0;
        });
        const trials: ContractCardInfo[] = _.filter(
          contractCardInfo,
          (info) =>
            info.usageKind !== 'purchased' && info.usageKind !== 'freemium',
        ).sort((a, b) => {
          return a.startDate && b.startDate ? a.startDate - b.startDate : 0;
        });

        const contractCards: ContractCardInfo[] = [];
        contractCards.push(...purchased);
        contractCards.push(...freemium);
        contractCards.push(...trials);

        setContractCards(contractCards);
        setDialogState && setDialogState('close');
      };
      try {
        timeout(getPaymentMethod());
      } catch {
        setDialogState && setDialogState('error');
      }
    }
  }, [
    customerId,
    contractAPIBaseUrl,
    getAccessTokenSilently,
    paymentGatewayAPIBaseUrl,
    setDialogState,
    tenantManagementAPIBaseUrl,
  ]);

  return (
    <>
      <Box className={classes.root}>
        {contractCards.map((contract, i) => (
          <ContractCard
            className={classes.card}
            applicationControlAPIBaseUrl={applicationControlAPIBaseUrl}
            paymentGatewayAPIBaseUrl={paymentGatewayAPIBaseUrl}
            iconSrc={contract.iconSrc}
            title={contract.contractTitle}
            licenseNumber={contract.licenseNumber}
            paymentMethod={contract.paymentMethod}
            nextChargeTime={contract.nextChargeTime}
            serviceId={contract.serviceId}
            tenantId={contract.tenantId}
            customerId={customerId}
            userId={userId}
            key={`contract-${i}`}
            usageKind={contract.usageKind}
          />
        ))}
      </Box>
    </>
  );
};
