import React from 'react';
import * as _ from 'lodash';
import { FormControl, OutlinedInputProps } from '@material-ui/core';

import {
  StyledInputLabel,
  StyledOutlinedInput,
  StyledHelperText,
  StyledDescription,
} from '@bizapp-frontend/customer/molecules/form/FormComponent';

export interface FormInputProps extends OutlinedInputProps {
  description?: React.ReactNode;
  helperText?: React.ReactNode;
  warning?: boolean;
  setValue?: (value: string | ((prevVar: string) => string)) => void;
  setIsValid?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  validator?: FormInputValidator;
  forceValidation?: boolean;
  setForceValidation?: (
    value: boolean | ((prevVar: boolean) => boolean),
  ) => void;
  converter?: FormInputConverter;
  postChange?: (value: string) => void;
  postBlur?: (value: string) => void;
}

export const FormInput: React.FC<FormInputProps> = ({
  className = '',
  id,
  label,
  required = false,
  disabled = false,
  hidden = false,
  warning = false,
  description,
  defaultValue,
  helperText,
  setValue,
  setIsValid,
  validator,
  forceValidation,
  setForceValidation,
  converter,
  onChange,
  postChange,
  onBlur,
  postBlur,
  ...props
}) => {
  const [checkedDefaultValue, setCheckedDefaultValue] = React.useState(false);
  const [error, setError] = React.useState(props.error ?? false);
  const [helper, setHelper] = React.useState(helperText ?? '');
  const [validate, setValidate] = React.useState(() => (value: string) => true);

  const handleValidate = React.useCallback(
    (value: string) => {
      if (
        (required && validate(value)) ||
        (!required && _.isEmpty(value)) ||
        (!required && !_.isEmpty(value) && validate(value))
      ) {
        setError(false);
        setIsValid && setIsValid(true);
      } else {
        setError(true);
        setIsValid && setIsValid(false);
      }
    },
    [required, setIsValid, validate],
  );

  const handleChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onChange && onChange(ev);
    setValue && setValue(ev.target.value);
    postChange && postChange(ev.target.value);
  };

  const handleBlur = (
    ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onBlur && onBlur(ev);
    let newValue = (props.value as string) || '';
    if (!_.isUndefined(converter)) {
      newValue = converter(newValue);
      setValue && setValue(newValue);
    }
    handleValidate(newValue);
    postBlur && postBlur(newValue);
  };

  React.useEffect(() => {
    if (validator) {
      setValidate(() => validator.validate);
    } else if (required) {
      setValidate(() => requiredFormInputValidator.validate);
    }
  }, [required, validator]);

  React.useEffect(() => {
    if (helperText) {
      setHelper(helperText);
    } else if (validator) {
      setHelper(validator.helperText);
    } else if (required) {
      setHelper(requiredFormInputValidator.helperText);
    }
  }, [helperText, required, validator]);

  React.useEffect(() => {
    if (forceValidation) {
      handleValidate((props.value as string) ?? '');
      setForceValidation && setForceValidation(false);
    }
  }, [props.value, forceValidation, handleValidate, setForceValidation]);

  React.useEffect(() => {
    if (!checkedDefaultValue) {
      setCheckedDefaultValue(true);
      defaultValue && setValue && setValue(defaultValue as string);
      handleValidate(defaultValue as string);
    }
  }, [defaultValue, setValue, handleValidate, checkedDefaultValue]);

  return (
    <>
      {hidden || (
        <FormControl
          required={required}
          error={props.error || error}
          disabled={disabled}
          className={className}
        >
          <StyledInputLabel htmlFor={id}>{label}</StyledInputLabel>
          <StyledOutlinedInput
            {...props}
            id={id}
            warning={warning}
            error={props.error || error}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {helper && (props.error || error || warning) && (
            <StyledHelperText children={helper} warning={warning} />
          )}
          {description && <StyledDescription children={description} />}
        </FormControl>
      )}
    </>
  );
};

export interface FormInputValidator {
  helperText: string;
  validate: (value: string) => boolean;
}

export const requiredFormInputValidator: FormInputValidator = {
  helperText: '入力必須項目です。',
  validate: (value: string) => !_.isEmpty(value),
};

export const telFormInputValidator: FormInputValidator = {
  helperText: '電話番号をハイフンなしで入力してください。',
  validate: (value: string) =>
    value.match(new RegExp(/^[\d０-９]{9,11}$/)) !== null,
};

export const emailFormInputValidator: FormInputValidator = {
  helperText: '正規のメールアドレスを入力してください。',
  validate: (value: string) => value.match(new RegExp(/^.+@.+$/)) !== null,
};

export const positiveRangeIntegerFormInputValidatorCreator = (
  minValue: number,
  maxValue: number,
): FormInputValidator => {
  return {
    helperText: `${minValue}以上、${maxValue}以下の数字を入力してください。`,
    validate: (value: string) => {
      if (value.match(new RegExp(/^[1-9][\d]*$/)) === null) {
        return false;
      }

      const v = parseInt(value, 10);
      if (v < minValue || maxValue < v) {
        return false;
      }
      return true;
    },
  };
};

export const positiveIntegerFormInputValidator: FormInputValidator = {
  helperText: '1以上の数字を入力してください。',
  validate: (value: string) => value.match(new RegExp(/^[1-9][\d]*$/)) !== null,
};

export const passwordFormInputValidator: FormInputValidator = {
  helperText: 'パスワードが条件を満たしていません。',
  validate: (value: string) =>
    value.match(new RegExp(/^[a-zA-Z0-9!@#$%^&*]{8,}$/)) !== null &&
    value.match(new RegExp(/[a-z]/)) !== null &&
    value.match(new RegExp(/[A-Z]/)) !== null &&
    value.match(new RegExp(/[0-9]/)) !== null,
};

export type FormInputConverter = (value: string) => string;

export const fullWidthToHalfWidthConverter: FormInputConverter = (
  value: string,
) => {
  const convertionMap = new Map([
    ['＿', '_'],
    ['＠', '@'],
    ['ー', '-'],
    ['―', '-'],
    ['‐', '-'],
    ['‑', '-'],
    ['–', '-'],
    ['—', '-'],
    ['−', '-'],
    ['ｰ', '-'],
    ['－', '-'],
  ]);

  return value
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    })
    .replace(
      new RegExp(
        String.raw`(${Array.from(convertionMap.keys()).join('|')})`,
        'g',
      ),
      (match) => convertionMap.get(match) ?? match,
    );
};
