import React from 'react';
import { CircularProgress, Theme, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    headingBox: {
      padding: theme.spacing(6, 0, 3),
      textAlign: 'center',
      '& .MuiTypography-h2': {
        marginBottom: '27px',
        color: '#4285F4',
        fontSize: '24px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-h3': {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
        fontSize: '20px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        '& span': { display: 'block' },
      },
    },
    headingTitleText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'bold',
      color: '#333333',
    },
    headingDescriptionText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
      color: '#333333',
    },
    spinnerWrapper: {
      height: '168px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    spinner: {
      height: '40px',
      width: '40px',

      color: '#4285F4',
    },
  }),
);

export interface RepurchaseApplicationStep2Props {
  className?: string;
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
}

export const RepurchaseApplicationStep2: React.FC<RepurchaseApplicationStep2Props> = ({
  className,
  children,
  dialogState,
  onClickDialogClose,
}) => {
  const classes = useStyle();
  return (
    <div className={`${classes.root} ${className || ''}`}>
      <div className={classes.headingBox}>
        <Typography variant="h2">{'決済処理を完了しています…'}</Typography>
        <div className={classes.spinnerWrapper}>
          <CircularProgress className={classes.spinner} />
        </div>
        <Typography>
          <span>
            {'完了までに数秒かかりますが、自動的にページ移動します。'}
          </span>
          <span>{'画面は閉じずに、このままお待ちください。'}</span>
        </Typography>
        {children}
      </div>
      <ProcessingDialog
        state={dialogState ?? 'close'}
        onClickError={onClickDialogClose}
      />
    </div>
  );
};
