import React from 'react';
import { Link, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useAuth0 } from '@auth0/auth0-react';

import { IncreaseLicenseApplicationContext } from '@bizapp-frontend/customer/templates/form/IncreaseLicenseApplication';
import {
  IncreaseLicenseApplicationStep2,
  IncreaseLicenseApplicationStep2Props,
  PaymentInfo,
} from '@bizapp-frontend/customer/templates/form/IncreaseLicenseApplicationStep2';
import { IncreaseLicenseApplicationCommonProps } from '@bizapp-frontend/customer/pages/form/pjb/IncreaseLicenseApplication';
import { v4 as uuid } from 'uuid';

import {
  getCookie,
  timeout,
  toNextMonthDayStr,
} from '@bizapp-frontend/customer/pages/util';
import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';
import { PaymentMethod } from '@bizapp-frontend/customer/organisms/ContractCard';
import { FormButton } from '@bizapp-frontend/customer/molecules/form/FormButton';
import { PriceInfo } from '@bizapp-frontend/customer/organisms/form/PriceTable';
import { useNavigate } from 'react-router-dom';

export interface IncreaseLicenseFormStep2Props
  extends IncreaseLicenseApplicationStep2Props,
    IncreaseLicenseApplicationCommonProps {
  applicationControlAPIBaseUrl: string;
  paymentGatewayAPIBaseUrl: string;
  contractAPIBaseUrl: string;
}

interface Step1SessionData {
  priceInfo?: PriceInfo;
  paymentMethod?: PaymentMethod;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    submitButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(5),
      '& > button': {
        width: '100%',
        height: '48px',
        maxWidth: '400px',
      },
      '& span': {
        display: 'block',
      },
      '& .MuiButton-label': {
        fontSize: '16px',
      },
    },
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(3, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
    paymentInfoTip: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
    paymentButtonTip: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: theme.spacing(2, 0, 3),
    },
  }),
);

interface PaymentMethodResp {
  paymentGatewayId: 'stripe' | 'mfkessai';
  stripe?: {
    last4: string;
    exp_year: number;
    exp_month: number;
    funding: string;
    country: string;
    brand: string;
    payment_customer_id: string;
  };
  mfkessai?: {
    issueDate: string;
  };
}

export const IncreaseLicenseFormStep2: React.FC<IncreaseLicenseFormStep2Props> = ({
  applicationControlAPIBaseUrl,
  paymentGatewayAPIBaseUrl,
  contractAPIBaseUrl,
  tenantId,
  serviceId,
  customerId,
  userId,
  emailAddress,
  ...props
}) => {
  const increaseLicenseDraft = 'increase-license-step1';
  const navigate = useNavigate();
  const classes = useStyle();

  const [submitForm, setSubmitForm] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [sessionData, setSessionData] = React.useState<Step1SessionData>({});
  const { getAccessTokenSilently } = useAuth0();
  const { step, setStep, paymentKind } = React.useContext(
    IncreaseLicenseApplicationContext,
  );
  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );
  const handleDialogClick = () => {
    setDialogState('close');
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [hasSubmittedDraft, setHasSubmittedDraft] = React.useState(false);
  const [draftId, setDraftId] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    if (customerId) {
      const getDraft = async (draftId: string) => {
        const method = 'GET';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/customers/${customerId}/drafts/${draftId}?draft-type=${increaseLicenseDraft}`;
        const accessToken = await getAccessTokenSilently();
        const headers = {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        };
        const resp = await fetch(url, {
          method: method,
          headers: headers,
        });

        if (resp.status === 200) {
          return Promise.resolve(resp.json());
        } else {
          return Promise.reject(
            new Error(
              `Failed in getting step 1 session data. status is ${resp.status}`,
            ),
          );
        }
      };
      const f = async () => {
        try {
          setDialogState('wait');
          const cookieDraftId = getCookie(
            `Draft-${increaseLicenseDraft}-${tenantId}`,
          );
          if (cookieDraftId) {
            const step1Draft = await timeout(getDraft(cookieDraftId));
            setDraftId(cookieDraftId);
            if (step1Draft.submitted) {
              setSessionData({});
              setHasSubmittedDraft(true);
              alert('有料プランご登録済です。');
            } else {
              setSessionData(JSON.parse(step1Draft.jsonData));
            }
            setDialogState('close');
          } else {
            console.error('No DraftId in cookie');
            setDialogState('error');
          }
        } catch (err) {
          setDisableSubmit(false);
          console.error('Get session data failed');
          setDialogState('error');
        }
      };
      f();
    }
  }, [
    applicationControlAPIBaseUrl,
    customerId,
    getAccessTokenSilently,
    tenantId,
  ]);

  const handleClick = React.useCallback(() => {
    // disable button to prevent multiple-submit
    setDisableSubmit(true);
    setSubmitForm(true);
  }, []);

  const [paymentInfo, setPaymentInfo] = React.useState<PaymentInfo>({
    paymentMethodDescription: '---',
    price: '---',
  });

  React.useEffect(() => {
    if (
      submitForm &&
      draftId &&
      sessionData.priceInfo != null &&
      paymentInfo.paymentGatewayId
    ) {
      setSubmitForm(false);
      const priceInfo = sessionData.priceInfo;
      const price = +sessionData.priceInfo.discountedPrice?.priceIncludingTax;
      const localSessionDataKey =
        'BIZAPP_CUSOMER_INCREASE_LICENSE_SESSION_DATA_' + uuid();

      const genCustomerRegisterCardUrl = async () => {
        const method = 'POST';
        const url = `${paymentGatewayAPIBaseUrl}/api/payment-gateway/${paymentInfo.paymentGatewayId}/customers`;
        const accessToken = await getAccessTokenSilently();
        window.localStorage.setItem(
          localSessionDataKey,
          JSON.stringify({ tenantId, serviceId }),
        );

        const headers = {
          'Content-Type': 'application/json',
          authorization: `Bearer ${accessToken}`,
        };
        const baseUrl = `${window.location.protocol}//${window.location.host}/application/pjb/increase-license`;
        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify({
            customer: {
              pic_email: emailAddress,
              customer_id: customerId,
            },
            success_url: `${baseUrl}/success?sessionId={CHECKOUT_SESSION_ID}&sessionDataKey=${localSessionDataKey}`,
            cancel_url: `${baseUrl}/cancel?sessionDataKey=${localSessionDataKey}`,
            price: price,
            paymentGatewayId: paymentInfo.paymentGatewayId,
            tenantId: tenantId,
            applicationDraftId: draftId,
            description: `Project Board（ライセンス数: ${priceInfo.licenseNumber}）`,
            metadata: {
              userId: userId,
              customerId: customerId,
              applicationDraftId: draftId,
              applicationDraftType: increaseLicenseDraft,
            },
          }),
        });
        return resp.status === 201
          ? Promise.resolve(resp.json())
          : Promise.reject(
              new Error(
                `Failed in getting stripe register url of customer. status is ${resp.status}`,
              ),
            );
      };
      const setDraftSubmitted = async (draftId: string) => {
        const method = 'POST';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/draft/customers/${customerId}/drafts/${draftId}/submitted`;
        const accessToken = await getAccessTokenSilently();
        window.localStorage.setItem(
          localSessionDataKey,
          JSON.stringify({ tenantId, serviceId }),
        );
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const _body = {
          applicationDraftType: 'increase-license-step1',
          paymentTiming:
            paymentInfo?.paymentGatewayId === 'stripe' ? 'immediate' : 'later',
          paymentSessionId: 'none',
          paymentGatewayId: paymentInfo?.paymentGatewayId,
        };

        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        return resp.status === 201
          ? Promise.resolve(true)
          : Promise.reject(
              new Error(
                `Failed in set draft submitted. status is ${resp.status}`,
              ),
            );
      };

      const f = async () => {
        try {
          setDialogState('wait');
          if (price > 0 && paymentInfo?.paymentGatewayId === 'stripe') {
            const registerCardUrlInfo = await timeout(
              genCustomerRegisterCardUrl(),
            );
            window.location.href = registerCardUrlInfo.session_url;
          } else if (
            price === 0 ||
            paymentInfo?.paymentGatewayId === 'mfkessai'
          ) {
            await setDraftSubmitted(draftId);
            navigate(
              `/application/pjb/increase-license/success?sessionDataKey=${localSessionDataKey}`,
            );
          }
          setDialogState('close');
        } catch (err) {
          setDisableSubmit(false);
          console.error('Get redirect url failed');
          setDialogState('error');
        }
      };
      f();
    } else {
      setSubmitForm(false);
      setDisableSubmit(false);
    }
  }, [
    applicationControlAPIBaseUrl,
    customerId,
    draftId,
    emailAddress,
    getAccessTokenSilently,
    navigate,
    paymentGatewayAPIBaseUrl,
    paymentInfo.paymentGatewayId,
    serviceId,
    sessionData.priceInfo,
    submitForm,
    tenantId,
    userId,
  ]);

  React.useEffect(() => {
    const priceNumber =
      sessionData?.priceInfo?.discountedPrice?.priceIncludingTax;
    if (priceNumber && paymentKind.paymentGatewayId !== 'none') {
      let paymentMethodDescription = '---';
      let issueDate = '---';
      const price = Number(priceNumber).toLocaleString() + ' 円';
      if (paymentKind.paymentGatewayId === 'mfkessai') {
        issueDate = paymentKind.issueDate
          ? toNextMonthDayStr(paymentKind.issueDate, Date.now())
          : '---';
        paymentMethodDescription = '請求書払い';
      }
      setPaymentInfo({
        paymentGatewayId: paymentKind.paymentGatewayId,
        paymentMethodDescription: paymentMethodDescription,
        price: price,
        issueDate: issueDate,
      });
    }
  }, [paymentKind, sessionData?.priceInfo?.discountedPrice?.priceIncludingTax]);

  React.useEffect(() => {
    if (
      customerId &&
      paymentKind.paymentGatewayId !== 'none' &&
      paymentKind.paymentGatewayId !== 'mfkessai'
    ) {
      const getPaymentMethod = async (): Promise<PaymentMethodResp> => {
        const accessToken = await getAccessTokenSilently();

        const paymentResp = await fetch(
          `${paymentGatewayAPIBaseUrl}/api/payment-gateway/${paymentKind.paymentGatewayId}/customers/${customerId}/card`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        return paymentResp.ok
          ? Promise.resolve(
              paymentResp.json().then(
                (card) =>
                  ({
                    paymentGatewayId: 'stripe',
                    stripe: card,
                  } as PaymentMethodResp),
              ),
            )
          : Promise.reject(
              new Error(
                `Failed in get card info. status is ${paymentResp.status}`,
              ),
            );
      };

      const f = async () => {
        try {
          setDialogState && setDialogState('wait');

          const paymentMethod = await getPaymentMethod();
          const paymentMethodDescription = `${paymentMethod.stripe?.brand} (****${paymentMethod.stripe?.last4})`;
          setPaymentInfo((prev) => ({
            ...prev,
            paymentMethodDescription: paymentMethodDescription,
          }));
          setDialogState && setDialogState('close');
        } catch (err) {
          setDialogState && setDialogState('error');
        }
      };
      f();
    }
  }, [
    contractAPIBaseUrl,
    customerId,
    getAccessTokenSilently,
    paymentGatewayAPIBaseUrl,
    paymentKind,
    sessionData?.priceInfo?.discountedPrice?.priceIncludingTax,
  ]);

  const cardTitle = React.useMemo(() => {
    switch (paymentKind.paymentGatewayId) {
      case 'stripe':
        return 'ご登録済みのクレジットカードにて決済を実行します。';
      case 'mfkessai':
        return 'お申込み内容をご確認ください。';
      default:
        return '';
    }
  }, [paymentKind.paymentGatewayId]);

  const tip1 = React.useMemo(() => {
    switch (paymentKind.paymentGatewayId) {
      case 'stripe':
        return 'お支払いクレジットカードを変更する場合は、次の決済画面で変更できます。';
      case 'mfkessai':
        return '他のお申し込みがあった場合、合算した料金を請求させていただきます。';
      default:
        return '';
    }
  }, [paymentKind.paymentGatewayId]);

  const tip2 = React.useMemo(() => {
    switch (paymentKind.paymentGatewayId) {
      case 'mfkessai':
        return '購入後、ライセンス追加処理が開始されます。';
      default:
        return '';
    }
  }, [paymentKind.paymentGatewayId]);

  const submitBtnLabel = React.useMemo(() => {
    switch (paymentKind.paymentGatewayId) {
      case 'stripe':
        return '決済画面を開く';
      case 'mfkessai':
        return '購入する';
      default:
        return '';
    }
  }, [paymentKind.paymentGatewayId]);

  return (
    <>
      <IncreaseLicenseApplicationStep2
        {...props}
        cardTitle={cardTitle}
        paymentInfo={paymentInfo}
        iconImgSrc={`${process.env.PUBLIC_URL}/images/icon-project-board@2x.png`}
        iconImgAlt="Project Board Logo"
        colors={{
          firstThemeColor: '#6A8AFF',
          secondThemeColor: '#6AA4FF',
        }}
        dialogState={dialogState}
        onClickDialogClose={handleDialogClick}
      >
        <div className={classes.paymentInfoTip}>
          <Typography>{tip1}</Typography>
        </div>
        <FormButton
          id="increase-license-purchase-btn"
          label={submitBtnLabel}
          className={classes.submitButton}
          disabled={disableSubmit || hasSubmittedDraft}
          postClick={handleClick}
        />
        {tip2 && (
          <div className={classes.paymentButtonTip}>
            <Typography>{tip2}</Typography>
          </div>
        )}
        <Link
          onClick={() => {
            setStep(step - 1, true);
          }}
          className={classes.backlink}
        >
          {'Step 1 へ戻る'}
        </Link>
      </IncreaseLicenseApplicationStep2>
    </>
  );
};
