import React from 'react';
import { Box, Dialog, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  StyledPaper,
  StyledPaperProps,
} from '@bizapp-frontend/customer/molecules/StyledPaper';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import { useNavigate } from 'react-router-dom';

export interface PurchaseApplicationSelectionDialogProps
  extends StyledPaperProps {
  open: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  serviceId?: string;
  tenantId?: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      width: '424px',
    },
    title: {
      fontSize: '16px',
      lineHeight: '24px',
      textAlign: 'center',
    },
    iconBox: {
      textAlign: 'center',
      marginBottom: '32px',
      '& > *': {
        height: '64px',
        width: '64px',
      },
    },
    option: {
      display: 'flex',
      borderRadius: '5px',
      margin: theme.spacing(1, 0),
      padding: '24px 25px',
      border: '1px solid #DCE0E7',
      cursor: 'pointer',
      '&:hover': { borderColor: '#4285F4' },
      '&:hover .MuiSvgIcon-root': { color: '#4285F4' },
      '&:hover .MuiTypography-root': { color: '#4285F4' },
    },
    optionIcon: {
      fontSize: '45px',
    },
    optionContent: {
      marginLeft: '18px',
    },
    optionTitle: {
      fontSize: '20px',
      lineHeight: '30px',
      // color: '#333333',
      fontWeight: 'bold',
    },
    optionSubtitle: {
      fontSize: '14px',
      lineHeight: '21px',
      color: '#7F7F7F',
    },
  }),
);

export const PurchaseApplicationSelectionDialog: React.FC<PurchaseApplicationSelectionDialogProps> = ({
  open,
  onClose = () => {},
  iconImgSrc,
  iconImgAlt,
  colors,
  serviceId,
  tenantId,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const toPersonalPurchase = () => {
    navigate(
      `/application/${serviceId}/purchase?serviceId=${serviceId}&tenantId=${tenantId}&customerKind=personal`,
    );
  };

  const toCompanyPurchase = () => {
    navigate(
      `/application/${serviceId}/purchase?serviceId=${serviceId}&tenantId=${tenantId}&customerKind=corporate`,
    );
  };

  const toCompanyContact = () => {
    const inquiryType = 'ご契約に関する問い合わせ';
    const inquiryDetail =
      '請求書払いによる有料プラン登録をご希望の方は、 以下フォーマットにご記入の上、送信してください。\n' +
      '--------\n' +
      '・お申し込みライセンス数：\n' +
      '・会社・団体名：\n' +
      '・会社・団体名（フリガナ）：';
    navigate(
      `/contact?tenantId=${tenantId}&serviceId=${serviceId}&type=${inquiryType}&detail=${encodeURIComponent(
        inquiryDetail,
      )}`,
    );
  };
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <StyledPaper
          className={classes.content}
          iconImgSrc={iconImgSrc}
          iconImgAlt={iconImgAlt}
          colors={colors}
        >
          <Typography className={classes.title}>
            お申し込み区分を選択してください。
          </Typography>
          <Box flexDirection="column">
            <Box
              className={classes.option}
              flexDirection="row"
              onClick={toPersonalPurchase}
            >
              <PersonIcon className={classes.optionIcon} />
              <Box className={classes.optionContent}>
                <Typography className={classes.optionTitle}>
                  個人のお客様
                </Typography>
                <Typography className={classes.optionSubtitle}>
                  クレジットカードでのお支払い
                </Typography>
              </Box>
            </Box>
            <Box
              className={classes.option}
              flexDirection="row"
              onClick={toCompanyPurchase}
            >
              <BusinessIcon className={classes.optionIcon} />
              <Box className={classes.optionContent}>
                <Typography className={classes.optionTitle}>
                  法人のお客様
                </Typography>
                <Typography className={classes.optionSubtitle}>
                  クレジットカードでのお支払い
                </Typography>
              </Box>
            </Box>
            <Box
              className={classes.option}
              flexDirection="row"
              onClick={toCompanyContact}
            >
              <BusinessIcon className={classes.optionIcon} />
              <Box className={classes.optionContent}>
                <Typography className={classes.optionTitle}>
                  法人のお客様
                </Typography>
                <Typography className={classes.optionSubtitle}>
                  請求書でのお支払い
                </Typography>
              </Box>
            </Box>
          </Box>
        </StyledPaper>
      </Dialog>
    </>
  );
};
