import React from 'react';
import { Box, IconButton, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

interface SimplePaginationProps {
  page: number;
  count: number;
  onChange: (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number,
  ) => void;
  onClick: React.MouseEventHandler<HTMLElement>;
  tabIndex?: string | number | undefined;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'center',
      height: '32px',
    },
    button: {
      color: '#1565C0',
      width: '32px',
      height: '32px',
    },
    pagetext: {
      color: '#333333',
      fontSize: '14px',
      lineHeight: '21px',
    },
  }),
);

export const SimplePagination: React.FC<SimplePaginationProps> = ({
  className,
  page,
  count,
  onChange,
  onClick,
  tabIndex,
}: SimplePaginationProps) => {
  const classes = useStyles();

  const handleClickBefore = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onChange(ev, page - 1);
  };
  const handleClickNext = (
    ev: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    onChange(ev, page + 1);
  };

  return (
    <Box onClick={onClick} className={`${classes.root} ${className ?? ''}`}>
      <IconButton
        onClick={handleClickBefore}
        disabled={page === 1}
        tabIndex={tabIndex}
        className={classes.button}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <Typography className={classes.pagetext}>
        {page}
        {'/'}
        {count}
      </Typography>
      <IconButton
        onClick={handleClickNext}
        disabled={page === count}
        tabIndex={tabIndex}
        className={classes.button}
      >
        <NavigateNextIcon />
      </IconButton>
    </Box>
  );
};
