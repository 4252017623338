import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    headingBox: {
      padding: theme.spacing(6, 0, 0),
      textAlign: 'center',
      '& .MuiTypography-h2': {
        marginBottom: '27px',
        color: '#4285F4',
        fontSize: '24px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-h3': {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
        fontSize: '20px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        '& span': { display: 'block' },
      },
      '& svg': {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
      },
    },
    headingTitleText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'bold',
      color: '#333333',
    },
    headingDescriptionText: {
      margin: theme.spacing(3.5, 0, 5),
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
      color: '#333333',
      '& > p': {
        margin: theme.spacing(1, 0, 0),
      },
      '& a': {
        color: '#1565C0',
      },
    },
  }),
);

export interface IncreaseLicenseApplicationStep3Props {
  className?: string;
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
}

export const IncreaseLicenseApplicationStep3: React.FC<IncreaseLicenseApplicationStep3Props> = ({
  className,
  children,
  dialogState,
  onClickDialogClose,
}) => {
  const classes = useStyle();
  return (
    <div className={`${classes.root} ${className || ''}`}>
      <div className={classes.headingBox}>
        <Typography variant="h2">お申し込みが完了しました</Typography>
        <div className={classes.headingDescriptionText}>
          <Typography>
            <span>お申し込みいただき、ありがとうございます。</span>
          </Typography>
          <Typography>
            <span>ただいま、ご利用環境のデータの更新を開始しました。</span>
            <span>
              完了次第メールにてご案内いたしますので、しばらくお待ちください。
            </span>
          </Typography>
        </div>
        {children}
      </div>
      <ProcessingDialog
        state={dialogState ?? 'close'}
        onClickError={onClickDialogClose}
      />
    </div>
  );
};
