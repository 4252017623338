import React from 'react';
import { PageTemplate } from '@bizapp-frontend/customer/templates/PageTemplate';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import {
  StyledPaper,
  StyledPaperProps,
} from '@bizapp-frontend/customer/molecules/StyledPaper';
import {
  FormGenerator,
  FormGeneratorProps,
} from '@bizapp-frontend/customer/organisms/form/FormGenerator';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'block',
      margin: theme.spacing(0, 'auto'),
      backgroundColor: '#F2f3fb',
      padding: theme.spacing(5, 2),
      [theme.breakpoints.up(768)]: {
        padding: theme.spacing(5, 4),
      },
    },
    header: {
      color: '#333333',
      '& .MuiTypography-h1': {
        fontSize: '32px',
        lineHeight: '48px',
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.down(768)]: {
          fontSize: '24px',
          lineHeight: '36px',
        },
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 'normal',
        textAlign: 'center',
        margin: '16px auto 32px',
      },
    },
    contentsRoot: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '464px',
      margin: theme.spacing(0, 'auto'),
    },
    description: {
      margin: theme.spacing(3, 0),
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
      color: '#333333',
      '& span': {
        display: 'block',
      },
    },
  }),
);

export interface ContactProps extends StyledPaperProps, FormGeneratorProps {
  grecaptchaKey: string;
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
}

export const Contact: React.FC<ContactProps> = ({
  className,
  iconImgSrc,
  iconImgAlt,
  colors,
  formComponents,
  grecaptchaKey,
  children,
  dialogState,
  onClickDialogClose,
  ...props
}) => {
  const classes = useStyles();

  return (
    <GoogleReCaptchaProvider reCaptchaKey={grecaptchaKey}>
      <PageTemplate>
        <div className={classes.root}>
          <header className={classes.header}>
            <Typography variant="h1">お問い合わせ</Typography>
          </header>
          <div className={classes.contentsRoot}>
            <Typography component="p" className={classes.description}>
              <span>
                お問い合わせの内容を、できるだけ詳細にご入力ください。
              </span>
              <span>内容を確認後、弊社担当よりお答えいたします。</span>
            </Typography>
            <StyledPaper
              iconImgSrc={iconImgSrc}
              iconImgAlt={iconImgAlt}
              colors={colors}
            >
              <div>
                <FormGenerator {...props} formComponents={formComponents} />
              </div>
              {children}
            </StyledPaper>
          </div>
        </div>
        <ProcessingDialog
          state={dialogState ?? 'close'}
          onClickError={onClickDialogClose}
        />
      </PageTemplate>
    </GoogleReCaptchaProvider>
  );
};
