import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface IncreaseLicenseApplicationCancelProps {}

interface LocalSessionData {
  tenantId?: string;
  serviceId?: string;
}

export const IncreaseLicenseApplicationCancel: React.FC<IncreaseLicenseApplicationCancelProps> = () => {
  const query = new URLSearchParams(useLocation().search);
  const localSessionDataKey = query.get('sessionDataKey') ?? undefined;
  const navigate = useNavigate();

  React.useEffect(() => {
    const getLocalSessionData = (localSessionDataKey: string) => {
      return JSON.parse(
        window.localStorage.getItem(localSessionDataKey) ?? '{}',
      );
    };

    if (localSessionDataKey) {
      const sessionData: LocalSessionData = getLocalSessionData(
        localSessionDataKey,
      );
      if (sessionData.tenantId && sessionData.serviceId) {
        window.localStorage.removeItem(localSessionDataKey);
        navigate(
          `/application/pjb/increase-license?step=1&serviceId=${sessionData.serviceId}&tenantId=${sessionData.tenantId}`,
        );
      } else {
        navigate('/');
      }
    }
  }, [localSessionDataKey, navigate]);

  return <></>;
};
