import React from 'react';
import { Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

import {
  PurchaseApplicationStep3,
  PurchaseApplicationStep3Props,
} from '@bizapp-frontend/customer/templates/form/PurchaseApplicationStep3';
import { PurchaseApplicationCommonProps } from '@bizapp-frontend/customer/pages/form/pjb/PurchaseApplication';

export interface PurchaseFormStep3Props
  extends PurchaseApplicationStep3Props,
    PurchaseApplicationCommonProps {
  applicationControlAPIBaseUrl: string;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(5, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
  }),
);

export const PurchaseFormStep3: React.FC<PurchaseFormStep3Props> = () => {
  const navigate = useNavigate();
  const classes = useStyle();

  return (
    <>
      <PurchaseApplicationStep3 />
      <Link
        onClick={() => {
          navigate('/');
        }}
        className={classes.backlink}
      >
        {'ユーザーページへ戻る'}
      </Link>
    </>
  );
};
