import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  StyledPaper,
  StyledPaperProps,
} from '@bizapp-frontend/customer/molecules/StyledPaper';
import {
  FormComponentProps,
  FormGenerator,
  FormGeneratorProps,
} from '@bizapp-frontend/customer/organisms/form/FormGenerator';
import {
  PriceInfo,
  PriceTable,
  PriceTableProps,
} from '@bizapp-frontend/customer/organisms/form/PriceTable';
import {
  ProcessingDialog,
  ProcessingDialogConfig,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';
import { ConfirmationDialog } from '@bizapp-frontend/customer/organisms/ConfirmationDialog';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    headingBox: {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
    },
    headingTitleText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'bold',
      color: '#333333',
    },
    headingDescriptionText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
      color: '#333333',
    },
    firstSection: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      flexDirection: 'row',
    },
    firstSectionColumn: {
      display: 'flex',
      flexDirection: 'column',
      flexBasis: '100%',
      [theme.breakpoints.up('md')]: {
        flexBasis: '50%',
      },
      margin: theme.spacing(2, 0),
    },
  }),
);

export interface PurchaseApplicationStep1Props
  extends StyledPaperProps,
    Omit<FormGeneratorProps, 'formComponents'> {
  baseUrl?: string;
  className?: string;
  formComponentsPart1?: FormComponentProps[][];
  formComponentsPart2?: FormComponentProps[][];
  priceTable?: PriceTableProps;
  priceInfo?: PriceInfo;
  dialogConfig?: ProcessingDialogConfig;
  onClickDialogClose?: () => void;
  showConfirmationDialog?: boolean;
  setShowConfirmationDialog?: () => void;
  onLoadSessionData?: (load: boolean) => void;
}

export const PurchaseApplicationStep1: React.FC<PurchaseApplicationStep1Props> = ({
  baseUrl,
  className,
  iconImgSrc,
  iconImgAlt,
  colors,
  priceInfo,
  formComponentsPart1,
  formComponentsPart2,
  children,
  dialogConfig,
  onClickDialogClose,
  showConfirmationDialog,
  setShowConfirmationDialog,
  onLoadSessionData,
  ...props
}) => {
  const classes = useStyle();
  return (
    <div className={`${classes.root} ${className || ''}`}>
      <div className={classes.headingBox}>
        <Typography component="h2" className={classes.headingTitleText}>
          {'Step 1'}
        </Typography>
        <Typography component="p" className={classes.headingDescriptionText}>
          サービスお申込み内容を入力してください。
        </Typography>
      </div>
      <StyledPaper
        iconImgSrc={iconImgSrc}
        iconImgAlt={iconImgAlt}
        colors={colors}
      >
        <div className={classes.firstSection}>
          <div className={classes.firstSectionColumn}>
            <FormGenerator {...props} formComponents={formComponentsPart1} />
          </div>
          <div className={classes.firstSectionColumn}>
            <PriceTable priceInfo={priceInfo} />
          </div>
        </div>
        <div>
          <FormGenerator {...props} formComponents={formComponentsPart2} />
        </div>
        {children}
        <ProcessingDialog
          state={dialogConfig?.state ?? 'close'}
          errorMessage={dialogConfig?.errorMessage}
          onClickError={onClickDialogClose}
        />
        <ConfirmationDialog
          show={showConfirmationDialog ?? false}
          description={'保存された入力内容があります。続きから編集しますか？'}
          okText={'はい'}
          cancelText={'いいえ'}
          onClickOk={() => onLoadSessionData && onLoadSessionData(true)}
          onClickCancel={() => onLoadSessionData && onLoadSessionData(false)}
        />
      </StyledPaper>
    </div>
  );
};
