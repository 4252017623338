import React from 'react';
import {
  FormHelperText,
  FormHelperTextProps,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export interface StyledInputProps extends OutlinedInputProps {
  helperText?: React.ReactNode;
  description?: React.ReactNode;
  warning?: boolean;
}

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  color: '#777777',
  fontSize: '14px',
  lineHeight: '32px',
  '&.MuiInputLabel-formControl': {
    position: 'static',
    transform: 'initial',
  },
  '&.MuiInputLabel-shrink': {
    transform: 'initial',
  },
  '& .MuiInputLabel-asterisk': {
    color: '#e91e63',
  },
  '&.Mui-focused': {
    color: '#777777',
  },
  '&.Mui-error': {
    color: '#777777',
  },
  '&.Mui-disabled': {
    color: '#777777',
  },
}));

export const StyledOutlinedInput: React.FC<StyledInputProps> = ({
  className,
  warning,
  ...props
}) => {
  const Component = React.useMemo(
    () =>
      styled(OutlinedInput)(({ theme }) => ({
        width: '100%',
        'label + &': {
          marginTop: theme.spacing(0),
          padding: theme.spacing(0),
        },
        '& :-webkit-autofill': {
          '-webkit-box-shadow': '0 0 0 1000px white inset',
        },
        '& .MuiOutlinedInput-inputMultiline': {
          padding: theme.spacing(2, 1.5),
          resize: 'vertical',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #c2cad8',
          borderRadius: '3px',
        },
        '&.Mui-error': {
          backgroundColor: '#fce8ef',
          '&.Mui-focused': {
            backgroundColor: '#ffffff',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e91e63',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#5e9bff',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#e91e63',
          },
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#c2cad8',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #5e9bff',
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderWidth: '0px',
        },
        '& input': {
          width: '100%',
          minHeight: '32px',
          boxSizing: 'border-box',
          padding: theme.spacing(0.75, 1),
          fontSize: '14px',
          '&.Mui-disabled': {
            backgroundColor: '#EDEFF3',
            color: '#333333',
            borderRadius: '3px',
          },
        },
        '&.CustomMui-warning': {
          backgroundColor: '#FDF7EC',
          '&.Mui-focused': {
            backgroundColor: '#ffffff',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F4B142 !important',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#5e9bff !important',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#F4B142 !important',
          },
        },
      })),
    [],
  );

  return (
    <Component
      className={`${warning ? 'CustomMui-warning' : ''} ${className || ''}`}
      {...props}
    />
  );
};

export interface StyledHelperTextProps extends FormHelperTextProps {
  warning?: boolean;
}

export const StyledHelperText: React.FC<StyledHelperTextProps> = ({
  className,
  warning,
  ...props
}) => {
  const Component = styled(FormHelperText)(({ theme }) => ({
    fontSize: '14px',
    color: '#e91e63',
    margin: 0,
    '&.Mui-error': {
      color: '#e91e63',
    },
    '&.CustomMui-warning': {
      color: '#F4B142 !important',
    },
  }));
  return (
    <Component
      className={`${warning ? 'CustomMui-warning' : ''} ${className || ''}`}
      {...props}
    />
  );
};

export const StyledDescription = styled('p')(({ theme }) => ({
  margin: '5px 0 0 0',
  color: '#7F7F7F',
  fontSize: '12px',
  '& span': {
    display: 'block',
  },
  '& strong': {
    fontWeight: 'bold',
  },
  '& a': {
    color: '#1565C0',
  },
}));
