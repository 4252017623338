import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  StyledPaper,
  StyledPaperProps,
} from '@bizapp-frontend/customer/molecules/StyledPaper';
import { FormGeneratorProps } from '@bizapp-frontend/customer/organisms/form/FormGenerator';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    headingBox: {
      marginBottom: theme.spacing(3),
      textAlign: 'center',
    },
    headingTitleText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'bold',
      color: '#333333',
    },
    headingDescriptionText: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
      color: '#333333',
    },
    payment: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(5),
      padding: theme.spacing(0, 0, 3),
    },
    paymentTitle: {
      fontSize: '20px',
      lineHeight: '30px',
      fontWeight: 'bold',
      color: '#333333',
    },
    paymentTable: {
      maxWidth: '400px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#DADDE0',
      borderRadius: '3px',
      borderCollapse: 'separate',
      padding: theme.spacing(1.5, 2, 3),
      '& .MuiTableCell-root': {
        border: 'none',
        padding: 0,
      },
      margin: theme.spacing(3, 2),
    },
    paymentTableTitle: {
      fontSize: '14px',
      lineHeight: '32px',
      fontWeight: 'bold',
      color: '#777777',
    },
    paymentTableValue: {
      textAlign: 'right',
      fontSize: '14px',
      lineHeight: '32px',
      fontWeight: 'bold',
      color: '#333333',
    },
    paymentButtonTip: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    },
  }),
);

export interface PaymentInfo {
  description: string;
  price: string;
}

export interface RepurchaseApplicationStep1Props
  extends StyledPaperProps,
    Omit<FormGeneratorProps, 'formComponents'> {
  baseUrl?: string;
  className?: string;
  paymentInfo?: PaymentInfo;
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
  showConfirmationDialog?: boolean;
  setShowConfirmationDialog?: () => void;
  onLoadSessionData?: (load: boolean) => void;
}

export const RepurchaseApplicationStep1: React.FC<RepurchaseApplicationStep1Props> = ({
  baseUrl,
  className,
  iconImgSrc,
  iconImgAlt,
  colors,
  paymentInfo,
  children,
  dialogState,
  onClickDialogClose,
}) => {
  const classes = useStyle();
  return (
    <div className={`${classes.root} ${className || ''}`}>
      <div className={classes.headingBox}>
        <Typography component="h2" className={classes.headingTitleText}>
          {'Step 1'}
        </Typography>
      </div>
      <StyledPaper
        iconImgSrc={iconImgSrc}
        iconImgAlt={iconImgAlt}
        colors={colors}
      >
        <div className={classes.payment}>
          <Typography component="h3" className={classes.paymentTitle}>
            お支払い情報を確認してください。
          </Typography>
          <Table className={classes.paymentTable}>
            <TableBody>
              <TableRow>
                <TableCell className={classes.paymentTableTitle}>
                  請求名目
                </TableCell>
                <TableCell className={classes.paymentTableValue}>
                  {paymentInfo?.description}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.paymentTableTitle}>
                  請求金額
                </TableCell>
                <TableCell className={classes.paymentTableValue}>
                  {paymentInfo?.price}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className={classes.paymentButtonTip}>
          <Typography>
            以下のボタンをクリックすると、外部サイト（Stripe）の決済ページが開きます。
          </Typography>
          <Typography>
            完了後、ふたたびHUE Works
            Suiteの画面へ戻ってきますので、画面上の表示に従ってください。
          </Typography>
        </div>
        {children}
        <ProcessingDialog
          state={dialogState ?? 'close'}
          onClickError={onClickDialogClose}
        />
      </StyledPaper>
    </div>
  );
};
