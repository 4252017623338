import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  PurchaseApplication as PurchaseApplicationTemplate,
  PurchaseApplicationProps as PurchaseApplicationTemplateProps,
} from '@bizapp-frontend/customer/templates/form/PurchaseApplication';

import {
  PurchaseFormStep1,
  PurchaseFormStep1Props,
} from '@bizapp-frontend/customer/pages/form/pjb/PurchaseFormStep1';
import {
  PurchaseFormStep2,
  PurchaseFormStep2Props,
} from '@bizapp-frontend/customer/pages/form/pjb/PurchaseFormStep2';
import { PurchaseFormStep3 } from '@bizapp-frontend/customer/pages/form/pjb/PurchaseFormStep3';

import { GlobalsContext } from '@bizapp-frontend/customer/globals';
import { CustomerKind } from '@bizapp-frontend/customer/pages/util';

interface PurchaseApplicationProps
  extends Omit<PurchaseApplicationTemplateProps, 'stepPages'>,
    PurchaseFormStep1Props,
    PurchaseFormStep2Props {}

export interface PurchaseApplicationCommonProps {
  // login info
  customerId?: string;
  userId?: string;
  emailAddress?: string;

  // from state
  tenantId?: string;
  serviceId?: string;
  customerKind?: CustomerKind;

  // fixed to pjb-basic
  planId?: string;
}

const PurchaseApplication: React.FC<PurchaseApplicationProps> = ({
  applicationControlAPIBaseUrl,
  zipcodeAPIBaseUrl,
  feeCalculatorAPIBaseUrl,
  paymentGatewayAPIBaseUrl,
  customerAPIBaseUrl,
  tenantManagementAPIBaseUrl,
  pjbDomainUrlSuffix,
}) => {
  const {
    state: { customerId, userId, emailAddress },
  } = React.useContext(GlobalsContext);
  const query = new URLSearchParams(useLocation().search);
  const tenantId = query.get('tenantId') ?? undefined;
  const serviceId = query.get('serviceId') ?? undefined;
  const customerKind = (query.get('customerKind') ?? 'unknown') as CustomerKind;
  const planId = 'pjb-basic';

  const navigate = useNavigate();

  if (!tenantId || !serviceId) {
    navigate('/');
  }

  return (
    <>
      <PurchaseApplicationTemplate
        stepPages={[
          <PurchaseFormStep1
            applicationControlAPIBaseUrl={applicationControlAPIBaseUrl}
            zipcodeAPIBaseUrl={zipcodeAPIBaseUrl}
            feeCalculatorAPIBaseUrl={feeCalculatorAPIBaseUrl}
            customerAPIBaseUrl={customerAPIBaseUrl}
            tenantManagementAPIBaseUrl={tenantManagementAPIBaseUrl}
            pjbDomainUrlSuffix={pjbDomainUrlSuffix}
            customerId={customerId}
            emailAddress={emailAddress}
            userId={userId}
            tenantId={tenantId}
            serviceId={serviceId}
            planId={planId}
            customerKind={customerKind}
          />,
          <PurchaseFormStep2
            applicationControlAPIBaseUrl={applicationControlAPIBaseUrl}
            paymentGatewayAPIBaseUrl={paymentGatewayAPIBaseUrl}
            customerId={customerId}
            tenantId={tenantId}
            serviceId={serviceId}
            userId={userId}
            customerKind={customerKind}
          />,
          <PurchaseFormStep3
            applicationControlAPIBaseUrl={applicationControlAPIBaseUrl}
            userId={userId}
            customerId={customerId}
            planId={planId}
            serviceId={serviceId}
            tenantId={tenantId}
          />,
        ]}
      />
    </>
  );
};

export default PurchaseApplication;
