import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getCookie } from '@bizapp-frontend/customer/pages/util';
import {
  Container,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  styled,
  Theme,
} from '@material-ui/core/styles';
import { IncreaseLicenseApplicationSuccess as IncreaseLicenseApplicationSuccessTemplate } from '@bizapp-frontend/customer/templates/form/IncreaseLicenseApplicationSuccess';
import { PageTemplate } from '@bizapp-frontend/customer/templates/PageTemplate';

export interface IncreaseLicenseApplicationSuccessProps
  extends IncreaseLicenseApplicationSuccessComponentProps {}

interface LocalSessionData {
  tenantId?: string;
  serviceId?: string;
}

const useStepperStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      backgroundColor: '#f2f3fb',
    },
    header: {
      color: '#333333',
      padding: theme.spacing(5, 2, 0),
      [theme.breakpoints.up(768)]: {
        padding: theme.spacing(5, 4, 0),
      },
      '& .MuiTypography-h1': {
        fontSize: '32px',
        lineHeight: '48px',
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.down(768)]: {
          fontSize: '24px',
          lineHeight: '36px',
        },
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 'normal',
        textAlign: 'center',
        margin: '16px auto 32px',
      },
    },

    stepper: {
      margin: theme.spacing(3, 'auto'),
      width: '264px',
      backgroundColor: 'initial',
      padding: 0,
      '& .MuiStep-horizontal': { padding: 0 },
      '& .MuiStepLabel-iconContainer': { padding: 0 },
    },
    stepDescription: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      color: '#333333',
      textAlign: 'center',
      '& .MuiTypography-subtitle1': {
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 'bold',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
        lineHeight: '21px',
      },
    },
  }),
);

const useStepIconStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      backgroundColor: '#DADDE0',
      color: '#7F7F7F',
    },
    span: {
      textAlign: 'center',
      fontSize: '12px',
    },
    active: {
      backgroundColor: '#4285F4',
      color: '#FFFFFF',
    },
    completed: {
      backgroundColor: '#FFFFFF',
      color: '#4285F4',
      width: '22px',
      height: '22px',
      border: `1px solid #4285F4`,
    },
  }),
);

const StyledConnector = styled(StepConnector)({
  line: { borderColor: '#DADDE0' },
});

const StyledStepIcon = (props: StepIconProps) => {
  const classes = useStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={`${classes.root} ${active ? classes.active : ''} ${
        completed ? classes.completed : ''
      }`}
    >
      <span className={classes.span}>{props.icon}</span>
    </div>
  );
};

export const IncreaseLicenseApplicationSuccess: React.FC<IncreaseLicenseApplicationSuccessProps> = () => {
  const classes = useStepperStyles();
  const query = new URLSearchParams(useLocation().search);
  const localSessionDataKey = query.get('sessionDataKey') ?? undefined;
  const activeStep = 1;
  return (
    <PageTemplate>
      <Container disableGutters maxWidth={false} className={`${classes.root}`}>
        <Container
          component="header"
          disableGutters
          maxWidth={false}
          className={classes.header}
        >
          <Typography variant="h1">ライセンス追加購入</Typography>
          <Stepper
            activeStep={activeStep}
            connector={<StyledConnector />}
            className={classes.stepper}
          >
            {Array(3)
              .fill(0)
              .map((_, i) => (
                <Step completed={activeStep > i} key={i}>
                  <StepLabel StepIconComponent={StyledStepIcon} />
                </Step>
              ))}
          </Stepper>
        </Container>

        <IncreaseLicenseApplicationSuccessComponent
          localSessionDataKey={localSessionDataKey}
        />
      </Container>
    </PageTemplate>
  );
};

export interface IncreaseLicenseApplicationSuccessComponentProps {
  localSessionDataKey?: string;
}

const IncreaseLicenseApplicationSuccessComponent: React.FC<IncreaseLicenseApplicationSuccessComponentProps> = ({
  localSessionDataKey,
}) => {
  const applicationDraftType = 'increase-license-step1';
  const navigate = useNavigate();

  const [tenantId, setTenantId] = React.useState('');
  const [serviceId, setServiceId] = React.useState('');
  const getLocalSessionData = (localSessionDataKey: string) => {
    const sessionData: LocalSessionData = JSON.parse(
      window.localStorage.getItem(localSessionDataKey) ?? '{}',
    );
    if (sessionData.tenantId && sessionData.serviceId) {
      setTenantId(sessionData.tenantId);
      setServiceId(sessionData.serviceId);
    }
  };

  React.useEffect(() => {
    if (localSessionDataKey) {
      getLocalSessionData(localSessionDataKey);
    }
  });

  React.useEffect(() => {
    if (localSessionDataKey && tenantId && serviceId) {
      const f = async () => {
        try {
          const cookieKey = `Draft-${applicationDraftType}-${tenantId}`;
          const draftId = getCookie(cookieKey);
          if (draftId) {
            if (localSessionDataKey) {
              window.localStorage.removeItem(localSessionDataKey);
            }
            document.cookie = `${cookieKey}=${draftId}; path=/; max-age=0`;
          }
          navigate(
            `/application/pjb/increase-license?step=2&serviceId=${serviceId}&tenantId=${tenantId}`,
          );
        } catch (e) {
          console.error(e);
        }
      };
      f();
    }
  }, [localSessionDataKey, navigate, serviceId, tenantId]);

  return (
    <>
      <IncreaseLicenseApplicationSuccessTemplate />
    </>
  );
};
