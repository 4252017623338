import React from 'react';
import { List, ListItem, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import Meta from '@bizapp-frontend/customer/organisms/Meta';
import { Complete } from '@bizapp-frontend/customer/templates/Complete';

import { GlobalsContext } from '@bizapp-frontend/customer/globals';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiListItem-root': {
        display: 'block',
        textAlign: 'center',
      },
    },
  }),
);

const PageList: React.FC = () => {
  const classes = useStyle();
  const { state, dispatch } = React.useContext(GlobalsContext);

  return (
    <>
      <Meta>
        <title>ユーザサイト 社内向けページリスト</title>
      </Meta>
      <Complete
        heading1="ユーザサイト 社内向けページリスト"
        heading2="各ページ確認用"
      >
        <Container className={classes.root}>
          <Typography variant="h3">開発社モード</Typography>
          <List>
            <ListItem>モード</ListItem>
            <ListItem>{state.development ? 'オン' : 'オフ'}</ListItem>
            <ListItem>
              <Link
                component="button"
                onClick={() =>
                  dispatch({
                    type: 'SET_DEVELOPMENT',
                    development: state.development ? false : true,
                  })
                }
              >
                {`${state.development ? 'オフ' : 'オン'}にする`}
              </Link>
            </ListItem>
          </List>

          <Typography variant="h3">情報表示</Typography>
          <List>
            <ListItem>
              <Link component={RouterLink} to="/?status=in-preparation">
                ユーザページ概要（トライアル準備中）
              </Link>
            </ListItem>
            <ListItem>
              <Link component={RouterLink} to="/?status=in-use">
                ユーザページ概要（トライアル期間中）
              </Link>
            </ListItem>
            <ListItem>
              <Link component={RouterLink} to="/?status=will-expire">
                ユーザページ概要（トライアル期間終了間近）
              </Link>
            </ListItem>
            <ListItem>
              <Link component={RouterLink} to="/?status=expired">
                ユーザページ概要（トライアル期間終了後）
              </Link>
            </ListItem>
            <ListItem>
              <Link component={RouterLink} to="/?status=purchased">
                ユーザページ概要（有料プラン契約中）
              </Link>
            </ListItem>
            <ListItem>
              <Link component={RouterLink} to="/plan-contract">
                プランとご契約内容（ご契約内容）
              </Link>
            </ListItem>
          </List>
          <Typography variant="h3">有料プラン利用登録</Typography>
          <List>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/pjb/purchase?step=0&tenantId=f9b0e587-98ac-4047-b0d6-e42cd9c3c425&serviceId=pjb&customerKind=personal"
              >
                有料プラン利用登録 Step1 (個人のお客様)
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/pjb/purchase?step=0&tenantId=f9b0e587-98ac-4047-b0d6-e42cd9c3c425&serviceId=pjb&customerKind=corporate"
              >
                有料プラン利用登録 Step1 (法人のお客様)
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/pjb/purchase?step=1&tenantId=f9b0e587-98ac-4047-b0d6-e42cd9c3c425&serviceId=pjb"
              >
                有料プラン利用登録 Step2
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/pjb/purchase?step=2&tenantId=f9b0e587-98ac-4047-b0d6-e42cd9c3c425&serviceId=pjb"
              >
                有料プラン利用登録 Step3
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/pjb/purchase/success"
              >
                有料プラン利用登録 購入完了後の処理ページ
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/repayments/2021-12/a39817e4-9b15-4380-8ee6-f510781cb5ad?step=0"
              >
                Repurchase Step1
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/pjb/unpaid?step=1"
                state={{
                  // dummy data
                  tenantId: '12ecc28c-7261-3187-c0f4-221516b3d573',
                  serviceId: 'pjb',
                }}
              >
                Repurchase Step2
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/pjb/unpaid?step=2"
                state={{
                  // dummy data
                  tenantId: '12ecc28c-7261-3187-c0f4-221516b3d573',
                  serviceId: 'pjb',
                }}
              >
                Repurchase Step3
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/pjb/increase-license?serviceId=pjb&tenantId=f9b0e587-98ac-4047-b0d6-e42cd9c3c425"
              >
                Increase License Step1
              </Link>
            </ListItem>
          </List>
          <Typography variant="h3">無料トライアル利用登録</Typography>
          <List>
            <ListItem>
              <Link component={RouterLink} to="/signup/pjb">
                アカウント登録 - メールアドレス入力
              </Link>
            </ListItem>
            <ListItem>
              <Link component={RouterLink} to="/signup-email-verification/pjb">
                アカウント登録 - 確認コード入力
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/signup-email-verification/pjb"
                state={{ emailAddress: 'test@example.jp' }}
              >
                アカウント登録 - 確認コード入力（メールアドレス:
                test@example.jp）（Project Board）
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/signup-email-verification/workflow"
                state={{ emailAddress: 'test@example.jp' }}
              >
                アカウント登録 - 確認コード入力（メールアドレス:
                test@example.jp）（Workflow）
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/pjb/trial"
                state={{ emailAddress: 'test@example.jp', accessToken: 'test' }}
              >
                無料トライアル利用登録 入力（Project Board）
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/workflow/trial"
                state={{ emailAddress: 'test@example.jp', accessToken: 'test' }}
              >
                無料トライアル利用登録 入力（Workflow）
              </Link>
            </ListItem>
            <ListItem>
              <Link component={RouterLink} to="/application/pjb/trial-complete">
                無料トライアルのお申し込み 完了（Project Board）
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/application/workflow/trial-complete"
              >
                無料トライアルのお申し込み 完了（Workflow）
              </Link>
            </ListItem>
          </List>
          <Typography variant="h3">お問い合わせ</Typography>
          <List>
            <ListItem>
              <Link component={RouterLink} to="/contact">
                お問い合わせ
              </Link>
            </ListItem>
            <ListItem>
              <Link component={RouterLink} to="/contact-complete">
                お問い合わせ 完了
              </Link>
            </ListItem>
          </List>
          <Typography variant="h3">Conecction Test in 22.05.hotfix</Typography>
          <List>
            <ListItem>
              <Link
                component={RouterLink}
                to="/internal/application/workflow/trial"
              >
                Trial Application Form of Workflow for Internal
              </Link>
            </ListItem>
            <ListItem>
              <Link
                component={RouterLink}
                to="/internal/application/workflow/trial-complete"
              >
                Complete page of Trial Application of Workflow for Internal
              </Link>
            </ListItem>
          </List>
        </Container>
      </Complete>
    </>
  );
};

export default PageList;
