import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import {
  FormGenerator,
  FormGeneratorProps,
} from '@bizapp-frontend/customer/organisms/form/FormGenerator';

import { PageTemplate } from '@bizapp-frontend/customer/templates/PageTemplate';
import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

import { ServiceThemeColor } from '@bizapp-frontend/customer/pages/util';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      backgroundColor: '#F2F3FB',
      padding: theme.spacing(6, 2, 6, 2),
    },
    root: (props: ServiceThemeColor) => ({
      boxSizing: 'border-box',
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 6px 20px #0000004D',
      borderRadius: '0px 0px 5px 5px',
      width: '100%',
      maxWidth: '480px',

      '& button': {
        width: '100%',
        paddingTop: '10px',
        paddingBottom: '9px',
      },

      '&::before': {
        content: '""',
        display: 'block',
        height: theme.spacing(0.75),
        borderRadius: '5px 5px 0px 0px',
        background: `transparent linear-gradient(to right, ${props.firstThemeColor} 0%, ${props.secondThemeColor} 100%) 0% 0% no-repeat padding-box`,
      },
    }),
    content: {
      margin: theme.spacing(4, 4, 5, 4),

      [theme.breakpoints.down(376)]: {
        margin: theme.spacing(3),
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: theme.spacing(3),

      '& img': {
        width: '40px',
        height: '40px',
      },

      '& span': {
        marginLeft: theme.spacing(1),
        fontSize: '20px',
        color: '#333333',
        display: 'block',
      },
    },
    login: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(6),

      '& a': {
        textAlign: 'center',
        fontSize: '14px',
        color: '#1565C0',
      },
    },
    previousPage: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),

      '& a': {
        textAlign: 'center',
        fontSize: '14px',
        color: '#1565C0',
      },
    },
  }),
);

export interface SignUpProps extends FormGeneratorProps {
  children?: React.ReactNode;
  dialogState?: ProcessingDialogState;
  headingContent?: React.ReactNode;
  login?: React.ReactNode;
  onClickDialogClose?: () => void;
  serviceThemeColor?: ServiceThemeColor;
}

export const SignUp: React.FC<SignUpProps> = ({
  children,
  dialogState,
  headingContent,
  login,
  onClickDialogClose,
  serviceThemeColor,
  formComponents,
  ...props
}) => {
  const classes = useStyles(
    serviceThemeColor ?? {
      firstThemeColor: '#ffffff',
      secondThemeColor: '#ffffff',
    },
  );

  return (
    <>
      <PageTemplate>
        <div className={classes.background}>
          <div className={classes.root}>
            <div className={classes.content}>
              <div className={classes.header}>{headingContent}</div>
              {children}
              <div className={classes.login}>{login}</div>
              <FormGenerator {...props} formComponents={formComponents} />
            </div>
          </div>
          <ProcessingDialog
            state={dialogState ?? 'close'}
            onClickError={onClickDialogClose}
          />
        </div>
      </PageTemplate>
    </>
  );
};
