import React from 'react';
import {
  PlanContract as PlanContractTemplate,
  PlanContractProps as PlanContractTemplateProps,
} from '@bizapp-frontend/customer/templates/PlanContractTemplate';

import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';

import {
  ContractList,
  ContractListProps,
} from '@bizapp-frontend/customer/pages/plancontract/ContractList';
import { GlobalsContext } from '@bizapp-frontend/customer/globals';

export interface PlanContractProps
  extends Omit<PlanContractTemplateProps, 'tabPages'>,
    ContractListProps {}

const PlanContract: React.FC<PlanContractProps> = ({
  contractAPIBaseUrl,
  paymentGatewayAPIBaseUrl,
  tenantManagementAPIBaseUrl,
  applicationControlAPIBaseUrl,
}) => {
  const {
    state: { customerId, userId },
  } = React.useContext(GlobalsContext);

  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );

  return (
    <>
      <PlanContractTemplate
        tabPages={[
          [
            'ご契約内容',
            <ContractList
              contractAPIBaseUrl={contractAPIBaseUrl}
              paymentGatewayAPIBaseUrl={paymentGatewayAPIBaseUrl}
              tenantManagementAPIBaseUrl={tenantManagementAPIBaseUrl}
              applicationControlAPIBaseUrl={applicationControlAPIBaseUrl}
              customerId={customerId}
              userId={userId}
              setDialogState={setDialogState}
            />,
          ],
          // TODO implement tab content
          // ['ご注文履歴', <div />],
          // ['お支払い履歴', <div />],
        ]}
        dialogState={dialogState}
      />
    </>
  );
};

export default PlanContract;
