import React from 'react';
import { Box, Container, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import {
  ProcessingDialog,
  ProcessingDialogState,
} from '@bizapp-frontend/customer/organisms/ProcessingDialog';

import { PageTemplate } from '@bizapp-frontend/customer/templates/PageTemplate';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#F2f3fb',
    padding: theme.spacing(6, 11),
    [theme.breakpoints.down(theme.breakpoints.values.tabletEx)]: {
      padding: theme.spacing(5, 4),
    },
    [theme.breakpoints.down(theme.breakpoints.values.tablet)]: {
      padding: theme.spacing(5, 2),
    },
  },
  tabs: {
    backgroundColor: '#F2F3FB',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    '& .Mui-selected': {
      backgroundColor: '#E1E3F2',
      maxHeight: '40px',
      fontWeight: 'bold',
      borderRadius: '3px',
    },
    marginRight: theme.spacing(4),
    [theme.breakpoints.down(theme.breakpoints.values.tabletEx)]: {
      marginRight: 0,
    },
  },
  tab: {
    minWidth: '206px',
    [theme.breakpoints.down(theme.breakpoints.values.tabletEx)]: {
      minWidth: '104px',
    },
    minHeight: '40px',
    paddingTop: '8px',
    padding: '8px 12px',
    fontWeight: 'normal',
    '& .MuiTab-wrapper': {
      alignItems: 'flex-start',
    },
  },
  contentWrapper: {
    margin: theme.spacing(0, 'auto'),
    maxWidth: '888px',
    padding: theme.spacing(1, 4, 0),
    [theme.breakpoints.down(theme.breakpoints.values.tabletEx)]: {
      padding: theme.spacing(1, 0, 0),
    },
  },
  contentTitle: {
    fontSize: '16px',
    lineHeight: '24px',
  },
}));

export interface PlanContractProps {
  tabPages?: [string, React.ReactNode][];
  dialogState?: ProcessingDialogState;
  onClickDialogClose?: () => void;
}

export const PlanContract: React.FC<PlanContractProps> = ({
  tabPages,
  dialogState,
  onClickDialogClose,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const tablet = useMediaQuery(
    theme.breakpoints.down(theme.breakpoints.values.tabletEx),
  );
  const [tabIndex] = React.useState(0);

  // const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  //   setTabIndex(newValue);
  // };
  return (
    <>
      <PageTemplate>
        <Box
          display="flex"
          flexDirection={tablet ? 'column' : 'row'}
          width="100%"
          className={classes.root}
        >
          {/*<Tabs*/}
          {/*  orientation={tablet ? 'horizontal' : 'vertical'}*/}
          {/*  value={tabIndex}*/}
          {/*  onChange={handleChange}*/}
          {/*  aria-label=""*/}
          {/*  className={classes.tabs}*/}
          {/*>*/}
          {/*  {tabPages &&*/}
          {/*    tabPages.map((v, i) => (*/}
          {/*      <Tab className={classes.tab} label={v[0]} key={'tab-' + i} />*/}
          {/*    ))}*/}
          {/*</Tabs>*/}
          <Container disableGutters maxWidth={false}>
            <Box width="100%">
              {tabPages &&
                tabPages.map(
                  (v, i) =>
                    tabIndex === i && (
                      <div
                        key={'content-' + i}
                        className={classes.contentWrapper}
                      >
                        <Typography
                          variant="h6"
                          className={classes.contentTitle}
                        >
                          {v[0]}
                        </Typography>
                        {v[1]}
                      </div>
                    ),
                )}
            </Box>
          </Container>
          <ProcessingDialog
            state={dialogState ?? 'close'}
            onClickError={onClickDialogClose}
          />
        </Box>
      </PageTemplate>
    </>
  );
};
