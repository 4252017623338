import React from 'react';
import * as _ from 'lodash';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { GrecaptchaTypography } from '@bizapp-frontend/customer/atoms/GrecaptchaTypography';
import {
  StyledButton,
  StyledButtonProps,
} from '@bizapp-frontend/customer/molecules/StyledButton';

export interface FormButtonProps extends Omit<StyledButtonProps, 'onClick'> {
  className?: string;
  id: string;
  label: React.ReactNode;
  postClick?: () => void;
  preClick?: () => void;
  isValids?: { [key: string]: boolean };
  forceValidation?: boolean;
  setForceValidation?: (
    value: boolean | ((prevVar: boolean) => boolean),
  ) => void;
}

export const FormButton: React.FC<FormButtonProps> = ({
  className,
  isValids,
  forceValidation,
  setForceValidation,
  postClick,
  preClick,
  children,
  ...props
}) => {
  const [clicked, setClicked] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(false);

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = async (
    ev,
  ) => {
    if (preClick) {
      await preClick();
    }
    setForceValidation && setForceValidation(true);
    setClicked(true);
  };

  React.useEffect(() => {
    // check forceValidation is false
    // to prevent set clicked to false before
    // all form inputs update their isValids states
    if (clicked && !forceValidation) {
      if (_.every(_.values(isValids))) {
        setCanSubmit(true);
      } else {
        setClicked(false);
      }
      setClicked(false);
    }
  }, [clicked, isValids, forceValidation]);

  React.useEffect(() => {
    if (canSubmit) {
      postClick && postClick();
      setCanSubmit(false);
    }
  }, [canSubmit, postClick]);

  return (
    <div className={className}>
      <StyledButton {...props} onClick={handleClick} />
      {children}
    </div>
  );
};

export interface FormButtonGrecaptchaProps
  extends Omit<FormButtonProps, 'postClick'> {
  actionString?: string;
  postClick?: (grecaptchaToken: string) => void;
  showPrivacy?: boolean;
}

export const FormButtonGrecaptcha: React.FC<FormButtonGrecaptchaProps> = ({
  actionString,
  postClick,
  showPrivacy = true,
  ...props
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [grecaptchaToken, setGrecaptchaToken] = React.useState('');

  const submit = React.useCallback(async () => {
    if (executeRecaptcha === undefined) {
      return Promise.reject(new Error('Site key is undefined'));
    }
    let token: string = '';
    try {
      token = await executeRecaptcha(actionString);
      setGrecaptchaToken(token);
    } catch (error) {
      setGrecaptchaToken('');
      return Promise.reject(
        new Error(
          `Probably, this domain is not supported for this site key. ${error}`,
        ),
      );
    }
    return Promise.resolve();
  }, [actionString, executeRecaptcha]);

  const handleClick = async () => {
    await submit();
  };

  React.useEffect(() => {
    if (!_.isEmpty(grecaptchaToken)) {
      postClick && postClick(grecaptchaToken);
      setGrecaptchaToken('');
    }
  }, [grecaptchaToken, postClick]);

  return (
    <FormButton {...props} postClick={handleClick}>
      {showPrivacy && <GrecaptchaTypography />}
    </FormButton>
  );
};
