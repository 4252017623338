import React from 'react';
import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import {
  StyledButton,
  StyledButtonProps,
} from '@bizapp-frontend/customer/molecules/StyledButton';

import { StyledAccordion } from '@bizapp-frontend/customer/molecules/StyledAccordion';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    moreServices: {
      width: '100%',
    },
    link: {
      color: '#7F7F7F',
      fontSize: '14px',
      lineHight: '21px',
      marginBottom: theme.spacing(0.5),
    },
    linkDisabled: {
      color: '#7F7F7F',
      fontSize: '14px',
      lineHight: '21px',
      marginBottom: theme.spacing(0.5),
      opacity: 0.3,
    },
    comment: {
      display: 'block',
      color: '#7F7F7F',
      fontSize: '12px',
    },
  }),
);

export interface AvailableServiceLink {
  id: string;
  label?: string;
  type: 'link' | 'title';
  externalLink?: boolean;
  href?: string;
  state?: object;
  target?: string;
  disabled?: boolean;
  hidden?: boolean;
  subLinks?: AvailableServiceLink[];
  comment?: string;
}

export type AvailableServiceStatus = 'normal' | 'success' | 'warning';

export interface ServiceInfo {
  iconAlt?: string;
  iconImgSrc?: string;
  serviceName?: string;
  serviceNameComponent?: React.ElementType;
  aboutServiceLabel?: React.ReactNode;
  aboutServiceLinks?: AvailableServiceLink[];
  aboutContractLabel?: React.ReactNode;
  aboutContractLinks?: AvailableServiceLink[];
  moreServicesLabel?: React.ReactNode;
  moreServicesLeftLinks?: AvailableServiceLink[];
  moreServicesRightLinks?: AvailableServiceLink[];
}

export interface ServiceBehaviorProps {
  className?: string;
  statusVariant?: AvailableServiceStatus;
  statusLabel?: React.ReactNode;
  buttonsProps?: StyledButtonProps[];
  licenseInfo?: React.ReactNode;
  endDateInfo?: React.ReactNode;
  warningDescription?: React.ReactNode;
  moreServicesRef?: any;
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  notificationPanel?: React.ReactNode;
}

export type AvailableServiceProps = ServiceInfo & ServiceBehaviorProps;

export const AvailableService: React.FC<AvailableServiceProps> = ({
  className,
  iconAlt,
  iconImgSrc,
  serviceName,
  serviceNameComponent,
  statusVariant,
  statusLabel,
  buttonsProps,
  aboutServiceLabel,
  aboutServiceLinks,
  aboutContractLabel,
  aboutContractLinks,
  moreServicesLabel,
  moreServicesLeftLinks,
  moreServicesRightLinks,
  licenseInfo,
  endDateInfo,
  warningDescription,
  moreServicesRef,
  expanded,
  setExpanded,
  notificationPanel,
}) => {
  const classes = useStyle();

  const RootContainer = React.useMemo(
    () =>
      styled('section')(({ theme }) => ({
        boxSizing: 'border-box',
        width: '100%',
        maxWidth: '888px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 1px 3px #0000004D',
        borderRadius: '3px',
        marginBottom: theme.spacing(1),
        padding: theme.spacing(3),
        [theme.breakpoints.up(768)]: {
          padding: theme.spacing(3, 4),
        },
      })),
    [],
  );

  const IconBox = styled('div')(({ theme }) => ({
    '& img': {
      width: '48px',
      height: '48px',
    },
    [theme.breakpoints.up(768)]: {
      '& img': {
        width: '96px',
        height: '96px',
      },
    },
  }));

  const ContentRootBox = React.useMemo(
    () =>
      styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up(640)]: {
          display: 'block',
          width: '100%',
        },
      })),
    [],
  );

  const InformationBox: React.FC = () => {
    const RootBox = styled('div')(({ theme }) => ({
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '295px',
      [theme.breakpoints.up(640)]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: 'initial',
      },
    }));

    const ContentBox = styled('div')(({ theme }) => ({
      flexGrow: 1,
      [theme.breakpoints.down(376)]: {
        flexDirection: 'column',
      },
    }));

    const HeadingBox = styled('div')(({ theme }) => ({
      display: 'flex',
      maxHeight: '63px',
      width: '100%',
      [theme.breakpoints.up(848)]: {
        maxHeight: '30px',
      },
    }));

    const ServiceNameComponent = styled(serviceNameComponent || 'h3')(
      ({ theme }) => ({
        color: '#333333',
        textAlign: 'left',
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: 'bold',
        letterSpacing: 0,
        marginRight: theme.spacing(3),
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up(848)]: {
          marginBottom: theme.spacing(0),
        },
      }),
    );

    const StatusLabel: React.FC = () => {
      let color = '';
      switch (statusVariant) {
        case 'success':
          color = '#00B19D';
          break;
        case 'warning':
          color = '#FF9800';
          break;
        case 'normal': // fall through because of default value
        default:
          color = '#7F7F7F';
          break;
      }

      const StatusComponent = styled('span')(({ theme }) => ({
        display: 'inline-block',
        color: color,
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        border: `1px solid ${color}`,
        borderRadius: '14px',
        textAlign: 'left',
        fontSize: '14px',
        lineHeight: '21px',
        fontWeight: 'normal',
        letterSpacing: 0,
        padding: theme.spacing(0, 1.5),
        boxSizing: 'border-box',
        height: '24px',
        alignSelf: 'flex-end',
        [theme.breakpoints.up(640)]: {
          marginLeft: theme.spacing(4),
        },
        [theme.breakpoints.up(768)]: {
          marginLeft: theme.spacing(0),
        },
      }));

      return <Typography component={StatusComponent}>{statusLabel}</Typography>;
    };

    const ServiceSummaryBox = styled('div')(({ theme }) => ({
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginLeft: theme.spacing(2),
      maxWidth: '247px',
      [theme.breakpoints.up(768)]: {
        marginLeft: theme.spacing(4),
      },
      [theme.breakpoints.up(848)]: {
        flexDirection: 'row',
        alignItems: 'center',
        maxWidth: 'initial',
      },
    }));

    const ContentDetailsBox = styled('div')(({ theme }) => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      marginTop: theme.spacing(2),
      [theme.breakpoints.up(640)]: {
        marginLeft: theme.spacing(8),
      },
      [theme.breakpoints.up(768)]: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(16),
      },
    }));

    const ContentDetailInfo = styled('div')(({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      '& strong': {
        fontWeight: 'bold',
      },
      '& svg': {
        fontSize: theme.spacing(2),
        color: '#E91E63',
        marginLeft: theme.spacing(0.5),
      },
    }));

    const ButtonBox = styled('div')(({ theme }) => ({
      marginTop: theme.spacing(3),
      [theme.breakpoints.up(640)]: {
        marginTop: 0,
      },
      '& button': {
        display: 'block',
        width: '100%',
        [theme.breakpoints.up(640)]: {
          width: '200px',
        },
        height: '32px',
        marginBottom: theme.spacing(1),
        '&:last-child': {
          marginBottom: theme.spacing(0),
        },
      },
    }));

    return (
      <RootBox>
        <ContentBox>
          <HeadingBox>
            <IconBox>
              <img alt={iconAlt} src={iconImgSrc} />
            </IconBox>
            <ServiceSummaryBox>
              <Typography component={ServiceNameComponent}>
                {serviceName}
              </Typography>
              <StatusLabel />
            </ServiceSummaryBox>
          </HeadingBox>
          <ContentDetailsBox>
            <Typography component={ContentDetailInfo}>{licenseInfo}</Typography>
            <Typography component={ContentDetailInfo}>{endDateInfo}</Typography>
          </ContentDetailsBox>
        </ContentBox>
        <ButtonBox>
          {buttonsProps &&
            buttonsProps.map((props, i) => {
              return <StyledButton {...props} key={i} />;
            })}
        </ButtonBox>
      </RootBox>
    );
  };

  const WarningBox: React.FC = () => {
    const WarningWrapper = styled('div')(({ theme }) => ({
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      maxWidth: '295px',
      [theme.breakpoints.up(640)]: {
        marginLeft: theme.spacing(8),
        maxWidth: 'initial',
      },
      [theme.breakpoints.up(768)]: {
        marginLeft: theme.spacing(16),
      },
    }));

    const DescriptionComponent = styled('p')(({ theme }) => ({
      color: '#E91E63',
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 'normal',
      '& span': {
        display: 'block',
      },
      '& strong': {
        fontWeight: 'bold',
      },
    }));

    if (!warningDescription) {
      return null;
    }
    return (
      <WarningWrapper>
        <Typography component={DescriptionComponent}>
          {warningDescription}
        </Typography>
      </WarningWrapper>
    );
  };

  const ServiceBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    '& :last-child': {
      marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.up(640)]: {
      flexDirection: 'row',
    },
  }));

  const ServiceSubBox = styled('div')(({ theme }) => ({
    flex: 1,
    flexDirection: 'column',
    marginBottom: theme.spacing(3),
    '& :last-child': {
      marginBottom: theme.spacing(0),
    },
    [theme.breakpoints.up(640)]: {
      marginBottom: 0,
    },
  }));

  const ServiceSummary = styled('span')(({ theme }) => ({
    display: 'block',
    fontWeight: 'bold',
    fontSize: 14,
    color: '#333333',
    marginBottom: theme.spacing(1),
  }));

  const ServiceLinks: React.FC<{ links?: AvailableServiceLink[] }> = ({
    links,
  }) => {
    const RootList = styled(List)(({ theme }) => ({
      margin: theme.spacing(0, 0, 0, 3),
      padding: 0,
      listStyle: 'disc',
      '& ul.MuiList-root': {
        listStyle: 'circle',
        margin: theme.spacing(1, 0, 0, 1.5),
      },
      '& .MuiList-padding': { padding: 0 },
      '& .MuiListItem-root': {
        display: 'list-item',
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: theme.spacing(1),
        '&:first-child': { marginTop: 0 },
      },
      '& .MuiListItem-gutters': {
        padding: 0,
      },
      '& .MuiListItemText-root': { margin: 0 },
    }));

    const LinkGenerator: React.FC<AvailableServiceLink> = (link) => {
      if (link.hidden === true) {
        return <></>;
      }
      if (link.type === 'link') {
        if (link.disabled) {
          return (
            <ListItem>
              <ListItemText className={classes.linkDisabled}>
                {link.label}
              </ListItemText>
            </ListItem>
          );
        } else {
          return (
            <>
              <ListItem>
                <ListItemText>
                  {link.externalLink ? (
                    <Link
                      className={classes.link}
                      href={link.href}
                      target={link.target ?? ''}
                    >
                      {link.label}
                    </Link>
                  ) : (
                    <Link
                      className={classes.link}
                      component={RouterLink as any}
                      to={link.href}
                      state={link.state}
                    >
                      {link.label}
                    </Link>
                  )}
                </ListItemText>
              </ListItem>
              {link.comment && (
                <span className={classes.comment}>{link.comment}</span>
              )}
            </>
          );
        }
      } else if (link.type === 'title') {
        return (
          <>
            <ListItem>
              <ListItemText>{link.label}</ListItemText>
              <List>
                {link.subLinks && <ServiceLinks links={link.subLinks} />}
              </List>
            </ListItem>
          </>
        );
      }
      return <></>;
    };

    return (
      <>
        {links && (
          <RootList>
            {links.map((link, i) => (
              <LinkGenerator {...link} key={`${link.id}-{i}`} />
            ))}
          </RootList>
        )}
      </>
    );
  };

  const ServicesContainer = React.useMemo(
    () =>
      styled('div')(({ theme }) => ({
        borderTop: '1px solid #0000001f',
        marginTop: theme.spacing(3),
        paddingTop: theme.spacing(3),
        width: '100%',
        maxWidth: '295px',
        [theme.breakpoints.up(640)]: {
          marginLeft: theme.spacing(8),
          width: 'initial',
          maxWidth: 'initial',
        },
        [theme.breakpoints.up(768)]: {
          marginLeft: theme.spacing(16),
        },
      })),
    [],
  );

  return (
    <RootContainer className={className}>
      {notificationPanel}
      <ContentRootBox>
        <InformationBox />
        <WarningBox />
        <ServicesContainer>
          <ServiceBox>
            <ServiceSubBox>
              <Typography component={ServiceSummary}>
                {aboutServiceLabel}
              </Typography>
              <ServiceLinks links={aboutServiceLinks} key="about-service-key" />
            </ServiceSubBox>
            <ServiceSubBox>
              <Typography component={ServiceSummary}>
                {aboutContractLabel}
              </Typography>
              <ServiceLinks
                links={aboutContractLinks}
                key="about-contract-key"
              />
            </ServiceSubBox>
          </ServiceBox>
        </ServicesContainer>
        <ServicesContainer ref={moreServicesRef}>
          <StyledAccordion
            id="more-services"
            title={
              <Typography component={ServiceSummary}>
                {moreServicesLabel}
              </Typography>
            }
            className={classes.moreServices}
            onChange={(ev, isExpanded) => {
              setExpanded && setExpanded(isExpanded);
            }}
            expanded={expanded}
          >
            <ServiceBox>
              <ServiceSubBox>
                <ServiceLinks
                  links={moreServicesLeftLinks}
                  key="more-service-left-key"
                />
              </ServiceSubBox>
              <ServiceSubBox>
                <ServiceLinks
                  links={moreServicesRightLinks}
                  key="more-service-right-key"
                />
              </ServiceSubBox>
            </ServiceBox>
          </StyledAccordion>
        </ServicesContainer>
      </ContentRootBox>
    </RootContainer>
  );
};
