import { FC } from 'react';
import { Button, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const baseStyles = createStyles({
  base: {
    borderRadius: '3px',
    fontWeight: 'bold',
    letterSpacing: 0,
    opacity: 1,
  },
});

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    primary: {
      ...baseStyles.base,
      backgroundColor: '#FFCF18',
      color: '#142E38',
      boxShadow: '0px 2px 4px #00000029',
      fontSize: '14px',
      lineHeight: '21px',
      overflow: 'hidden',
      position: 'relative',
      '&:hover': { backgroundColor: '#FFC900' },
      '&:focus': { backgroundColor: '#FFD740' },
      '&:active': { backgroundColor: '#FFD740' },
      '&.Mui-disabled': {
        color: '#253559',
        backgroundColor: '#FFFFFF',
        border: '1px solid #253559',
        boxShadow: 'none',
        opacity: 0.5,
      },
      '& > span:first-child': {
        zIndex: 1,
      },
      '& .MuiTouchRipple-root': {
        color: '#FFEF5F',
      },
    },
    secondary: {
      ...baseStyles.base,
      backgroundColor: '#FFFFFF',
      color: '#253559',
      border: '1px solid #253559',
      fontSize: '14px',
      lineHeight: '21px',
      '&.Mui-disabled': {
        color: '#253559',
        backgroundColor: '#FFFFFF',
        borderColor: '#253559',
        boxShadow: 'none',
        opacity: 0.5,
      },
    },
  }),
);

export interface StyledButtonProps {
  variant?: string;
  label?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const StyledButton: FC<StyledButtonProps> = ({
  variant,
  label,
  className,
  disabled = false,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      className={`${classes[variant ?? 'primary']} ${className}`}
      disabled={disabled}
      {...props}
    >
      {label}
    </Button>
  );
};
