import React from 'react';
import { Link } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';

import { GlobalsContext } from '@bizapp-frontend/customer/globals';

import {
  FormComponentProps,
  initFormDataPairs,
} from '@bizapp-frontend/customer/organisms/form/FormGenerator';
import {
  Contact as ContactTemplate,
  ContactProps as ContactTemplateProps,
} from '@bizapp-frontend/customer/templates/form/Contact';

import {
  timeout,
  serviceName,
  serviceThemeColor,
  serviceIcon,
  ServiceType,
} from '@bizapp-frontend/customer/pages/util';
import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    agreement: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(5.5),
    },
    submitButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > button': {
        width: '100%',
        height: '48px',
        maxWidth: '400px',
      },
      '& span': {
        display: 'block',
      },
    },
    backlink: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(2, 'auto', 0),
      color: '#1565C0',
      fontSize: '14px',
      lineHeight: '21px',
      cursor: 'pointer',
    },
  }),
);

export type ContactState = {
  serviceId?: string;
  tenantId?: string;
  inquiryType?: string;
  inquiryDetail?: string;
};

export interface ContactProps extends ContactTemplateProps {
  applicationControlAPIBaseUrl?: string;
  customerAPIBaseUrl?: string;
}

const Contact: React.FC<ContactProps> = ({
  applicationControlAPIBaseUrl,
  customerAPIBaseUrl,
  grecaptchaKey,
}) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const {
    state: { emailAddress, userName, userTel, customerId },
  } = React.useContext(GlobalsContext);
  const [submitForm, setSubmitForm] = React.useState(false);
  const [disableSubmit, setDisableSubmit] = React.useState(false);
  const [grecaptcha, setGrecaptcha] = React.useState('');
  const { getAccessTokenSilently } = useAuth0();
  const query = new URLSearchParams(useLocation().search);
  let tenantId = query.get('tenantId') ?? undefined;
  const showBack = (query.get('back') ?? 'true') === 'true';

  const { state: locationState } = useLocation() as { state: ContactState };
  const inquiryDetail = decodeURIComponent(query.get('detail') ?? '');
  const inquiryType = query.get('type') ?? '';
  const serviceId = query.get('serviceId') ?? 'pjb';

  if (locationState?.tenantId) {
    tenantId = locationState.tenantId;
  }

  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );

  const handleDialogClick = () => {
    setDialogState('close');
  };

  const options = React.useMemo(
    () =>
      new Map([
        ['service', '製品に関する問い合わせ'],
        ['contract', 'ご契約に関する問い合わせ'],
        ['other', 'その他'],
      ]),
    [],
  );

  const handleClick = async (grecaptchaToken: string) => {
    setDisableSubmit(true);
    setGrecaptcha(grecaptchaToken);
    setSubmitForm(true);
  };

  const formComponents: FormComponentProps[][] = [
    [
      {
        kind: 'input-normal',
        id: 'contact-person-name',
        label: '氏名',
        defaultValue: userName,
        name: 'name',
        required: false,
        disabled: true,
        size: 'full',
      },
    ],
    [
      {
        kind: 'input-email',
        id: 'contact-person-mail',
        label: 'メールアドレス',
        defaultValue: emailAddress,
        name: 'email',
        required: false,
        disabled: true,
        size: 'full',
      },
    ],
    [
      {
        kind: 'input-tel',
        id: 'contact-person-tel',
        label: '携帯番号 (テレワーク中の方など連絡が取れる番号)',
        defaultValue: userTel,
        name: 'tel',
        required: false,
        disabled: true,
        size: 'full',
      },
    ],
    [
      {
        kind: 'input-normal',
        id: 'service',
        label: 'お問い合わせ対象サービス',
        defaultValue: serviceId
          ? serviceName(serviceId as ServiceType)
          : 'Project Board',
        required: false,
        disabled: true,
        size: 'full',
      },
    ],
    [
      {
        kind: 'select-normal',
        id: 'inquiry-type',
        label: 'お問い合わせ種別',
        defaultValue: inquiryType,
        required: true,
        size: 'full',
        options: options,
      },
    ],
    [
      {
        kind: 'input-normal',
        id: 'inquiry-detail',
        label: 'お問い合わせ詳細',
        defaultValue: inquiryDetail,
        required: true,
        size: 'full',
        multiline: true,
        rows: 10,
      },
    ],
    [
      {
        kind: 'checkbox-normal',
        id: 'agreement',
        label: (
          <>
            <Link href="https://bizapp.worksap.co.jp/terms" target={'_blank'}>
              利用規約
            </Link>
            および
            <Link href="https://bizapp.worksap.co.jp/privacy" target={'_blank'}>
              個人情報の取り扱い
            </Link>
            について同意する。
          </>
        ),
        required: true,
        helperText: 'ご同意をお願いします。',
        className: classes.agreement,
      },
    ],
    [
      {
        kind: 'button-grecaptcha',
        id: 'submit-button',
        label: '送信する',
        className: classes.submitButton,
        postClick: handleClick,
        disabled: disableSubmit,
      },
    ],
  ];

  const kinds = _.flatMapDeep(formComponents)
    .filter((v) => !(v.kind.startsWith('text') || v.kind.startsWith('button')))
    .map((v) => v);

  const [formData, setFormData] = React.useState<{
    [key: string]: boolean | string | undefined;
  }>(initFormDataPairs(kinds));

  const [isValids, setIsValids] = React.useState(
    _.fromPairs(kinds.map((v) => [v.id, false])),
  );
  const [forceValidation, setForceValidation] = React.useState(false);

  React.useEffect(() => {
    if (_.isEmpty(tenantId)) {
      navigate('/');
    } else {
      // when open with new tab need to wait global context fetching
      setFormData((prevState) => ({
        ...prevState,
        'contact-person-mail': emailAddress,
        'contact-person-name': userName,
        'contact-person-tel': userTel,
      }));
    }
  }, [customerId, emailAddress, userName, tenantId, navigate, userTel]);

  React.useEffect(() => {
    if (submitForm && grecaptcha) {
      setSubmitForm(false);
      const submitData = async (grecaptchaToken: string) => {
        const optionsNameKeyMap = new Map(
          options ? Array.from(options.entries()).map((v) => [v[1], v[0]]) : [],
        );

        const accessToken = await getAccessTokenSilently();

        const customerResp = await fetch(
          `${customerAPIBaseUrl}/api/customer/customers/${customerId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );

        if (!customerResp.ok) {
          return Promise.reject(new Error('Data is invalid'));
        }
        const customerJson = await customerResp.json();

        const resp = await fetch(
          `${applicationControlAPIBaseUrl}/api/application-controller/applications`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
              'X-reCAPTCHA-Token': grecaptchaToken,
            },
            body: JSON.stringify({
              applicationType: 'contact',
              applicationSubtype: optionsNameKeyMap.get(
                formData['inquiry-type'] as string,
              ),
              userKind: 'customer',
              userId: emailAddress,
              customerId: customerId,
              tenantId: tenantId,
              serviceId: serviceId,
              notificationKind: 'email',
              jsonData: JSON.stringify({
                ...formData,
                'company-name': customerJson.company.companyName,
              }),
            }),
          },
        );

        return resp.status === 201
          ? Promise.resolve()
          : Promise.reject(
              new Error(`Failed in submitting form. status is ${resp.status}`),
            );
      };

      const f = async () => {
        try {
          setDialogState('wait');
          await timeout(submitData(grecaptcha));
          setDialogState('close');
          navigate(`/contact-complete?serviceId=${serviceId}`);
        } catch (err) {
          setGrecaptcha('');
          setDisableSubmit(false);
          // Todo: error handling
          setDialogState('error');
        }
      };
      f();
    } else {
      setSubmitForm(false);
      setGrecaptcha('');
      setDisableSubmit(false);
    }
  }, [
    applicationControlAPIBaseUrl,
    customerAPIBaseUrl,
    customerId,
    emailAddress,
    formData,
    getAccessTokenSilently,
    grecaptcha,
    serviceId,
    tenantId,
    navigate,
    options,
    submitForm,
  ]);

  return (
    <>
      <ContactTemplate
        formComponents={formComponents}
        formData={formData}
        setFormData={setFormData}
        isValids={isValids}
        setIsValids={setIsValids}
        forceValidation={forceValidation}
        setForceValidation={setForceValidation}
        iconImgSrc={
          serviceId
            ? serviceIcon(serviceId as ServiceType)
            : `${process.env.PUBLIC_URL}/images/icon-project-board@2x.png`
        }
        iconImgAlt="Project Board Logo"
        colors={
          serviceId
            ? serviceThemeColor(serviceId as ServiceType)
            : {
                firstThemeColor: '#6A8AFF',
                secondThemeColor: '#6AA4FF',
              }
        }
        grecaptchaKey={grecaptchaKey}
        dialogState={dialogState}
        onClickDialogClose={handleDialogClick}
      >
        {showBack && (
          <Link
            onClick={() => {
              navigate(-1);
            }}
            className={classes.backlink}
          >
            {'前のページへ戻る'}
          </Link>
        )}
      </ContactTemplate>
    </>
  );
};

export default Contact;
