import React from 'react';
import { Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    title: {
      fontWeight: 'bold',
      fontSize: '16px',
      color: '#333333',
      marginBottom: theme.spacing(2),
    },
    content: {
      fontSize: '16px',
      color: '#333333',
      display: 'flex',
      flexDirection: 'column',
      '& span': {
        display: 'block',
      },
      '& a,button': {
        fontSize: '16px',
        lineHeight: '24px',
        fontWeith: 'normal',
        color: '#333333',
        textDecoration: 'underline',
      },
    },
  }),
);

export interface NotificationPanelClasses {
  root?: string;
  icon?: string;
}

export interface NotificationPanelProps {
  type?: 'primary' | 'success' | 'accent' | 'danger' | 'hidden';
  icon?: React.ReactNode;
  title?: string;
  content?: React.ReactNode;
  className?: string;
}

export const NotificationPanel: React.FC<NotificationPanelProps> = ({
  type,
  icon,
  title,
  content,
  className,
}) => {
  const classes = useStyles();

  const notificationColor = React.useMemo(() => {
    switch (type) {
      case 'success':
        return '#0EC6BD';
      case 'accent':
        return '#FF9800';
      case 'danger':
        return '#E91E63';
      case 'primary':
      default:
        return '#4285F4';
    }
  }, [type]);

  const RootContainer = styled('div')(({ theme }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    backgroundColor: '#FFFFFF',
    border: '1px solid',
    borderColor: notificationColor,
    borderRadius: '3px',
    maxWidth: '888px',
    width: '100%',
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down(376)]: {
      padding: theme.spacing(1.5),
    },
  }));

  const IconBox = styled('div')(({ theme }) => ({
    display: 'flex',
    alignSelf: 'flex-start',
    '& svg': {
      color: notificationColor,
      alignSelf: 'center',
    },
  }));

  const ContentBox = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
    fontSize: '16px',
    color: '#333333',
  }));

  return (
    <>
      {type !== 'hidden' && (
        <RootContainer className={className}>
          <IconBox>{icon}</IconBox>
          <ContentBox>
            <Typography component="span" className={classes.title}>
              {title}
            </Typography>
            <Typography component="div" className={classes.content}>
              {content}
            </Typography>
          </ContentBox>
        </RootContainer>
      )}
    </>
  );
};
