import React from 'react';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import Meta from '@bizapp-frontend/customer/organisms/Meta';
import { Complete } from '@bizapp-frontend/customer/templates/Complete';

type PurchaseApplicationCompleteState = {
  valid?: boolean;
};

export interface PurchaseApplicationCompleteProps {
  isAllowedDirectAccess?: boolean;
}
const title =
  'HUE Works Suite / HUE Works Suite DX Solutions ユーザサイト | 株式会社ワークスアプリケーションズ';
const description =
  '株式会社ワークスアプリケーションズがご提供するHUE Works Suiteのユーザ様用のウェブサイトです。';

const PurchaseApplicationComplete: React.FC<PurchaseApplicationCompleteProps> = ({
  isAllowedDirectAccess,
}) => {
  const { state } = useLocation() as {
    state: PurchaseApplicationCompleteState;
  };
  const navigate = useNavigate();

  const ThanksBox = styled('section')(({ theme }) => ({
    margin: theme.spacing(3, 2),
    [theme.breakpoints.up(768)]: {
      margin: theme.spacing(3, 4),
    },
  }));

  const ThanksComponent = styled('p')(({ theme }) => ({
    color: '#333333',
    fontSize: '14px',
    lineHeight: '21px',
    fontWeight: 'normal',
    letterSpacing: 0,
    padding: 0,
    marginBottom: theme.spacing(1),
    textAling: 'center',
    '& span': {
      display: 'block',
    },
  }));

  const AnnounceBox = styled('section')(({ theme }) => ({
    margin: theme.spacing(3, 2),
    [theme.breakpoints.up(768)]: {
      margin: theme.spacing(3, 4),
    },
  }));

  const BackLink = styled('a')(({ theme }) => ({
    display: 'block',
    textAlign: 'center',
    margin: theme.spacing(5, 'auto', 0),
    color: '#1565C0',
    fontSize: '14px',
    lineHeight: '21px',
    cursor: 'pointer',
  }));

  return isAllowedDirectAccess || state?.valid ? (
    <>
      <Meta>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Meta>
      <Complete
        themeColor="#4285F4"
        heading1="ご購入アンケート"
        heading2="アンケートの送信が完了しました"
      >
        <ThanksBox>
          <Typography component={ThanksComponent}>
            ご協力いただき、誠にありがとうございました。
          </Typography>
        </ThanksBox>
        <AnnounceBox>
          <BackLink
            onClick={() => {
              navigate('/');
            }}
          >
            {'ユーザーページへ戻る'}
          </BackLink>
        </AnnounceBox>
      </Complete>
    </>
  ) : (
    <Navigate to="/invalid" replace />
  );
};

export default PurchaseApplicationComplete;
