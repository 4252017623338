import React from 'react';
import { Info, Warning } from '@material-ui/icons';
import { Link } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import * as _ from 'lodash';

import { NotificationPanelProps } from '@bizapp-frontend/customer/organisms/NotificationPanel';

import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';

import { GlobalsContext } from '@bizapp-frontend/customer/globals';

import {
  AvailableServiceInfo,
  Home as HomeTemplate,
  HomeProps as HomeTemplateProps,
  PJBServiceInfo,
  ServiceStatus,
  WorkflowServiceInfo,
  ChatbotServiceInfo,
} from '@bizapp-frontend/customer/templates/Home';
import { PurchaseApplicationSelectionDialog } from '@bizapp-frontend/customer/organisms/PurchaseApplicationSelectionDialog';
import {
  isTenantDeleted,
  serviceName as getServiceName,
  serviceThemeColor,
  ServiceType,
} from '@bizapp-frontend/customer/pages/util';

interface ContractInfo {
  applicationId: string;
  availableDate: number;
  calculationStartDate: number;
  contractId: string;
  contractKind: 'volume' | 'sepcified' | 'constant';
  customerId: string;
  endDate: number;
  note: string;
  planId: string;
  serviceId: string;
  startDate: number;
  usageKind: 'trial' | 'purchased' | 'freemium';
  tenantId: string;
  tenantName: string;
  volume?: number | null;
  timestamp: number;
  environmentStatus: string;
}

export interface HomeProps {
  applicationControlAPIBaseUrl: string;
  contractAPIBaseUrl: string;
  tenantManegementAPIBaseUrl: string;
  pjbDomainUrlSuffix: string;
  workflowDomainUrl: string;
  chatbotDomainUrlSuffix: string;
}

export interface Usage {
  resource: string;
  value: number;
  limit: number;
}

export interface UsageInfo {
  usage: Usage[];
}

interface CustomerTenantInfo {
  customerId: string;
  serviceId: string;
  tenantId: string;
  tenantName: string;
  timestamp: number;
}

type TenantUsage = { [tenantId: string]: Usage[] };

interface TenantInfo {
  tenantId: string;
  tenantName: string;
  serviceId: string;
}

const Home: React.FC<HomeProps> = ({
  applicationControlAPIBaseUrl,
  contractAPIBaseUrl,
  tenantManegementAPIBaseUrl,
  pjbDomainUrlSuffix,
  workflowDomainUrl,
  chatbotDomainUrlSuffix,
}) => {
  const ref = React.createRef<HTMLElement>();
  const [expanded, setExpanded] = React.useState<{ [key: string]: boolean }>(
    {},
  );
  const { getAccessTokenSilently } = useAuth0();
  const [contractInfo, setContractInfo] = React.useState<{
    [key: string]: ContractInfo;
  }>({});
  const [blocked, setBlocked] = React.useState(false);
  const [templateData, setTemplateData] = React.useState<HomeTemplateProps>({});
  const [updated, setUpdated] = React.useState(false);
  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );
  const [unusedServices, setUnusedServices] = React.useState<ServiceType[]>([]);

  const { state } = React.useContext(GlobalsContext);

  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const tenantId = query.get('tenantId') ?? undefined;
  const serviceId = query.get('serviceId') ?? undefined;
  const showApplicationDialogQuery = query.get('purchaseDialog') === 'true';
  const statusQuery = query.get('status');

  const [
    showApplicationDialog,
    setShowApplicationDialog,
  ] = React.useState<boolean>(false);

  React.useEffect(() => {
    setShowApplicationDialog(
      showApplicationDialogQuery && dialogState === 'close',
    );
  }, [dialogState, showApplicationDialogQuery]);

  const closeApplicationDialog = () => {
    if (statusQuery) {
      navigate(`/?statue=${statusQuery}`);
    }
    navigate(`/`);
  };

  let status;
  switch (statusQuery) {
    case 'will-expire':
      status = 'willExpire';
      break;
    case 'expired':
      status = 'expired';
      break;
    case 'in-use':
      status = 'inUse';
      break;
    case 'purchased':
      status = 'purchased';
      break;
    case 'in-preparation':
      status = 'inPreparation';
      break;
    default:
      status = '';
  }

  const handleDialogClick = () => {
    setDialogState('close');
  };

  const [tenantInfos, setTenantInfos] = React.useState<TenantInfo[]>([]);
  const [tenantUsages, setTenantUsages] = React.useState<TenantUsage>(
    {} as TenantUsage,
  );

  const getUsage = React.useCallback(
    async (tenantId: string) => {
      const accessToken = await getAccessTokenSilently();
      const method = 'GET';
      const url = `${applicationControlAPIBaseUrl}/api/application-controller/tenants/${tenantId}/usages`;
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      };
      const resp = await fetch(url, {
        method: method,
        headers: headers,
      });

      if (resp.status === 200) {
        return Promise.resolve(resp.json());
      } else {
        return Promise.reject(
          new Error(`Failed in getting usage data. status is ${resp.status}`),
        );
      }
    },
    [applicationControlAPIBaseUrl, getAccessTokenSilently],
  );

  React.useEffect(() => {
    const f = async () => {
      const accessToken = await getAccessTokenSilently();
      try {
        setBlocked(true);
        setDialogState('wait');
        const userResp = await fetch(
          `${tenantManegementAPIBaseUrl}/api/tenant-management/customers/${state.customerId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          },
        );
        const tenantJson: CustomerTenantInfo[] = (await userResp.json()).filter(
          // TODO support other services
          // filter to show pjb service only
          // since all views are designed for pjb only
          (it: CustomerTenantInfo) =>
            it.serviceId === 'pjb' ||
            it.serviceId === 'workflow' ||
            it.serviceId === 'chatbot',
        );
        let newContractInfo: { [tenantId: string]: ContractInfo } = {};
        for await (const t of tenantJson) {
          const contractResp = await fetch(
            `${contractAPIBaseUrl}/api/contract/customers/${state.customerId}/tenants/${t.tenantId}/current`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          const contractJson = await contractResp.json();

          const statusResp = await fetch(
            `${applicationControlAPIBaseUrl}/api/application-controller/tenants/${t.tenantId}/status`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          const statusJson = await statusResp.json();

          if (t.serviceId !== 'workflow') {
            try {
              const usageInfo: { usage: Usage[] } = await getUsage(t.tenantId);
              setTenantUsages((prevUsages) => ({
                ...prevUsages,
                [t.tenantId]: usageInfo.usage,
              }));
            } catch (e) {
              console.error('fetch tenant info failed at start up', e);
            }
          }
          newContractInfo = {
            ...newContractInfo,
            [contractJson.tenantId]: {
              ...contractJson,
              tenantName: t.tenantName,
              environmentStatus: statusJson.Status,
            } as ContractInfo,
          };
        }

        setTenantInfos(
          tenantJson.map((t) => ({
            tenantId: t.tenantId,
            tenantName: t.tenantName,
            serviceId: t.serviceId,
          })),
        );
        setDialogState('close');
        setContractInfo(newContractInfo);
        setUpdated(true);
      } catch {
        setDialogState('error');
      }
    };
    setDialogState('wait');
    if (state.customerId && !blocked) {
      f();
    }
  }, [
    contractAPIBaseUrl,
    blocked,
    getAccessTokenSilently,
    state.customerId,
    tenantManegementAPIBaseUrl,
    pjbDomainUrlSuffix,
    applicationControlAPIBaseUrl,
    getUsage,
  ]);

  const handleClick = React.useCallback(
    (tenantId: string) => {
      setExpanded((prev) => ({
        ...prev,
        [tenantId]: true,
      }));
      setUpdated(true);
      ref?.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [ref],
  );

  const getNotificationPanelProps = React.useCallback(
    (
      serviceId: string,
      status: string,
      tenantId: string,
    ): NotificationPanelProps => {
      const serviceName = serviceId
        ? getServiceName(serviceId as ServiceType)
        : '';
      switch (status) {
        case 'in-preparation':
          return {
            type: 'primary',
            icon: <Info />,
            title: `${serviceName} の無料トライアルは、まもなく開始できます。`,
            content: (
              <>
                <span>
                  セットアップが完了し次第、メールにてご案内いたします。
                </span>
                <span>
                  お待ちのあいだ、
                  <Link
                    component="button"
                    onClick={() => handleClick(tenantId)}
                  >
                    こちら
                  </Link>
                  で {serviceName} について学ぶマニュアルを入手できます。
                </span>
              </>
            ),
          };
        case 'in-use':
          return {
            type: 'success',
            icon: <Info />,
            title: `${serviceName} の無料トライアルご利用のご準備が整いました。`,
            content: (
              <>
                <span>
                  「サービスを開く」より、30日間のトライアルをお楽しみください。
                </span>
                <span>
                  「はじめてガイド」などの活用法ドキュメントは、
                  <Link
                    component="button"
                    onClick={() => handleClick(tenantId)}
                  >
                    こちら
                  </Link>
                  からどうぞ。
                </span>
              </>
            ),
          };
        case 'will-expire':
          return {
            type: 'accent',
            icon: <Warning />,
            title: `${serviceName} の無料トライアル期間の終了が迫っています。`,
            content:
              '引き続きご利用される場合は、有料プランへのご登録が必要となります。',
          };
        case 'expired':
          return {
            type: 'danger',
            icon: <Warning />,
            title: `${serviceName} の無料トライアル期間は終了しました。`,
            content:
              '引き続きご利用される場合は、有料プランへのご登録が必要となります。',
          };
        case 'purchased':
        default:
          return {
            type: 'hidden',
          };
      }
    },
    [handleClick],
  );

  const ServiceInfo = React.useCallback((serviceId: string) => {
    switch (serviceId) {
      case 'workflow':
        return WorkflowServiceInfo;
      case 'chatbot':
        return ChatbotServiceInfo;
      case 'pjb':
      default:
        return PJBServiceInfo;
    }
  }, []);

  const homeTemplateProps: { [key: string]: HomeTemplateProps } = {
    inPreparation: {
      availableServiceInfo: [
        {
          serviceInfo: ServiceInfo(serviceId || '')('in-preparation'),
          serviceStatus: 'in-preparation',
          licenseNumber: 20,
          moreServicesRef: ref,
          expanded: expanded['in-preparation-tenant'],
          setExpanded: (expanded) =>
            setExpanded((prev) => ({
              ...prev,
              'in-preparation-tenant': expanded,
            })),
          notificationPanel: getNotificationPanelProps(
            serviceId || '',
            'in-preparation',
            'in-preparation-tenant',
          ),
        },
      ],
    },
    inUse: {
      availableServiceInfo: [
        {
          serviceInfo: ServiceInfo(serviceId || '')('in-use'),
          serviceStatus: 'in-use',
          licenseNumber: 20,
          usedLicenseNumber: 1,
          volume: 214748364800,
          usedVolume: 1656385,
          expiredDate: 1633013999000000000,
          moreServicesRef: ref,
          expanded: expanded['in-use-tenant'],
          setExpanded: (expanded) =>
            setExpanded((prev) => ({ ...prev, 'in-use-tenant': expanded })),
          notificationPanel: getNotificationPanelProps(
            serviceId || '',
            'in-use',
            'in-use-tenant',
          ),
        },
      ],
    },
    willExpire: {
      availableServiceInfo: [
        {
          serviceInfo: ServiceInfo(serviceId || '')('will-expire'),
          serviceStatus: 'will-expire',
          licenseNumber: 20,
          usedLicenseNumber: 8,
          volume: 214748364800,
          usedVolume: 1656385,
          expiredDate: 1633013999000000000,
          notificationPanel: getNotificationPanelProps(
            serviceId || '',
            'will-expire',
            '',
          ),
        },
      ],
    },
    expired: {
      availableServiceInfo: [
        {
          serviceInfo: ServiceInfo(serviceId || '')('expired'),
          serviceStatus: 'expired',
          licenseNumber: 20,
          usedLicenseNumber: 8,
          volume: 214748364800,
          usedVolume: 1656385,
          expiredDate: 1633013999000000000,
          notificationPanel: getNotificationPanelProps(
            serviceId || '',
            'expired',
            '',
          ),
        },
      ],
    },
    purchased: {
      availableServiceInfo: [
        {
          serviceInfo: ServiceInfo(serviceId || '')('purchased'),
          serviceStatus: 'purchased',
          licenseNumber: 10,
          usedLicenseNumber: 8,
        },
      ],
    },
  };

  React.useEffect(() => {
    if (tenantInfos.length > 0) {
      const requestUpdateUsage = async (
        tenantId: string,
        tenantName: string,
        serviceId: string,
      ) => {
        const accessToken = await getAccessTokenSilently();
        const method = 'POST';
        const url = `${applicationControlAPIBaseUrl}/api/application-controller/messages`;
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        };
        const _body = {
          targetService: serviceId,
          tenantId: tenantId,
          tenantName: tenantName,
          eventType: 'read',
          resource: 'usage',
        };
        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(_body),
        });

        if (resp.status === 201) {
          return Promise.resolve();
        } else {
          return Promise.reject(
            new Error(`Failed in getting usage data. status is ${resp.status}`),
          );
        }
      };

      const f = async () => {
        for await (const tenantInfo of tenantInfos) {
          if (
            tenantInfo.serviceId === 'workflow' ||
            tenantInfo.serviceId === 'chatbot'
          ) {
            continue;
          }
          try {
            await requestUpdateUsage(
              tenantInfo.tenantId,
              tenantInfo.tenantName,
              tenantInfo.serviceId,
            );
            setTimeout(async () => {
              try {
                const usageInfo: { usage: Usage[] } = await getUsage(
                  tenantInfo.tenantId,
                );
                setTenantUsages((prevUsages) => ({
                  ...prevUsages,
                  [tenantInfo.tenantId]: usageInfo.usage,
                }));
              } catch (e) {
                console.error(e);
              }
            }, 5000);
          } catch (e) {
            console.error(e);
          }
        }
      };
      f();
    }
  }, [
    applicationControlAPIBaseUrl,
    getAccessTokenSilently,
    getUsage,
    tenantInfos,
  ]);

  React.useEffect(() => {
    if (!updated || _.keys(contractInfo).length === 0) {
      return;
    }
    setUpdated(false);
    const currentTime = new Date().getTime();
    const expandedStatus: { [key: string]: boolean } = expanded;
    const serviceInfos: AvailableServiceInfo[] = _.values(contractInfo).map(
      (info: ContractInfo) => {
        expandedStatus[info.tenantId] = expanded[info.tenantId] || false;
        const endDate = info.endDate / 1000000;
        const daysLeft = Math.floor((endDate - currentTime) / 86400000);
        let status: ServiceStatus = 'in-preparation';
        if (info.usageKind === 'freemium') {
          status = 'freemium';
        } else if (info.usageKind === 'purchased') {
          status = 'purchased';
        } else if (
          currentTime > endDate ||
          info.environmentStatus.toUpperCase() === 'LOCKED'
        ) {
          status = 'expired';
        } else if (daysLeft <= 3) {
          status = 'will-expire';
        } else if (
          info.environmentStatus.toUpperCase() === 'RUNNABLE' ||
          info.environmentStatus.toUpperCase() === 'RUNNING'
        ) {
          status = 'in-use';
        } else {
          status = 'in-preparation';
        }
        // TODO: need to change the url in accordance with serviceIdj
        let url = '';
        switch (info.serviceId) {
          case 'pjb':
            url = `https://${info.tenantName}${pjbDomainUrlSuffix}`;
            break;
          case 'workflow':
            url = `${workflowDomainUrl}/${info.tenantName}/home`;
            break;
          case 'chatbot':
            url = `https://${info.tenantName}${chatbotDomainUrlSuffix}${info.tenantName}`;
            break;
          default:
        }

        const usageInfo: Usage[] = tenantUsages[info.tenantId];
        const productUsage = usageInfo?.find(
          (it) => it.resource === 'VALID_ACCOUNT_COUNT',
        );
        const volumeUsageInfo = usageInfo?.find(
          (it) => it.resource === 'REF_FILE_SIZE_SUM',
        );
        return {
          serviceInfo: ServiceInfo(info.serviceId)(
            status,
            info.serviceId,
            info.tenantId,
            url,
            isTenantDeleted(endDate),
          ),
          serviceStatus: status,
          licenseNumber: info.volume ?? 0,
          usedLicenseNumber: productUsage?.value,
          volume: volumeUsageInfo?.limit,
          usedVolume: volumeUsageInfo?.value,

          serviceId: info.serviceId,
          //usageKind: info.usageKind,
          tenantId: info.tenantId,
          expiredDate: info.endDate,
          daysLeft: daysLeft,
          moreServicesRef: ref,
          expanded: expanded[info.tenantId],
          setExpanded: (expanded: boolean) => {
            setExpanded((prev) => ({
              ...prev,
              [info.tenantId]: expanded,
            }));
            setUpdated(true);
          },
          url: url,
          notificationPanel: getNotificationPanelProps(
            info.serviceId,
            status,
            info.tenantId,
          ),
          startDate: info.startDate,
        };
      },
    );
    const purchased: AvailableServiceInfo[] = _.filter(serviceInfos, [
      'serviceStatus',
      'purchased',
    ]).sort((a, b) => {
      return a.startDate && b.startDate ? a.startDate - b.startDate : 0;
    });
    const freemium: AvailableServiceInfo[] = _.filter(serviceInfos, [
      'serviceStatus',
      'freemium',
    ]).sort((a, b) => {
      return a.startDate && b.startDate ? a.startDate - b.startDate : 0;
    });
    const trials: AvailableServiceInfo[] = _.filter(
      serviceInfos,
      (info) =>
        info.serviceStatus !== 'purchased' && info.serviceStatus !== 'freemium',
    ).sort((a, b) => {
      return a.startDate && b.startDate ? a.startDate - b.startDate : 0;
    });

    const availableServiceInfo: AvailableServiceInfo[] = [];
    availableServiceInfo.push(...purchased);
    availableServiceInfo.push(...freemium);
    availableServiceInfo.push(...trials);

    setTemplateData({
      availableServiceInfo: availableServiceInfo,
    });
    setExpanded(expandedStatus);
    setUnusedServices(
      _.without(
        ['pjb', 'workflow', 'chatbot'],
        ..._.map(availableServiceInfo, 'serviceId'),
      ) as ServiceType[],
    );
  }, [
    contractInfo,
    updated,
    ref,
    getNotificationPanelProps,
    expanded,
    pjbDomainUrlSuffix,
    workflowDomainUrl,
    chatbotDomainUrlSuffix,
    tenantUsages,
    ServiceInfo,
  ]);

  return (
    <>
      {state.development && status ? (
        <HomeTemplate {...homeTemplateProps[status]} />
      ) : (
        <HomeTemplate
          {...templateData}
          dialogState={dialogState}
          onClickDialogClose={handleDialogClick}
          trialServices={unusedServices}
        />
      )}

      <PurchaseApplicationSelectionDialog
        serviceId={serviceId}
        tenantId={tenantId}
        open={showApplicationDialog}
        onClose={closeApplicationDialog}
        iconImgSrc={`${process.env.PUBLIC_URL}/images/logo/${serviceId}.png`}
        iconImgAlt={`${
          serviceId ? getServiceName(serviceId as ServiceType) : ''
        } Logo`}
        colors={
          serviceId ? serviceThemeColor(serviceId as ServiceType) : undefined
        }
      />
    </>
  );
};

export default Home;
