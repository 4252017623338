import { IconButton, Snackbar } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';

export interface ToastrProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  message: string;
  duration?: number;
}

export const Toastr: React.FC<ToastrProps> = ({
  message,
  open,
  setOpen,
  duration = 1000,
}) => {
  const handleClose = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      message={message}
      action={action}
      onClose={handleClose}
    />
  );
};
