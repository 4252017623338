import {
  addMonths,
  getTime,
  setDate,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  toDate,
} from 'date-fns/fp';
import { formatInTimeZone } from 'date-fns-tz/fp';
import flow from 'lodash/fp/flow';
import { addDays, isAfter } from 'date-fns';

export const TIMEOUT_MSEC = 30 * 1000;

export const timeout = <T extends any>(
  promise: Promise<T>,
  milliseconds: number = TIMEOUT_MSEC,
): Promise<T> => {
  let timer: number;
  return Promise.race([
    promise,
    new Promise<T>((_resolve, reject) => {
      timer = window.setTimeout(reject, milliseconds);
    }),
  ]).finally(() => window.clearTimeout(timer));
};

export const getCookie = (key: string) => {
  if (document.cookie !== '') {
    const cookies: { [key: string]: string } = {};
    const cookieLines = document.cookie.split('; ');
    for (let i = 0; i < cookieLines.length; i++) {
      const cookieLine = cookieLines[i].split('=');
      cookies[cookieLine[0]] = decodeURIComponent(cookieLine[1]);
    }
    return cookies[key];
  }
};

const JSTTimeZone = 'Asia/Tokyo';
export const toNextMonthDayStr = (day: string, now: number) => {
  return flow(
    toDate,
    addMonths(1),
    formatInTimeZone(`yyyy年M月${day}日`, JSTTimeZone),
  )(now);
};

const JST_OFFSET = -9 * 60;

const toJSTWithSameLocalTime = (ms: number) => {
  return ms + (JST_OFFSET - new Date().getTimezoneOffset()) * 60 * 1000;
};

const toLocalWithSameLocalTime = (ms: number) => {
  return ms + (new Date().getTimezoneOffset() - JST_OFFSET) * 60 * 1000;
};

export const addDay = (ms: number, day: number) =>
  ms + 24 * 60 * 60 * 1000 * day;

export const toCurrMonthDayMs = (day: string, now: number) => {
  return flow(
    toLocalWithSameLocalTime,
    toDate,
    setHours(0),
    setMinutes(0),
    setSeconds(0),
    setMilliseconds(0),
    setDate(+day),
    getTime,
    toJSTWithSameLocalTime,
  )(now);
};
export const toNextMonthDayMs = (day: string, now: number) => {
  return flow(
    toLocalWithSameLocalTime,
    toDate,
    addMonths(1),
    setHours(0),
    setMinutes(0),
    setSeconds(0),
    setMilliseconds(0),
    setDate(+day),
    getTime,
    toJSTWithSameLocalTime,
  )(now);
};

const DAYS_UNTIL_TENANT_DELETION = 30;
export const isTenantDeleted = (expirationDateMs: number) => {
  const currentTime = new Date().getTime();
  const tenantDeletionTime = addDays(
    expirationDateMs,
    DAYS_UNTIL_TENANT_DELETION,
  );
  return isAfter(currentTime, tenantDeletionTime);
};

export type CustomerKind = 'personal' | 'corporate' | 'unknown';

export interface ServiceThemeColor {
  firstThemeColor: string;
  secondThemeColor: string;
}

export type ServiceType = 'pjb' | 'workflow' | 'chatbot';
export type ServiceInfo = {
  [key in ServiceType]: {
    name: string;
    nameJa: string;
    colors: ServiceThemeColor;
    icon: string;
  };
};

export const serviceInfo: ServiceInfo = {
  pjb: {
    name: 'Project Board',
    nameJa: 'プロジェクトボード',
    colors: {
      firstThemeColor: '#6A8AFF',
      secondThemeColor: '#6AA4FF',
    },
    icon: `${process.env.PUBLIC_URL}/images/icon-project-board@2x.png`,
  },
  workflow: {
    name: 'Workflow',
    nameJa: 'ワークフロー',
    colors: {
      firstThemeColor: '#0EC6BD',
      secondThemeColor: '#78D899',
    },
    icon: `${process.env.PUBLIC_URL}/images/icon-workflow@2x.png`,
  },
  chatbot: {
    name: 'Chatbot',
    nameJa: 'チャットボット',
    colors: {
      firstThemeColor: '#5E9BFF',
      secondThemeColor: '#78AEFF',
    },
    icon: `${process.env.PUBLIC_URL}/images/logo/chatbot.png`,
  },
};
export const serviceName = (serviceId: ServiceType): string => {
  return serviceInfo[serviceId].name;
};
export const serviceNameJa = (serviceId: ServiceType): string => {
  return serviceInfo[serviceId].nameJa;
};
export const serviceThemeColor = (
  serviceId: ServiceType,
): ServiceThemeColor => {
  return serviceInfo[serviceId].colors;
};
export const serviceIcon = (serviceId: ServiceType): string => {
  return serviceInfo[serviceId].icon;
};
