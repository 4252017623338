import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { endOfMonth, startOfMonth } from 'date-fns';

export interface PriceTableProps {
  className?: string;
  licenseNumber?: number;
  priceInfo?: PriceInfo;
  hideDescription?: boolean;
}

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    priceTable: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: '#DADDE0',
      borderRadius: '3px',
      borderCollapse: 'separate',
      padding: theme.spacing(1.5, 2, 3),
      '& .MuiTableCell-root': {
        border: 'none',
        padding: 0,
      },
    },
    groupTitle: {
      lineHeight: '32px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#777777',
      paddingTop: '12px !important',
    },
    groupPrice: {
      lineHeight: '32px',
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#333333',
      textAlign: 'right',
    },
    totalTitle: {
      lineHeight: '32px',
      fontSize: '16px',
      fontWeight: 'bold',
      color: '#777777',
    },
    totalPrice: {
      lineHeight: '32px',
      fontSize: '20px',
      fontWeight: 'bold',
      color: '#333333',
      textAlign: 'right',
    },
    itemTitle: {
      lineHeight: '32px',
      fontSize: '14px',
      color: '#777777',
      paddingLeft: '32px !important',
      '&:last-child': {
        color: '#ff7777',
      },
    },
    itemPrice: {
      lineHeight: '32px',
      fontSize: '14px',
      color: '#333333',
      textAlign: 'right',
    },
    singlePriceRow: {
      '& th,td': {
        paddingTop: '12px !important',
        borderTop: '1px solid #DADDE0 !important',
      },
    },
    licenseNumberRow: {
      '& th,td': {
        paddingBottom: '12px !important',
      },
    },
    totalPriceRow: {
      '& th,td': {
        paddingTop: '12px !important',
        borderTop: '4px double #DADDE0 !important',
      },
    },
    rowPadding: {
      '& th,td': {
        paddingBottom: '12px !important',
      },
    },
    description: {
      marginTop: theme.spacing(1),
      color: '#7F7F7F',
      fontSize: '12px',
      lineHeight: '18px',
    },
  }),
);

export interface PriceInfo {
  discountedPrice: Price;
  normalPrice: Price;
  licenseNumber: string;
}

export interface Price {
  priceExcludingTax: string;
  priceIncludingTax: string;
  taxOfPrice: string;
  unitPriceExcludingTax: string;
  unitPriceIncludingTax: string;
  taxOfUnitPrice: string;
  discountAmountForUnitPriceExcludingTax: string;
  discountAmountForUnitPriceIncludingTax: string;
  discountAmountExcludingTax: string;
  discountAmountIncludingTax: string;
  daysUsed: number;
  daysUnused: number;
  daysTotal: number;
}

export const PriceTable: React.FC<PriceTableProps> = ({
  className,
  priceInfo,
  hideDescription = false,
}) => {
  const classes = useStyle();

  const [singlePrice, setSinglePrice] = React.useState('0');
  const [totalPrice, setTotalPrice] = React.useState('0');
  const [remainingDateText, setRemainingDateText] = React.useState('（---）');

  React.useEffect(() => {
    if (priceInfo) {
      // todo deal with second month price
      setSinglePrice(priceInfo.discountedPrice.unitPriceIncludingTax ?? '0');
      setTotalPrice(priceInfo.discountedPrice.priceIncludingTax ?? '0');

      const formatter = Intl.DateTimeFormat('ja-JP', {
        timeZone: 'Asia/Tokyo',
        month: 'numeric',
        day: 'numeric',
      });

      const currentDate = new Date();
      const firstDayOfUsageMonth = startOfMonth(currentDate);
      const lastDayOfUsageMonth = endOfMonth(currentDate);
      //When price table is opened on the last day of month
      if (priceInfo.discountedPrice.daysUnused === 0) {
        setRemainingDateText(
          `（${formatter.format(
            firstDayOfUsageMonth,
          )}～${lastDayOfUsageMonth.getDate()} ${lastDayOfUsageMonth.getDate()}日分）`,
        );
      } else {
        setRemainingDateText(
          `（${formatter.format(firstDayOfUsageMonth)}～${
            priceInfo.discountedPrice.daysUnused
          } ${priceInfo.discountedPrice.daysUnused}日分）`,
        );
      }
    }
  }, [priceInfo]);

  const formatPrice = (price: number | string, showDigits: boolean = false) => {
    return Number(price).toLocaleString('ja-JP', {
      minimumFractionDigits: showDigits ? 2 : 0,
      maximumFractionDigits: showDigits ? 2 : 0,
    });
  };

  return (
    <>
      <Table className={`${classes.priceTable} ${className || ''}`}>
        <TableBody>
          <TableRow>
            <TableCell
              component="th"
              scope="row"
              className={classes.groupTitle}
            >
              初月ご利用料金
            </TableCell>
            <TableCell className={classes.groupPrice}>
              {formatPrice(
                priceInfo?.discountedPrice?.unitPriceIncludingTax ?? '0',
                true,
              )}{' '}
              円
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" className={classes.itemTitle}>
              ご利用標準月額
            </TableCell>
            <TableCell className={classes.itemPrice}>
              {formatPrice(
                priceInfo?.normalPrice?.unitPriceIncludingTax ?? '0',
                true,
              )}{' '}
              円
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row" className={classes.itemTitle}>
              {`ご利用日数割引${remainingDateText}`}
            </TableCell>
            <TableCell className={classes.itemPrice}>
              {`-${formatPrice(
                priceInfo?.discountedPrice
                  ?.discountAmountForUnitPriceIncludingTax ?? 0,
                true,
              )} 円`}
            </TableCell>
          </TableRow>
          <TableRow className={classes.singlePriceRow}>
            <TableCell
              component="th"
              scope="row"
              className={classes.groupTitle}
            >
              初回ご請求金額（単価）
            </TableCell>
            <TableCell
              className={`${classes.groupPrice} ${classes.singlePriceRow}`}
            >
              {formatPrice(singlePrice, true)} 円
            </TableCell>
          </TableRow>
          <TableRow className={classes.licenseNumberRow}>
            <TableCell component="th" scope="row" className={classes.itemTitle}>
              ライセンス数
            </TableCell>
            <TableCell className={classes.itemPrice}>
              {priceInfo?.licenseNumber ?? 0} 件
            </TableCell>
          </TableRow>
          <TableRow className={classes.totalPriceRow}>
            <TableCell
              component="th"
              scope="row"
              className={classes.totalTitle}
            >
              初回ご請求金額（合計）
            </TableCell>
            <TableCell className={classes.totalPrice}>
              {formatPrice(totalPrice)} 円
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      {!hideDescription && (
        <Typography className={classes.description}>
          ※実際の計算では小数点第三位以下も利用しています。
        </Typography>
      )}
    </>
  );
};
