import React from 'react';
import { Box, SvgIcon, Typography } from '@material-ui/core';
import Icon from '@material-ui/core/Icon';
import { styled } from '@material-ui/core/styles';

export const ArrowsDownIcon = React.memo(
  ({
    firstThemeColor = '#FFFFFF',
    secondThemeColor = '#FFFFFF',
  }: {
    firstThemeColor?: string;
    secondThemeColor?: string;
  }) => {
    return (
      <svg width="16px" height="40px" viewBox="0 0 10 25">
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop
              offset="0%"
              style={{
                stopColor: firstThemeColor,
                stopOpacity: 1,
              }}
            />
            <stop
              offset="100%"
              style={{
                stopColor: secondThemeColor,
                stopOpacity: 1,
              }}
            />
          </linearGradient>
        </defs>
        <path
          d="M 0 0 l 5 5 l 5 -5 Z M 0 10 l 5 5 l 5 -5 Z M 0 20 l 5 5 l 5 -5 Z"
          fill="url(#grad1)"
        />
      </svg>
    );
  },
);

export interface CircleNextIconProps {
  className?: string;
}
export const CircleNextIcon: React.FC<CircleNextIconProps> = React.memo(
  ({ className }) => {
    return (
      <SvgIcon className={className}>
        <path d="M 10 6 L 8.59 7.41 L 13.17 12 l -4.58 4.59 L 10 18 l 6 -6 Z" />
        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
      </SvgIcon>
    );
  },
);

export type MarkType = 'question' | 'answer';
export interface MarkIconProps {
  markType: MarkType;
}
export const MarkIcon: React.FC<MarkIconProps> = React.memo(
  (props: MarkIconProps) => {
    const markContext = (() => {
      switch (props.markType) {
        case 'question':
          return { color: '#6A8AFF', character: 'Q' };
        case 'answer':
          return { color: '#FFC851', character: 'A' };
      }
    })();

    const Component = styled('span')(() => ({
      backgroundColor: markContext.color,
      borderRadius: '4px',
      color: '#ffffff !important',
      display: 'flex',
      fontSize: '28px !important',
      lineHeight: '34px !important',
      justifyContent: 'center',
      alignItems: 'center',
      height: '40px',
      width: '40px',
    }));

    return (
      <Icon
        component={() => (
          <Typography component={Component}>{markContext.character}</Typography>
        )}
      />
    );
  },
);

export interface UploadedIconProps {
  className?: string;
}
export const UploadedIcon: React.FC<UploadedIconProps> = ({
  className = '',
}) => {
  const StyledBox = styled(Box)({
    width: '44px',
    height: '44px',
    backgroundColor: '#EDEFF3',
    border: '1px solid #C2CAD8',
    borderRadius: '2px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '& svg': {
      width: '44px',
      height: '44px',
      color: '#C2CAD8',
    },
  });

  const imgSrc = `${process.env.PUBLIC_URL}/images/visitor.svg`;
  const imgAlt = 'file uploaded';

  return (
    <StyledBox className={className}>
      <img src={imgSrc} alt={imgAlt} />
    </StyledBox>
  );
};
