import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from '@material-ui/core';
import * as _ from 'lodash';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';

import { initFormData } from '@bizapp-frontend/customer/organisms/form/FormGenerator';
import { fullWidthToHalfWidthConverter } from '@bizapp-frontend/customer/molecules/form/FormInput';

import {
  EmailVerificationTemplate,
  EmailVerificationTemplateProps,
} from '@bizapp-frontend/customer/templates/form/EmailVerificationTemplate';

import { timeout } from '@bizapp-frontend/customer/pages/util';
import { ProcessingDialogState } from '@bizapp-frontend/customer/organisms/ProcessingDialog';
import {
  ServiceType,
  serviceThemeColor,
} from '@bizapp-frontend/customer/pages/util';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      marginTop: theme.spacing(3),
    },
    resend: {
      color: '#1565C0',
      fontSize: '14px',
    },
  }),
);

type EmailVerificationState = {
  emailAddress?: string;
};

export interface EmailVerificationProps
  extends Omit<EmailVerificationTemplateProps, 'serviceId'> {
  authBaseUrl?: string;
  grecaptchaKey?: string;
}

const EmailVerification: React.FC<EmailVerificationProps> = ({
  authBaseUrl,
  grecaptchaKey,
  ...props
}) => {
  const classes = useStyle();
  const navigate = useNavigate();
  const { service } = useParams();
  const { state } = useLocation() as { state: EmailVerificationState };
  const [emailAddress, setEmailAddress] = React.useState(
    state?.emailAddress || '',
  );
  const [verifyFailed, setVerifyFailed] = React.useState(false);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState('');
  const [dialogState, setDialogState] = React.useState<ProcessingDialogState>(
    'close',
  );

  const handleDialogClick = () => {
    setDialogState('close');
  };

  React.useEffect(() => {
    if (!state || _.isEmpty(state.emailAddress)) {
      const arr: { [key: string]: string } = {};
      if (document.cookie !== '') {
        const tmp = document.cookie.split('; ');
        for (let i = 0; i < tmp.length; i++) {
          const data = tmp[i].split('=');
          arr[data[0]] = decodeURIComponent(data[1]);
        }
      }

      if (_.isEmpty(arr['email'])) {
        navigate('/signup/pjb');
      } else {
        setEmailAddress(arr['email']);
      }
    } else {
      document.cookie = `email=${state.emailAddress}; max-age=3600`;
    }
  }, [state, state?.emailAddress, navigate]);

  const verifyEmail = async () => {
    const method = 'POST';
    const url = `${authBaseUrl}/api/auth/passwordless/token`;
    const headers = { 'Content-Type': 'application/json' };
    const body = {
      email: state.emailAddress,
      code: formData['verification-code'],
    };

    try {
      const resp = await fetch(url, {
        method: method,
        headers: headers,
        body: JSON.stringify(body),
      });

      const res = await resp.json();
      if ('error' in res) {
        setVerifyFailed(true);
        setCanSubmit(false);
        setAccessToken('');
      } else {
        setVerifyFailed(false);
        setCanSubmit(true);
        setAccessToken(res['access_token'] ?? '');
      }
    } catch {
      setVerifyFailed(true);
      setCanSubmit(false);
      setAccessToken('');
    }
  };

  const handleClick = async () => {
    try {
      setDialogState('wait');
      await timeout(verifyEmail());
      setDialogState('close');
    } catch {
      // Todo: error handling
      setDialogState('close');
    }
  };

  React.useEffect(() => {
    if (!verifyFailed && canSubmit && accessToken) {
      document.cookie = `email=${emailAddress}; max-age=0`;
      navigate(`/application/${service}/trial`, {
        state: {
          emailAddress: emailAddress,
          accessToken: accessToken,
        },
        replace: true,
      });
    }
  }, [accessToken, canSubmit, navigate, service, verifyFailed, emailAddress]);

  props.formComponents = [
    [
      {
        kind: 'input-normal',
        id: 'verification-code',
        label: '確認コード',
        name: 'verification-code',
        size: 'full',
        error: verifyFailed,
        helperText: '正しい確認コードを入力してください。',
        required: true,
        converter: fullWidthToHalfWidthConverter,
        onChange: () => setVerifyFailed(false),
      },
    ],
    [
      {
        kind: 'button-normal',
        id: 'submit-button',
        label: '次へ',
        className: classes.submit,
        postClick: handleClick,
      },
    ],
  ];

  const kinds = _.flatMapDeep(props.formComponents)
    .filter((v) => !(v.kind.startsWith('text') || v.kind.startsWith('button')))
    .map((v) => v);

  const [formData, setFormData] = React.useState<{
    [key: string]: boolean | string | undefined;
  }>(_.fromPairs(kinds.map((v) => [v.id, initFormData(v)])));
  const [isValids, setIsValids] = React.useState(
    _.fromPairs(kinds.map((v) => [v.id, false])),
  );
  const [forceValidation, setForceValidation] = React.useState(false);

  const [showResentNotification, setShowResentNotification] = React.useState(
    false,
  );

  const ResendLink: React.FC = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const sendEmail = async () => {
      if (executeRecaptcha === undefined) {
        return Promise.reject(new Error('Site key is undefined'));
      }
      const token = await executeRecaptcha('passwordless');
      const method = 'POST';
      const url = `${authBaseUrl}/api/auth/passwordless`;
      const resp = await fetch(url, {
        headers: {
          'X-reCAPTCHA-Token': token,
        },
        method: method,
        body: JSON.stringify({
          email: emailAddress,
        }),
      });

      return resp.status === 201
        ? Promise.resolve()
        : Promise.reject(
            new Error(`Failed in submitting form. status is ${resp.status}`),
          );
    };

    const f = async () => {
      try {
        setDialogState('wait');
        window.scrollTo(0, 0);
        await timeout(sendEmail());
        setShowResentNotification(true);
        setDialogState('close');
      } catch {
        setDialogState('error');
      }
    };

    return (
      <>
        <Link
          className={classes.resend}
          component="button"
          onClick={() => f()}
          disabled={dialogState === 'wait'}
        >
          再送信する
        </Link>
      </>
    );
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={grecaptchaKey}>
      <EmailVerificationTemplate
        {...props}
        serviceId={service as ServiceType}
        formData={formData}
        setFormData={setFormData}
        isValids={isValids}
        setIsValids={setIsValids}
        forceValidation={forceValidation}
        setForceValidation={setForceValidation}
        resend={<ResendLink />}
        userEmail={emailAddress ?? ''}
        dialogState={dialogState}
        onClickDialogClose={handleDialogClick}
        showToastr={showResentNotification}
        setShowToastr={(show: boolean) => setShowResentNotification(show)}
        serviceThemeColor={serviceThemeColor(service as ServiceType)}
      />
    </GoogleReCaptchaProvider>
  );
};

export default EmailVerification;
