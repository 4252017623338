import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  IncreaseLicenseApplication as IncreaseLicenseApplicationTemplate,
  IncreaseLicenseApplicationProps as IncreaseLicenseApplicationTemplateProps,
} from '@bizapp-frontend/customer/templates/form/IncreaseLicenseApplication';

import {
  IncreaseLicenseFormStep1,
  IncreaseLicenseFormStep1Props,
} from '@bizapp-frontend/customer/pages/form/pjb/IncreaseLicenseFormStep1';
import {
  IncreaseLicenseFormStep2,
  IncreaseLicenseFormStep2Props,
} from '@bizapp-frontend/customer/pages/form/pjb/IncreaseLicenseFormStep2';
import { IncreaseLicenseFormStep3 } from '@bizapp-frontend/customer/pages/form/pjb/IncreaseLicenseFormStep3';

import { GlobalsContext } from '@bizapp-frontend/customer/globals';

interface IncreaseLicenseApplicationProps
  extends Omit<IncreaseLicenseApplicationTemplateProps, 'stepPages'>,
    IncreaseLicenseFormStep1Props,
    IncreaseLicenseFormStep2Props {}

export interface IncreaseLicenseApplicationCommonProps {
  // login info
  customerId?: string;
  userId?: string;
  emailAddress?: string;

  // from state
  tenantId?: string;
  serviceId?: string;

  // fixed to pjb-basic
  planId?: string;
}

const IncreaseLicenseApplication: React.FC<IncreaseLicenseApplicationProps> = ({
  applicationControlAPIBaseUrl,
  zipcodeAPIBaseUrl,
  feeCalculatorAPIBaseUrl,
  paymentGatewayAPIBaseUrl,
  customerAPIBaseUrl,
  tenantManagementAPIBaseUrl,
  pjbDomainUrlSuffix,
  contractAPIBaseUrl,
}) => {
  const {
    state: { customerId, userId, emailAddress },
  } = React.useContext(GlobalsContext);
  const query = new URLSearchParams(useLocation().search);
  const tenantId = query.get('tenantId') ?? undefined;
  const serviceId = query.get('serviceId') ?? undefined;

  const planId = 'pjb-basic';

  const navigate = useNavigate();

  if (!tenantId || !serviceId) {
    navigate('/');
  }

  return (
    <>
      <IncreaseLicenseApplicationTemplate
        stepPages={[
          <IncreaseLicenseFormStep1
            applicationControlAPIBaseUrl={applicationControlAPIBaseUrl}
            zipcodeAPIBaseUrl={zipcodeAPIBaseUrl}
            feeCalculatorAPIBaseUrl={feeCalculatorAPIBaseUrl}
            contractAPIBaseUrl={contractAPIBaseUrl}
            customerAPIBaseUrl={customerAPIBaseUrl}
            tenantManagementAPIBaseUrl={tenantManagementAPIBaseUrl}
            pjbDomainUrlSuffix={pjbDomainUrlSuffix}
            customerId={customerId}
            emailAddress={emailAddress}
            userId={userId}
            tenantId={tenantId}
            serviceId={serviceId}
            planId={planId}
          />,
          <IncreaseLicenseFormStep2
            applicationControlAPIBaseUrl={applicationControlAPIBaseUrl}
            paymentGatewayAPIBaseUrl={paymentGatewayAPIBaseUrl}
            contractAPIBaseUrl={contractAPIBaseUrl}
            customerId={customerId}
            tenantId={tenantId}
            serviceId={serviceId}
            emailAddress={emailAddress}
            userId={userId}
          />,
          <IncreaseLicenseFormStep3 />,
        ]}
      />
    </>
  );
};

export default IncreaseLicenseApplication;
